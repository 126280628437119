import React from 'react';
import '../styles/PricingCardMobile.css';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { CustomRadio } from '../styles/GlobalStyles';
import { Accordion, AccordionDetails, AccordionSummary } from '../styles/GlobalStyles';
import { ChevronUp } from '../assets';
import { BestValue } from './';

function PricingCardMobile(props) {
    const item = props.item;
    const index = props.index;
    const selectedCard = props.selectedCard;
    const changeRadio = props.changeRadio;
    const changeAccordion = props.changeAccordion;
    const expandedAccordion = props.expandedAccordion;
    const firstPricingOption = props.firstPricingOption;

    const styles = {
		accordionSummary: {
			width: '100px',
			boxShadow: 'none', 
			padding: 0,
			margin: '0 auto',
            display: 'flex',
		}
	};

    return (
        <div className={"pricing-card-mobile-outer-container " + (index === Number(selectedCard) && selectedCard !== null ? "pricing-card-mobile-selected" : "")}>
            {
                index === 2 && firstPricingOption === false ?
                <BestValue
                    text="✨Recommended✨"
                />
                :
                null
            }
            <div className="pricing-card-mobile-inner-container">
                <div className="pricing-card-mobile-left-container">
                    <div 
                        className="pricing-card-mobile-radio-container"
                        onClick={() => changeRadio(index)}
                    >
                        <FormControl className="new-filters-modal-radio-outer-container">
                            <RadioGroup
                                aria-labelledby="payment-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={selectedCard}
                                className="new-filters-payment-radio-row"
                                onChange={(val) => changeRadio(val.target.value)}
                            >
                                <FormControlLabel 
                                    value={index} 
                                    control={<CustomRadio />} 
                                    className="property-payment-radio-label"
                                />
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className="pricing-card-mobile-body-container">
                        <div
                            onClick={() => changeRadio(index)}
                            className="pricing-card-mobile-body-inner-container"
                        >
                            <img
                                src={item.image}
                                className="pricing-card-mobile-image"
                                alt={item.label}
                            />
                            <span className="body-regular colour-quaternary block-text margin-x-small">
                                {item.label}
                            </span>
                            <span className="body-regular colour-secondary block-text margin-x-small">
                                {item.bestFor}
                            </span>
                        </div>
                        {
                            item.features.length === 0 ?
                            null
                            :
                            <div className="pricing-card-mobile-accordion">
                                <Accordion 
                                    expanded={expandedAccordion === index ? true : false} 
                                    onChange={() => changeAccordion(index)}
                                    sx={{ boxShadow: 'none' }}
                                >
                                    <AccordionSummary
                                        aria-controls={`panel-${index}-bh-content`}
                                        id={`panel-${index}-bh-header`}
                                        sx={styles.accordionSummary}
                                    >
                                        <img
                                            src={ChevronUp}
                                            className={"pricing-card-mobile-chevron " + (expandedAccordion === index ? "" : "reverse")}
                                            alt="Chevron"
                                        />
                                        <h4 className="label-semibold colour-secondary">
                                            See benefits
                                        </h4>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="pricing-card-mobile-features-container">
                                            {   
                                                item.features.map((item, index) => (
                                                    item.text === null ?
                                                    null
                                                    :
                                                    <li
                                                        key={index}
                                                        className="pricing-card-mobile-features-list-element"
                                                    >
                                                        <div className="pricing-card-mobile-features-list-element-icon-container">
                                                            <img
                                                                src={item.icon}
                                                                className="pricing-card-mobile-features-list-element-icon"
                                                                alt="Tick"
                                                            />
                                                        </div>
                                                        {
                                                            item.text.includes("No 1-on-1") ?
                                                            <span className="label-regular colour-error">
                                                                {item.text}
                                                            </span>
                                                            :
                                                            item.singleLine === true ?
                                                            <span className="label-regular colour-secondary">
                                                                {item.text}
                                                            </span>
                                                            :
                                                            <span className="label-regular colour-secondary">
                                                                {item.firstText} <span className={"text-bold " + (item.link !== "" ? "underline cursor-pointer" : "")}>{item.boldText}</span> {item.secondText}
                                                            </span>
                                                        }
                                                    </li>
                                                ))
                                            }
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        }
                    </div>
                </div>
                <div 
                    className="pricing-card-mobile-right-container"
                    onClick={() => changeRadio(index)}
                >
                    <h3 className="body-semibold text-link text-align-right margin-x-x-small">
                        {item.title}
                    </h3>
                    <h4 className="heading-small-semibold text-link text-align-right margin-x-x-small">
                        {item.price}
                    </h4>
                    <span className="label-regular colour-secondary block-text text-align-right">
                        {item.duration}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default PricingCardMobile;