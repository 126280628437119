import React, { useState } from 'react';
import '../styles/LastUpdated.css';
import { Info } from '../assets';
import { DefaultTooltip } from './';
import moment from 'moment';
import { recordEvent } from '../functions';

function LastUpdated(props) {
    const property = props.property;
    const lastUpdated = property.lastDataUpdateTimestamp;
    const utcDate = new Date(lastUpdated);
    const utcTimestamp = utcDate.getTime();
    const timezoneOffsetMinutes = new Date().getTimezoneOffset();
    const timezoneOffsetMilliseconds = timezoneOffsetMinutes * 60000;
    const localTimestamp = new Date(utcTimestamp - timezoneOffsetMilliseconds);
    const currentTime = new Date();
    const lastUpdatedTSMoment = moment(localTimestamp);
    const todayTimestamp = moment(currentTime);
    const minuteDateDifference = todayTimestamp.diff(lastUpdatedTSMoment, 'minutes');
    const hourDateDifference = todayTimestamp.diff(lastUpdatedTSMoment, 'hours');
    const dayDateDifference = todayTimestamp.diff(lastUpdatedTSMoment, 'days');

    const [tooltip, setTooltip] = useState(false);

    const toggleTooltip = (val) => {
        recordEvent("Toggle Last Updated Tooltip", {
            tooltip: val
        });
        setTooltip(val);
    };

    const openZillow = () => {
        recordEvent("View Zillow Listing", {
            zpid: property.zpid
        })
        window.open(property.zillowlistingUrl, "_blank");
    };

    return (
        <div className="last-updated-outer-container">
            <div className="last-updated-inner-container">
                <div className="last-updated-title-container margin-large">
                    <h2 className="heading-small-semibold colour-primary">
                        Last updated
                    </h2>
                </div>
                <div className="last-updated-time-container margin-medium">
                    <div className="subtext-margin-right-large">
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="16" 
                            height="16" 
                            viewBox="0 0 16 16" 
                            fill="none"
                            className="last-updated-icon"
                        >
                            <path 
                                d="M7.84605 9.33252C7.94581 9.29284 8.05861 9.28976 8.16062 9.32325C11.4442 10.4013 12.2367 13.0154 12.4264 14.1105C12.4731 14.38 12.2592 14.606 11.9857 14.606H4.1059C3.84443 14.606 3.63536 14.3985 3.66943 14.1393C3.75675 13.4748 4.07356 12.2062 5.21242 11.0322C5.82329 10.4025 6.67069 9.79998 7.84605 9.33252Z" 
                                fill="#292621"
                            />
                            <path 
                                d="M7.77149 7.40602C7.87096 7.43829 7.98046 7.43526 8.07785 7.39716C8.99717 7.0375 9.66183 6.57639 10.1423 6.09465C10.9888 5.24598 11.2636 4.33334 11.3524 3.79879C11.3952 3.54102 11.1868 3.33316 10.9255 3.33316L4.86416 3.33316C4.59046 3.33316 4.37718 3.55994 4.43233 3.82804C4.6169 4.72544 5.295 6.60259 7.77149 7.40602Z" 
                                fill="#292621"
                            />
                            <path 
                                fillRule="evenodd" 
                                clipRule="evenodd" 
                                d="M13.8185 1.15134C14.0863 1.15134 14.3033 0.934264 14.3033 0.666489C14.3033 0.398715 14.0863 0.181641 13.8185 0.181641H2.18211C1.91434 0.181641 1.69727 0.398715 1.69727 0.666489C1.69727 0.934264 1.91434 1.15134 2.18211 1.15134C2.18211 3.89252 3.3559 6.28531 5.09957 7.56223C5.32496 7.72729 5.55987 7.87371 5.80301 7.99982C5.55987 8.12594 5.32496 8.27235 5.09957 8.43741C3.3559 9.71434 2.18211 12.1071 2.18211 14.8483C1.91434 14.8483 1.69727 15.0654 1.69727 15.3332C1.69727 15.6009 1.91434 15.818 2.18211 15.818H13.8185C14.0863 15.818 14.3033 15.6009 14.3033 15.3332C14.3033 15.0654 14.0863 14.8483 13.8185 14.8483C13.8185 12.1071 12.6447 9.71434 10.901 8.43741C10.6756 8.27235 10.4407 8.12594 10.1976 7.99982C10.4407 7.87371 10.6756 7.72729 10.901 7.56223C12.6447 6.28531 13.8185 3.89252 13.8185 1.15134ZM13.1326 0.908913H2.868C2.8948 0.984738 2.90939 1.06634 2.90939 1.15134C2.90939 4.03678 4.30504 6.40354 6.13788 7.35423C6.37896 7.47928 6.53028 7.72824 6.53028 7.99982C6.53028 8.27141 6.37896 8.52037 6.13788 8.64541C4.30504 9.59611 2.90939 11.9629 2.90939 14.8483C2.90939 14.9333 2.8948 15.0149 2.868 15.0907H13.1326C13.1058 15.0149 13.0912 14.9333 13.0912 14.8483C13.0912 11.9629 11.6956 9.59611 9.86272 8.64541C9.62163 8.52037 9.47031 8.27141 9.47031 7.99982C9.47031 7.72824 9.62163 7.47928 9.86272 7.35423C11.6956 6.40354 13.0912 4.03678 13.0912 1.15134C13.0912 1.06634 13.1058 0.984738 13.1326 0.908913Z" 
                                fill="#292621"
                            />
                        </svg>
                    </div>
                    <span className="body-regular colour-primary block-text subtext-margin-right-large">
                        {
                            hourDateDifference < 1 ?
                            `${minuteDateDifference} minute${minuteDateDifference === 1 ? "" : "s"} ago`
                            :
                            hourDateDifference > 23 ?
                            `${dayDateDifference} day${dayDateDifference === 1 ? "" : "s"} ago`
                            :
                            `${hourDateDifference} hour${hourDateDifference === 1 ? "" : "s"} ago`
                        }
                    </span>
                    <div
                        className="last-updated-info-icon-container relative-container"
                        onMouseEnter={() => toggleTooltip(true)}
                        onMouseLeave={() => toggleTooltip(false)}
                    >
                        <img
                            src={Info}
                            className="last-updated-info-icon"
                            alt="Information icon"
                        />
                        {
                            tooltip === true ?
                            <DefaultTooltip
                                title=""
                                description={`Our data currently updates twice per day – unfortunately we’re still a little broke, so this is all we can afford for now 😅 \n\n We added a Zillow link below so you can quickly check for real-time updates.`}
                                changeIcon={false}
                            />
                            :
                            null
                        }
                    </div>
                </div>
                {
                    hourDateDifference >= 3 ?
                        <div className="last-updated-description-container margin-medium">
                            <span className="body-regular colour-secondary">
                                🧐 It’s been {hourDateDifference < 24 ? `${hourDateDifference} hour${hourDateDifference === 1 ? "" : "s"}` : `${dayDateDifference} day${dayDateDifference === 1 ? "" : "s"}`} since we updated this listing. 
                                Consider verifying listing status on Zillow for any updates.
                            </span>
                        </div>
                    :
                    null
                }
                {
                    property.zillowlistingUrl !== undefined ?
                    <div 
                        className="text-button button-row"
                        onClick={() => openZillow()}
                    >
                        <span className="body-regular colour-link block-text subtext-margin-right">
                            See Zillow listing
                        </span>
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="16" 
                            height="16" 
                            viewBox="0 0 16 16" 
                            fill="none"
                            className="last-updated-zillow-icon"
                        >
                            <path 
                                d="M15.5 1.85735V14.0735C15.5 14.8231 14.8923 15.4308 14.1427 15.4308C13.393 15.4308 12.7853 14.8231 12.7853 14.0735L12.7853 5.13427L2.81714 15.1024C2.28706 15.6325 1.42764 15.6325 0.897558 15.1024C0.36748 14.5724 0.367481 13.7129 0.897558 13.1829L10.8657 3.2147L1.92653 3.21469C1.17688 3.21469 0.56918 2.60699 0.56918 1.85735C0.569179 1.10771 1.17689 0.5 1.92653 0.5L14.1427 0.500002C14.8923 0.500002 15.5 1.10771 15.5 1.85735Z" 
                                fill="#2F4858"
                            />
                        </svg>
                    </div>
                    :
                    null
                }
            </div>
        </div>
    );
};

export default LastUpdated;