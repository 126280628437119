import React, { forwardRef, useState, useEffect } from 'react';
import '../styles/NewPropertyCard.css';
import CustomSwiper from './CustomSwiper';
import { CopyChainBlue, FaveSelected, FaveUnselected, OffMarketHouse, RedirectArrow, TickBlue } from '../assets';
import { toggleFavourite, markPropertyViewed, recordEvent } from '../functions/index';
import { useNavigate, useLocation } from 'react-router-dom';
import { NewPropertyCharacteristics, TopPickBadge, SellerFinancingBadge, FixerUpperBadge, CashOnlyBadge, ExpensesCoveredBadge, AddBedroomBadge, ADUBadge, MultipleBadges, PriceCutBadge, CashOnCashBadge, ARVSpreadBadge, ARVBadge, PropertyCardBadge, PropertyStatus, MonthlyCashFlowBadge } from './';
import { propertyStatuses } from '../styles/GlobalStyles';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const NewPropertyCard = forwardRef((props, ref) => {
	const item = props.item;
	const index = props.index;
	const formatter = props.formatter;
	const abstract = props.abstract;
	const imageLimit = props.imageLimit;
	const viewedProperties = props.viewedProperties;
	const setViewedProperties = props.setViewedProperties;
	const cityId = props.cityId;
	const setHoverCard = props.setHoverCard;
	const propertyRefs = props.propertyRefs;
	const onboarding = props.onboarding;
	const onboardingStep = props.onboardingStep;
	const setCardClick = props.setCardClick;
	const temporaryId = props.temporaryId;
	const setSignUpModal = props.setSignUpModal;
	const overlay = props.overlay;
	const setOverlay = props.setOverlay;
	const propertySearch = props.propertySearch;
	const partnerView = props.partnerView;
	const partnerEditProperty = props.partnerEditProperty;
	// const partnerSoldProperty = props.partnerSoldProperty;
	const partnerData = props.partnerData;
	const openStatus = props.openStatus;
	const selectOption = props.selectOption;
	const arrowClicked = props.arrowClicked;
	const propertyViewed = viewedProperties === null ? false : viewedProperties.findIndex(e => e.zpid === item.zpid) === -1 ? false : true;
	const favourites = props.favourites;
	const setFavourites = props.setFavourites;
	const [favouriteDisable, setFavouriteDisable] = useState(false);
	const [multipleBadges, setMultipleBadges] = useState(false);
	const [linkCopied, setLinkCopied] = useState(false);
	const chooseStatus = props.chooseStatus;
	const zpidIndex = favourites.findIndex(e => e.zpid === item.zpid);
	const inFavourites = zpidIndex === -1 ? false : true;
	const ipUser = props.ipUser;
	const noUserId = props.noUserId;
	const strategy = props.strategy !== undefined && props.strategy !== null ? props.strategy : 0;
	const strategyParam = strategy > 4 || strategy < 1 ? "" : `?strategy=${strategy - 1}`;
	const googleLink = `https://www.google.com/maps/place/${item.address.streetAddress.replace(" ", "+")},+${item.address.city},+${item.address.state}+${item.address.zipcode}`;
	const statusElement = partnerView === false ? null : propertyStatuses.findIndex(e => e.value === item.status) === -1 ? null : propertyStatuses[propertyStatuses.findIndex(e => e.value === item.status)];
	const host = window.location.host;
	const cityObject = {
		cityId: cityId,
		city: item.address.city,
		state: item.address.state
	};
	const propertiesPath = item.dripScoreOptions === undefined ? `/old-properties` : `/properties`;
	const listingURL = partnerData === null ? "" : `${host}/partner/${partnerData.id}/${item.zpid}?utm_source=partner&utm_medium=listing&utm_campaign=${partnerData.id}`;

	useEffect(() => {
		const badgeArray = [];
		if ( item.topPick === true && strategy === 0 ) {
			badgeArray.push(true);
		}
        else if ( item.topPickOptions !== undefined && item.topPickOptions.traditional === true && strategy === 1 ) {
            badgeArray.push(true);
        }
        else if ( item.topPickOptions !== undefined && item.topPickOptions.houseHacking === true && strategy === 2 ) {
            badgeArray.push(true);
        }
		else if ( item.topPickOptions !== undefined && item.topPickOptions.brrrr === true && strategy === 3 ) {
            badgeArray.push(true);
        }
		else if ( item.topPickOptions !== undefined && item.topPickOptions.fixAndFlip === true && strategy === 4 ) {
			badgeArray.push(true);
		}
		else if ( item.topPickOptions !== undefined && item.topPickOptions.hiddenGem === true && strategy === 5 ) {
			badgeArray.push(true);
		}

		if ( item.fixerUpper === true ) {
			badgeArray.push(true);
		}

		if ( item.cashOnly === true ) {
			badgeArray.push(true);
		}

		if ( item.addBedOpportunity === true ) {
			badgeArray.push(true);
		}

		if ( item.zonedForAdu === true ) {
			badgeArray.push(true);
		}

		if ( item.ownerFinancingFlag === true ) {
			badgeArray.push(true);
		}

		if ( badgeArray.length > 1 ) {
			setMultipleBadges(true);
		}
		else {
			setMultipleBadges(false);
		}

	}, [item.addBedOpportunity, item.cashOnly, item.fixerUpper, item.zonedForAdu, item.topPick, item.ownerFinancingFlag, item.topPickOptions, strategy]);

	const navigate = useNavigate();
	const location = useLocation();
	const state = location.state;

	const viewProperty = async(zpid, e, bgImage) => {
		if ( partnerView === true ) {
			const target = e.target;
			const targetClass = target.parentNode.classList.value;
			if ( targetClass.includes("off-market-card-text-row") || targetClass.includes("off-market-extra-badges-container") ) {
				return;
			}
			else if ( zpid === undefined ) {
				alert("There was a problem getting the unique ID for this property...contact us for help!");
			}
			else {
				navigate(`/partner/${partnerData.id}/${zpid}`, {
					state: state
				});
				recordEvent("Partner Card Clicked", {
					zpid: zpid
				});
			}
		}
		else if ( propertySearch === true ) {
			const target = e.target;
			const targetClass = target.parentNode.classList.value;
			if ( targetClass.includes("off-market-card-text-row") || targetClass.includes("off-market-extra-badges-container") ) {
				return;
			}
			else {
				const encodedAddress = item.encodedAddress === undefined ? `${encodeURIComponent(`${item.address.streetAddress}, ${item.address.city}, ${item.address.state}, USA`)}` : item.encodedAddress;
				navigate(`/quick-report/${encodedAddress}`, {
					state: {
						redirect: true,
						preSaved: true,
						ipUser: ipUser,
						noUserId: noUserId
					}
				});
				recordEvent("Property Search Card Clicked", {
					zpid: zpid
				});
			}
		}
		else if ( overlay === false ) {
			setOverlay(true);
		}
		else if ( bgImage === true ) {
			const target = e.target;
			const targetClass = target.classList.value;
			if ( targetClass !== "favourite-icon " ) {
				if ( onboarding === true ) {
					if ( setCardClick !== null ) {
						setCardClick(true);
					}
					window.open(`${propertiesPath}/${cityId}/${zpid}${strategyParam}`, '_blank');
		
					if ( temporaryId === false ) {
						const path = window.location.pathname;
						const newObject = {
							zpid: zpid,
							date: new Date()
						}
	
						if ( viewedProperties !== null ) {
							const newArray = viewedProperties.slice(0);
							newArray.push(newObject);
							setViewedProperties(newArray);
							await markPropertyViewed(zpid);
						}

						recordEvent("Navigation", {
							oldRoute: path,
							newRoute: `${propertiesPath}/${cityId}/${zpid}${strategyParam}`
						});

						recordEvent("Property Click", {
							zpid: zpid,
							cityId: cityId
						});
					}

					setTimeout(() => {
						if ( setCardClick !== null ) {
							setCardClick(false);
						}
					}, 500)
				}
			}
		}
		else {
			if ( onboarding === true ) {
				if ( setCardClick !== null ) {
					setCardClick(true);
				}
				window.open(`${propertiesPath}/${cityId}/${zpid}${strategyParam}`, '_blank');
	
				if ( temporaryId === false ) {
					const newObject = {
						zpid: zpid,
						date: new Date()
					}
	
					if ( viewedProperties !== null ) {
						const newArray = viewedProperties.slice(0);
						newArray.push(newObject);
						setViewedProperties(newArray);
						await markPropertyViewed(zpid);
					}
		
					const path = window.location.pathname;

					recordEvent("Navigation", {
						oldRoute: path,
						newRoute: `${propertiesPath}/${cityId}/${zpid}${strategyParam}`
					});

					recordEvent("Property Click", {
						zpid: zpid,
						cityId: cityId
					});
				}
	
				setTimeout(() => {
					if ( setCardClick !== null ) {
						setCardClick(false);
					}
				}, 500)
			}
		}
	};

	const addHover = () => {
		setHoverCard(item.zpid);
		if ( propertyRefs[index].current !== null && propertyRefs !== undefined ) {
			propertyRefs[index].current.parentNode.style.zIndex = 3;
		}
	}

	const removeHover = () => {
		if ( propertyRefs[index].current !== null && propertyRefs !== undefined ) {
			propertyRefs[index].current.parentNode.style.zIndex = 1;
		}
		setHoverCard("");
	}

	const openGoogle = () => {
		recordEvent("Open Google Link", {});
        window.open(googleLink, '_blank');
    };

	const copyToClipboard = () => {
		recordEvent("Copy to Clipboard", {});
		setLinkCopied(true);

		setTimeout(() => {
			setLinkCopied(false);
		}, 10000);
	};

	return (
		<div 
			className={"new-property-card-outer-container " + (abstract === false && propertyViewed === true && onboarding === true ? "new-property-card-viewed-property" : "")}
			onMouseOver={() => abstract === false ? addHover() : null}
			onMouseOut={() => abstract === false ? removeHover() : null}
		>
			<div className={"new-property-card-inner-container " + (item.offMarket === false ? "new-property-card-on-market" : "")}>
			{
					multipleBadges === true ?
					<MultipleBadges
						property={item}
						strategy={strategy}
					/>
					:
					item.topPick === true && strategy === 0 ?
					<TopPickBadge />
					:
                    item.topPickOptions !== undefined && item.topPickOptions.traditional === true && strategy === 1 ?
                    <TopPickBadge />
                    :
                    item.topPickOptions !== undefined && item.topPickOptions.hiddenGem === true && strategy === 2 ?
                    <TopPickBadge />
                    :
					item.topPickOptions !== undefined && item.topPickOptions.houseHacking === true && strategy === 3 ?
					<TopPickBadge />
					:
					item.topPickOptions !== undefined && item.topPickOptions.brrrr === true && strategy === 4 ?
					<TopPickBadge />
					:
					item.topPickOptions !== undefined && item.topPickOptions.fixAndFlip === true && strategy === 5 ?
					<TopPickBadge />
					:
					item.fixerUpper === true ?
					<FixerUpperBadge />
					:
					item.cashOnly === true ?
					<CashOnlyBadge />
					:
					item.addBedOpportunity === true ?
					<AddBedroomBadge />
					:
					item.zonedForAdu === true ?
					<ADUBadge />
					:
					item.ownerFinancingFlag === true ?
					<SellerFinancingBadge />
					:
					null
				}
				{
					item.images !== undefined && item.images !== null && item.images.length !== 1 ?
					<div className="new-property-card-images-swiper-container">
						<CustomSwiper
							images={item.images}
							title={item.address.streetAddress}
							defaultImage={item.imgSrc}
							Heart={FaveUnselected}
							HeartActive={FaveSelected}
							favourites={favourites}
							toggleFavourite={toggleFavourite}
							favouriteDisable={favouriteDisable}
							item={item}
							setFavourites={setFavourites}
							setFavouriteDisable={setFavouriteDisable}
							abstract={abstract}
							viewProperty={viewProperty}
							cityId={cityId}
							setCardClick={setCardClick}
							temporaryId={temporaryId}
							setSignUpModal={setSignUpModal}
							propertySearch={propertySearch}
							partner={partnerData === null ? false : true}
							arrowClicked={arrowClicked}
							imageLimit={imageLimit}
							swiperRef={null}
						/>
						{
							item.auction === true ?
							<PropertyCardBadge
								text="AUCTION"
							/>
							:
							item.backOnMarket === true ?
							<PropertyCardBadge
								text="BACK ON MARKET"
							/>
							:
							partnerView === true && item.status === "sold" ?
							<PropertyCardBadge
								text="SOLD"
							/>
							:
							item.priceChange === true ?
							<PriceCutBadge
								priceDrop={item.priceChangeTotalPrctDiff > 0 ? false : true}
								priceChangeHistory={item.priceChangeHistory}
								priceChangeTotalAmount={item.priceChangeTotalAmount}
								priceChangeLatestDate={item.priceChangeLatestDate}
							/>
							:
							null
						}
					</div>
					:
					item.images === null || (item.images.length > 0 && item.images[0].includes("maps.googleapis.com")) || (item.imgSrc !== undefined && item.imgSrc.includes("maps.googleapis.com")) || (item.images.length === 0) ?
					<div 
						className="off-market-card-image-container"
						onClick={(e) => viewProperty(item.zpid, e, false)}
					>
						<img
							src={OffMarketHouse}
							className="off-market-card-icon"
							alt="Off Market"
						/>
						<div 
							className="off-market-card-text-row"
							onClick={() => openGoogle()}
						>
							<span className="label-regular-caps colour-secondary">
								Go to street view
							</span>
							<img
								src={RedirectArrow}
								className="off-market-card-redirect-arrow"
								alt="Redirect"
							/>
						</div>
					</div>
					:
					<div 
						style={{ backgroundImage: `url(${item.imgSrc})` }} className="new-property-card-image-container"
						onClick={(e) => viewProperty(item.zpid, e, true)}
					>
						{
							item.auction === true ?
							<PropertyCardBadge
								text="AUCTION"
							/>
							:
							item.backOnMarket === true ?
							<PropertyCardBadge
								text="BACK ON MARKET"
							/>
							:
							partnerView === true && item.status === "sold" ?
							<PropertyCardBadge
								text="SOLD"
							/>
							:
							item.priceChange === true ?
							<PriceCutBadge
								priceDrop={item.priceChangeTotalPrctDiff > 0 ? false : true}
								priceChangeHistory={item.priceChangeHistory}
								priceChangeTotalAmount={item.priceChangeTotalAmount}
								priceChangeLatestDate={item.priceChangeLatestDate}
							/>
							:
							null
						}
						{
							propertySearch === true || partnerView === true ?
							null
							:
							<div 
								className="favourite-icon-container"
								onClick={() => temporaryId === true ? setSignUpModal(true) : setFavourites === null ? null : toggleFavourite(favourites, item.zpid, setFavourites, setFavouriteDisable, navigate, cityObject, setCardClick)}
							>
								<img
									src={
											inFavourites === false ?
											FaveUnselected
											:
											FaveSelected
										}
									className={`favourite-icon ${favouriteDisable === true ? "favourite-icon-disable" : ""}`}
									alt="Add To Favourites"
								/>
							</div>
						}
					</div>
				}
				<div 
					onClick={(e) => viewProperty(item.zpid, e, false)}
					className="new-property-card-info-container"
				>
					<div className="new-property-card-price-container">
						<h3 className="heading-large-semibold text-link subtext-margin-right-large">
							{formatter.format(item.price).replace(".00", "")}
						</h3>
						{
							item.arvComps === true && item.fixerUpper === true && item.propertyTypeDimension === "Single Family" ?
							<ARVBadge
								arvPrice={item.arvPrice}
								item={item}
							/>
							:
							null
						}
					</div>
					<NewPropertyCharacteristics
						item={item}
					/>
				</div>
				{
					partnerView === true || propertySearch === true ?
					null
					:
					<div 
						className={"new-property-card-badges-container " + (onboarding === false && index === 0 && onboardingStep === 0 ? "new-property-card-badges-onboarding" : "")}
						onClick={(e) => viewProperty(item.zpid, e, false)}
					>
						{
							strategy === 0 || strategy === 1 || strategy === 5 ?
							<CashOnCashBadge
								item={item}
								strategy={strategy}
							/>
							:
							strategy === 2 || strategy === 3 ?
							<MonthlyCashFlowBadge
								item={item}	
								strategy={strategy}
							/>
							:
							null
						}
						{
							strategy !== 1 && strategy !== 2 && item.arvComps === true && item.fixerUpper === true && item.propertyTypeDimension === "Single Family" ?
							<ARVSpreadBadge
								arvPrice={item.arvPrice}
								item={item}
							/>
							:
							strategy === 2 ?
							<ExpensesCoveredBadge
								item={item}
								strategy={strategy}
							/>
							:
							null
						}
					</div>
				}
				{
					partnerView === true && partnerEditProperty !== null ?
					<div className="new-property-card-partner-container">
						<div className="new-property-card-partner-left-container">
							<PropertyStatus
								text={statusElement !== null ? statusElement.label : ""}
								bgColour={statusElement !== null ? statusElement.bgColour : ""}
								openStatus={openStatus}
								chooseStatus={chooseStatus}
								selectOption={selectOption}
								property={item}
							/>
						</div>
						<div className="new-property-card-partner-right-container">
							<div className="new-property-card-partner-edit-container text-button subtext-margin-right-large">
								<span 
									className="body-regular colour-link cursor-pointer"
									onClick={() => partnerEditProperty(item.zpid)}
								>
									Edit
								</span>
							</div>
							<CopyToClipboard 
								text={listingURL}
								onCopy={() => copyToClipboard()}
							>
								<div className={"new-property-card-partner-copy-link-container " + (linkCopied === false ? "cursor-pointer text-button" : "")}>
									<img
										src={linkCopied === true ? TickBlue : CopyChainBlue}
										className="new-property-card-partner-copy-link-icon subtext-margin-right"
										alt="Copy to Clipboard"
									/>
									<span className="body-regular text-link">
										{
											linkCopied === false ?
											"Copy link"
											:
											"Link copied"
										}
									</span>
								</div>
							</CopyToClipboard>
						</div>
					</div>
					:
					null
				}
			</div>
		</div>
	)
});

export default NewPropertyCard;