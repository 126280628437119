import React from 'react';
import '../styles/AllCitiesLoading.css';
import { EspressoShots } from '../assets/images';
import { useNavigate, useLocation } from 'react-router-dom';
import { recordEvent } from '../functions';

function AllCitiesLoading() {
    const location = useLocation();
    const state = location.state;
    const navigate = useNavigate();

    const viewSample = () => {
        window.open("/properties-list/1241176", "_blank");
        recordEvent("All Cities Loading - Property Search Clicked", {});
    };

    const goToSearch = () => {
        navigate("/quick-report", {
            state: state
        });
        recordEvent("All Cities Loading - Property Search Clicked", {});
    };

    return (
        <div className="all-cities-loading-outer-container">
            <div className="all-cities-loading-inner-container">
                <img
                    src={EspressoShots}
                    className="all-cities-loading-espresso-shots margin-large"
                    alt="Espresso Shots"
                />
                <h1 className="heading-small-semibold colour-primary margin-x-small text-align-center">
                    We’re brewing up your data
                </h1>
                <span className="body-regular colour-secondary block-text margin-large text-align-center">
                    Your cities will appear here once we finish pulling the data.
                    <br/>
                    We’ll  email you once they’re ready. Check back in about 30 minutes. 
                    <br/>
                    In the meantime, you can...
                </span>
                <div className="all-cities-loading-bottom-container">
                    <div 
                        className="button-row text-button"
                        onClick={() => viewSample()}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                            <path 
                                fillRule="evenodd" 
                                clipRule="evenodd" 
                                d="M13.3702 5.70321C13.3702 5.70326 13.3702 5.70331 13.3702 5.70336H14.3066C14.5204 5.70336 14.6937 5.87624 14.6937 6.08949V14.6164C14.6937 15.3805 14.0727 16 13.3066 16H3.69374C2.92767 16 2.30664 15.3805 2.30664 14.6164V6.08949C2.30664 5.87624 2.47996 5.70336 2.69374 5.70336H3.63017C3.68293 2.11915 5.93857 0 8.50019 0C9.61341 0 10.6688 0.400214 11.5108 1.14696L10.1802 2.72722C9.66753 2.26225 9.05304 2.05933 8.50019 2.05933C7.27333 2.05933 5.74298 3.05864 5.6949 5.70336H11.3055C11.3055 5.70331 11.3055 5.70326 11.3055 5.70321H13.3702ZM9.88729 9.78984C9.88729 10.2528 9.65937 10.6626 9.30935 10.9138L9.56165 12.9764C9.58042 13.1298 9.46043 13.265 9.30551 13.265H7.66261C7.50769 13.265 7.3877 13.1298 7.40647 12.9764L7.66142 10.8919C7.32822 10.6392 7.11309 10.2396 7.11309 9.78984C7.11309 9.02569 7.73412 8.40623 8.50019 8.40623C9.26625 8.40623 9.88729 9.02569 9.88729 9.78984Z" 
                                fill="#2F4858"
                            />
                        </svg>
                        <span className="body-regular text-link">
                            Explore sample market
                        </span>
                    </div>
                    <span className="body-regular colour-secondary block-text margin-x-x-small text-align-center">
                        or
                    </span>
                    <div 
                        className="button-row text-button"
                        onClick={() => goToSearch()}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path 
                                d="M8.90628 0.447049C9.07697 0.272528 9.30834 0.188985 9.53554 0.197348C9.76273 0.189356 9.99373 0.2729 10.1636 0.447142L15.7344 6.14873C16.0507 6.47249 16.0393 6.9862 15.709 7.29622C15.3786 7.60623 14.8544 7.59508 14.5381 7.27131L9.53516 2.15054L6.78768 4.96247C6.24307 4.68384 5.64242 4.50101 5.00879 4.43616L8.90628 0.447049Z" 
                                fill="#2F4858"
                            />
                            <path 
                                d="M8.949 11.8535L9.8937 12.7698H14.3936C14.8225 12.7698 15.1704 12.429 15.1704 12.0086V7.71161C15.0372 7.71505 14.9037 7.68726 14.782 7.62844V12.0086C14.782 12.2188 14.6082 12.3892 14.3936 12.3892H14.0052V7.63123C14.0052 7.59201 13.993 7.55558 13.9725 7.52538C13.9376 7.47445 13.8785 7.44091 13.811 7.44091H9.12926C9.39141 8.03616 9.54185 8.69135 9.55331 9.38021C9.5682 10.2758 9.34701 11.1182 8.949 11.8535Z" 
                                fill="#2F4858"
                            />
                            <path 
                                fillRule="evenodd" 
                                clipRule="evenodd" 
                                d="M6.99885 13.1364C6.30458 13.6115 5.4656 13.8967 4.56549 13.9117C2.11425 13.9525 0.0769641 11.9854 0.0362621 9.53853C-0.00443989 7.09167 1.97196 5.05781 4.41753 5.01713C6.86877 4.97636 8.90606 6.94345 8.94676 9.39031C8.96255 10.3397 8.67709 11.2205 8.17963 11.9523L10.6833 14.3808C10.8444 14.5364 10.9215 14.7443 10.925 14.959C10.9328 15.4224 10.5935 15.7955 10.1237 15.8033C9.89723 15.807 9.68646 15.7314 9.51965 15.5759L6.99885 13.1364ZM1.22508 9.51875C1.25488 11.3101 2.7512 12.7549 4.54575 12.725C6.3403 12.6952 7.78774 11.2014 7.75794 9.41008C7.72814 7.61874 6.23183 6.17398 4.43727 6.20383C2.64272 6.23368 1.19529 7.72741 1.22508 9.51875Z" 
                                fill="#2F4858"
                            />
                        </svg>
                        <span className="body-regular text-link">
                            Try property lookup
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AllCitiesLoading;