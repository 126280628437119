import React from 'react';
import '../styles/CreativeTool.css';

function CreativeToolPricing(props) {

    const openCreativeTool = () => {
        const link = "http://creative.coffeeclozers.com/pricing";
        window.open(link, "_blank")
    };
    
    return (
        <div className="creative-tool-pricing-container margin-large">
            <div 
                className="creative-tool-outer-container cursor-pointer"
                onClick={() => openCreativeTool()}
            >
                <div className="creative-tool-inner-container">
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="16" 
                        height="16" 
                        viewBox="0 0 16 16" 
                        fill="none"
                        className="block-text subtext-margin-right"
                    >
                        <path 
                            fillRule="evenodd" 
                            clipRule="evenodd" 
                            d="M2.73077 0L2.82356 0.470058C3.09683 1.85436 4.11124 2.97513 5.46154 3.38462C4.11124 3.7941 3.09683 4.91487 2.82356 6.29917L2.73077 6.76923L2.63798 6.29917C2.36471 4.91487 1.3503 3.7941 0 3.38462C1.3503 2.97513 2.36471 1.85436 2.63798 0.470058L2.73077 0ZM10 0.615385L10.7199 4.2365C11.0027 5.65922 12.0475 6.80976 13.4364 7.22802L16 8L13.4364 8.77198C12.0475 9.19024 11.0027 10.3408 10.7199 11.7635L10 15.3846L9.28014 11.7635C8.99731 10.3408 7.9525 9.19024 6.56355 8.77198L4 8L6.56356 7.22802C7.9525 6.80976 8.9973 5.65922 9.28014 4.2365L10 0.615385ZM4.04398 10.2608L3.96154 9.84615L3.8791 10.2608C3.6274 11.527 2.6976 12.5509 1.46154 12.9231C2.6976 13.2953 3.6274 14.3192 3.8791 15.5853L3.96154 16L4.04398 15.5853C4.29567 14.3192 5.22547 13.2953 6.46154 12.9231C5.22547 12.5509 4.29567 11.527 4.04398 10.2608Z" 
                            fill="#292621"
                        />
                    </svg>
                    <span className="body-regular colour-primary block-text subtext-margin-right">
                        Looking for the Creative Finance tool?
                    </span>
                    <div className="creative-tool-button-container">
                        <span className="body-semibold colour-white block-text subtext-margin-right">
                            Yes
                        </span>
                        <svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="16" 
                            height="16" 
                            viewBox="0 0 16 16" 
                            fill="none"
                        >
                            <path 
                                d="M15.5 1.85735V14.0735C15.5 14.8231 14.8923 15.4308 14.1427 15.4308C13.393 15.4308 12.7853 14.8231 12.7853 14.0735L12.7853 5.13427L2.81714 15.1024C2.28706 15.6325 1.42764 15.6325 0.897558 15.1024C0.36748 14.5724 0.367481 13.7129 0.897558 13.1829L10.8657 3.2147L1.92653 3.21469C1.17688 3.21469 0.56918 2.60699 0.56918 1.85735C0.569179 1.10771 1.17689 0.5 1.92653 0.5L14.1427 0.500002C14.8923 0.500002 15.5 1.10771 15.5 1.85735Z" 
                                fill="#FFFEFC"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreativeToolPricing;