import React, { useState } from 'react';
import '../styles/ChooseBuyBoxName.css';
import { NewInput } from '../styles/GlobalStyles';
import { BuyBoxFrequency, ExitScreen, BuyBoxMetroCity } from './';

function ChooseBuyBoxName(props) {
    const title = props.title;
    const setTitle = props.setTitle;
    const item = props.buyBox;
    const frequency = props.frequency;
    const setFrequency = props.setFrequency;
    const buyBoxCity = props.buyBoxCity;
    const buyBoxMetroCity = props.buyBoxMetroCity;
    const onChangeMetroCity = props.onChangeMetroCity;
    const [buyBoxFrequency, setBuyBoxFrequency] = useState(false);

    const onChangeBuyBoxFrequency = (val) => {
        setFrequency(val);
    };

    return (
        <div className="choose-buy-box-name-outer-container">
            {
                buyBoxFrequency !== false && buyBoxFrequency !== null ?
                <ExitScreen
                    clickScreen={() => setBuyBoxFrequency(null)}
                />
                :
                null
            }
            <div className="choose-buy-box-name-element-container margin-medium">
                <span className="body-semibold colour-primary block-text margin-x-small">
                    Buy box nickname
                </span>
                <NewInput
                    value={title}
                    type="text"
                    label=""
                    id={`buy-box-title-input`}
                    onChange={(text) => setTitle(text.target.value)}
                />
            </div>
            {
                buyBoxCity !== null ?
                <BuyBoxMetroCity
                    buyBoxCity={buyBoxCity}
                    buyBoxMetroCity={buyBoxMetroCity}
                    onChangeMetroCity={onChangeMetroCity}
                />
                :
                null
            }
            <div className="choose-buy-box-name-frequency-container">
                <span className="body-semibold colour-primary subtext-margin-right-x-x-large">
                    Notification frequency
                </span>
                <BuyBoxFrequency
                    frequency={frequency}
                    buyBoxFrequency={buyBoxFrequency !== false && buyBoxFrequency !== null ? true : false}
                    setBuyBoxFrequency={setBuyBoxFrequency}
                    id={item.id}
                    onChangeBuyBoxFrequency={onChangeBuyBoxFrequency}
                    city={item.city}
                    state={item.state}
                />
            </div>
        </div>
    )
};

export default ChooseBuyBoxName;