import React, { useEffect, useState, useRef } from 'react';
import '../styles/Pay.css';
import { Header, Footer, CityPricingCard, CityMobilePricingOverlay, LoadingStatic, CreditCard, SetupForm, DeleteCardModal, Loading } from '../components';
import { ChevronLeft } from '../assets';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ActionButton, colour } from '../styles/GlobalStyles';
import { checkOnAuthStateChanged, getDocument, getSetupIntent, getStripeCustomer, recordEvent, setData, createStripeSetupIntent, deleteStripePaymentMethod, payAutoBilling, updateStripeCustomer, checkStripeError, writeCreditsSubscriptionData } from '../functions';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import TagManager from 'react-gtm-module';

const mode = window.location.hostname === "localhost" || window.location.hostname.includes("192.") || window.location.hostname.includes("refi-787d3") ? "test" : "live";
const stripePromise = loadStripe(mode === "test" ? JSON.parse(process.env.REACT_APP_STRIPE).test.publishable : JSON.parse(process.env.REACT_APP_STRIPE).live.publishable);

function BuyCredits() {
    const [mobileAccordion, setMobileAccordion] = useState(false);
    const [userData, setUserData] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [paymentError, setPaymentError] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams({});
    const stateSearchParams = searchParams.get('stateId');
    const [deleteModal, setDeleteModal] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const discountApplied = null;
    const [subscriptions, setSubscriptions] = useState([]);
    const [userId, setUserId] = useState("");
    const creditCardButton = useRef(null);
    const navigate = useNavigate();
    const chosenCity = {
        msaTitle: "Unlimited Quick Report Credits",
        metroArea: true
    };
    const subscriptionId = mode === "test" ? "price_1PKAM4ERRPhVxjedcjxWf7mC" : "price_1PKALQERRPhVxjed8b8kGbFK";
    const autoBilling = true;
    const pricing = [
        {
            label: "Auto-renew",
            description: "All features",
            guarantee: "",
            discount: 0,
            months: 1,
            price: 30
        }
    ];
    const selectedPlan = 0;
    const plan = {
		label: "Auto-renew",
		description: "All features",
		guarantee: "",
		discount: 0,
		months: 1,
        total: 30
	};

    const options = {
        clientSecret: clientSecret,
        fonts: [
            {
                cssSrc: "https://fonts.googleapis.com/css?family=Rubik"
            }
        ],
        appearance: {
            theme: "stripe",
            variables: {
                fontFamily: "Rubik",
                colorBackground: colour.grayScaleWhite,
                colorText: colour.textPrimary,
                colorDanger: colour.redRed01,
                borderRadius: '3px',
                fontSizeBase: '14px',
                colorPrimary: colour.brownBrown01
            }
        }
    };

    useEffect(() => {
        document.title = "Buy Credits | Coffee Clozers";
        const fetchUserData = async () => {
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const colRef = "Users";
                const docRef = user.userId;
                setUserId(docRef);
                const queryData = await getDocument(colRef, docRef);
                if ( queryData.status === 200 ) {
                    const userDetails = queryData.data.data;

                    if ( userDetails.creditCard === undefined ) {
                        setupIntent(userDetails, docRef);
                    }
                }

                const subColRef = "Subscriptions";
                const subDocRef = user.userId;
                const subQueryData = await getDocument(subColRef, subDocRef);
                if ( subQueryData.status === 200 ) {
                    setSubscriptions(subQueryData.data.data);
                    setUserData(queryData.data.data);
                }
                else {
                    setUserData(queryData.data.data);
                }
            }
            else {
                navigate("/quick-report");
            }
        };

        const setupIntent = async(userDetails, userId) => {
            const getSetupIntentParams = searchParams.get('setup_intent');
            if ( clientSecret !== "" ) {
                return;
            }
            else if ( getSetupIntentParams !== null && userDetails.creditCard === undefined ) {
                const card = await getSetupIntent(getSetupIntentParams, mode);
                if ( card !== "" ) {
                    const amendedCard = {
                        brand: card.brand,
                        last4: card.last4,
                        exp_month: card.exp_month,
                        exp_year: card.exp_year,
                        mode: card.mode,
                        paymentMethod: card.paymentMethod
                    };
                    const colRef = "Users";
                    const newUserDetails = structuredClone(userDetails);
                    newUserDetails.creditCard = amendedCard;
                    await setData(colRef, userId, newUserDetails);
                    setUserData(newUserDetails);
                }
            }
            else {
                let customerId = userDetails.customerId;
                if ( customerId === undefined ) {
                    const customer = {
                        email: userDetails.email,
                        name: `${userDetails.firstName} ${userDetails.lastName}`,
                        phone: userDetails.phoneNumber !== undefined ? userDetails.phoneNumber : "",
                        mode: mode
                    };
                    const getCustomer = await getStripeCustomer(customer);
                    customerId = getCustomer.customerId;
                    const colRef = "Users";
                    const docRef = userId;
                    const data = userDetails;
                    data.customerId = customerId;
                    setUserData(data);
                    await setData(colRef, docRef, data);
                }
                const params = {
                    customerId: customerId,
                    mode: mode
                };
                const getIntent = await createStripeSetupIntent(params);
                if ( getIntent.status === 200 ) {
                    const id = getIntent.setupIntent.client_secret;
                    setClientSecret(id);
                }
            }
        };
        
        fetchUserData();
    }, [navigate, clientSecret, searchParams, setSearchParams, stateSearchParams]);

    const showDeleteModal = () => {
        recordEvent("Show Delete Card Modal");
        setDeleteModal(true);
    };

    const toggleAccordion = () => {
        setMobileAccordion(!mobileAccordion);
    };

    const deleteCard = async(item) => {
        setDisabled(true);
        setDeleteModal(false);
        let clone = JSON.parse(JSON.stringify(userData))
        delete clone.creditCard;
        setClientSecret("");
        setUserData(clone);
        const params = {
            mode: item.mode,
            paymentMethod: item.paymentMethod
        };
        const deletion = await deleteStripePaymentMethod(params);
        if ( deletion.status === 200 ) {
            const colRef = "Users";
            const data = clone;
            await setData(colRef, userId, data);
            setUserData(clone);

            const setupIntentParams = {
				customerId: data.customerId,
				mode: item.mode
			};
			const getIntent = await createStripeSetupIntent(setupIntentParams);
			if ( getIntent.status === 200 ) {
				const id = getIntent.setupIntent.client_secret;
				setClientSecret(id);
				setDisabled(false);
			}
        }
        setDisabled(false);
	};

    const goBack = () => {
        navigate("/quick-report");
    };

    const saveCreditCard = () => {
        if ( creditCardButton.current !== null) {
            creditCardButton.current.click();
        }
    };

    const complete = async(newUserDetails) => {
        // 1. Check if auto-billing is present
        // 2. If it IS present, we need to create individual subscriptions for each city as separate items
        // 3. If it is NOT present, we need to create a single payment for the total amount
        // 4. We then need to write the subscription data to the database
        // 5. We then need to let Ariel know via API so she can commence data processing
        if ( newUserDetails === undefined ) {
            newUserDetails = userData;
        }

        setLoading(true);
        const metadata = {
            chosenCity: chosenCity,
            months: plan.months,
            price: plan.total,
            subscriptionId: subscriptionId,
            allCities: false,
            plan: plan,
            creditCount: 99999
        };
        const sendPayment = await payAutoBilling(newUserDetails, mode, metadata);
        if ( sendPayment.status === 200 ) {
            const writeData = await writeCreditsSubscriptionData(sendPayment, autoBilling, metadata, newUserDetails, null);
            if ( writeData.status === 200 ) {
                setPaymentError("");

                const tagManagerArgs = {
                    dataLayer: {
                        event: "purchase",
                        live: mode === "live" ? true : false,
                        credits_only: true,
                        value: plan.total,
                        currency: "USD"
                    }
                };
                TagManager.dataLayer(tagManagerArgs);

                navigate("/quick-report");
                recordEvent("Payment Success", {
                    mode: mode,
                    metadata: metadata,
                    price: plan.total
                });
            }
            setLoading(false);
        }
        else if ( sendPayment.status === 404 ) {
            const newCustomerData = {
                paymentMethod: newUserDetails.creditCard.paymentMethod,
                customerId: newUserDetails.customerId,
                mode: mode
            }
            const setDefaultPaymentMethod = await updateStripeCustomer(newCustomerData);
            if ( setDefaultPaymentMethod.status === 200 ) {
                complete(userData);
            }
            else {
                setPaymentError("There was an error processing your subscription. Please delete your credit card and re-add it.");
                setLoading(false);
            }
        }
        else {
            const error = autoBilling === true ? sendPayment.subscription : sendPayment.paymentMethod;
            const checkedError = await checkStripeError(error);
            setPaymentError(checkedError);
            setLoading(false);
        }
    };

    return (
        <div className="pay-outer-container">
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
                mobileNav={true}
            />
            <div className="pay-inner-container">
                {
                    loading === true ?
                    <Loading />
                    :
                    null
                }
                {
                    userData !== null && userData.creditCard !== undefined && deleteModal === true ?
                    <DeleteCardModal
                        creditCard={userData.creditCard}
                        deleteFunction={deleteCard}
                        setDeleteModal={setDeleteModal}
                    />
                    :
                    null
                }
                <div className="pay-row-container">
                    <div className="pay-left-container margin-x-small">
                        <h1 className="heading-small-semibold colour-primary margin-large">
                            Payment method
                        </h1>
                        {
                            userData !== null && userData.creditCard !== undefined ?
                            <span className="body-regular colour-secondary block-text margin-large">
                                Found card on file
                            </span>
                            :
                            null
                        }
                        {
                            userData === null ?
                            <LoadingStatic />
                            :
                            userData.creditCard !== undefined ?
                            <CreditCard
                                creditCard={userData.creditCard}
                                deleteCard={showDeleteModal}
                                checkout={true}
                            />
                            :
                            clientSecret !== "" ?
                                <Elements
                                    stripe={stripePromise} 
                                    options={options}
                                >
                                    <SetupForm
                                        clientSecret={clientSecret}
                                        domain="buy-credits"
                                        bundle={false}
                                        userData={userData}
                                        setUserData={setUserData}
                                        setPaymentMethodModal={null}
                                        autoBilling={true}
                                        setAutoBilling={null}
                                        product={plan}
                                        city={null}
                                        loading={loading}
                                        setLoading={setLoading}
                                        setPaymentError={setPaymentError}
                                        payFunc={complete}
                                        creditCardButton={creditCardButton}
                                        setDisabled={setDisabled}
                                        pageState={null}
                                    />
                                </Elements>
                            :
                            <LoadingStatic />
                        }
                        {
                            paymentError !== "" ?
                            <div className="pay-payment-error-container margin-top-small">
                                <span className="body-regular colour-error">
                                    {paymentError}
                                </span>
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className="pay-right-container">
                        <CityPricingCard
                            chosenCity={chosenCity}
                            deleteCity={null}
                            checkout={true}
                            total={plan.total}
                            pricing={pricing}
                            selectedPlan={selectedPlan}
                            allCities={false}
                            discountApplied={discountApplied}
                        />
                    </div>
                </div>
                <div className="new-checkout-buttons-row margin-top-x-x-large mobile-none">
                    <div 
                        className="button-row text-button"
                        onClick={() => goBack()}
                    >
                        <img
                            src={ChevronLeft}
                            className="city-selection-back-icon"
                            alt="Back icon"
                        />
                        <span className="body-regular colour-link">
                            Back
                        </span>
                    </div>
                    <div className="city-selection-next-button">
                        <ActionButton
                            disabled={userData === null || loading === true || disabled === true ? true : false}
                            onClick={() => userData !== null && userData.creditCard !== undefined ? complete() : saveCreditCard()}
                        >
                            Submit payment
                        </ActionButton>
                    </div>
                </div>
                {
                    deleteModal === true ?
                    null
                    :
                    <CityMobilePricingOverlay
                        toggleAccordion={toggleAccordion}
                        mobileAccordion={mobileAccordion}
                        chosenCity={chosenCity}
                        deleteCity={null}
                        complete={complete}
                        checkout={true}
                        total={plan.total}
                        pricing={pricing}
                        selectedPlan={selectedPlan}
                        cta="Submit payment"
                        payment={true}
                        disabled={userData === null || loading === true || disabled === true ? true : false}
                        userData={userData}
                        saveCreditCard={saveCreditCard}
                        allCities={false}
                        discountApplied={discountApplied}
                    />
                }
            </div>
            <Footer />
        </div>
    );
}

export default BuyCredits;