import React, { useRef, useState } from 'react';
import '../styles/Property.css';
import { Header, Footer, StickyROI, ImageGrid, StrategiesPanel, ContactAgent, ShareButtons, PropertyAbstract, PropertyDetails, Schools, Location, OwnerDetails, PurchaseDetails, OffMarketImageContainer, Comparables, MobileBottomBar, ExitScreen, ToastMessage, MadeUpData, NeighbourhoodData, LastUpdated, ARVCompsAnalysis, RentCompsAnalysis } from '../components';
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { toggleFavourite, downloadExcel, recordEvent } from '../functions';
import { formatterCompact, formatterLong } from '../styles/GlobalStyles';
import { LoadingAnimation } from '../assets/animations';
import { useLottie } from 'lottie-react';
import { PropertyPageEffect } from '../queries';
import { PropertyPDF } from '../pdfs';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

function Property() {

	const params = useParams();
	const cityId = decodeURIComponent(params.cityId);
	const id = decodeURIComponent(params.id);
	const [property, setProperty] = useState([]);
	const [userId, setUserId] = useState(null);
	const [favourites, setFavourites] = useState([]);
	const [favouriteDisable, setFavouriteDisable] = useState(false);
	const [editedProperty, setEditedProperty] = useState("");
	const [userDetails, setUserDetails] = useState(null);
	const [downPayment, setDownPayment] = useState("");
	const [interestRate, setInterestRate] = useState("");
	const [loanYears, setLoanYears] = useState(30);
	const [closingPercentage, setClosingPercentage] = useState(3);
	const [highLevelProfits, setHighLevelProfits] = useState("");
	const [addBedroom, setAddBedroom] = useState(false);
	const [queryMade, setQueryMade] = useState(false);
	const [bugModal, setBugModal] = useState(false);
	const [neighbourhoodData, setNeighbourhoodData] = useState([]);
	const [tracts, setTracts] = useState([]);
	const [neighbourhoodBounds, setNeighbourhoodBounds] = useState([]);
	const [freeTrial, setFreeTrial] = useState(true);
	const [mobileBar, setMobileBar] = useState(false);
	const [blurred, setBlurred] = useState(false);
	const [loading, setLoading] = useState(true);
	const [recurringCosts, setRecurringCosts] = useState([]);
	const [renovationRadio, setRenovationRadio] = useState(0);
	const [spreadsheetProperty, setSpreadsheetProperty] = useState(null);
	const [costPerSqFoot, setCostPerSqFoot] = useState(0);
	const [totalRehabCost, setTotalRehabCost] = useState(0);
	const [defaultCostPerSqFoot, setDefaultCostPerSqFoot] = useState(0);
	const [cityState, setCityState] = useState("");
	const [userSettings, setUserSettings] = useState(null);
	const [defaultDownPayment, setDefaultDownPayment] = useState(20);
	const [defaultInterestRate, setDefaultInterestRate] = useState(7);
	const [searchParams] = useSearchParams({});
	const getStrategy = searchParams.get("strategy");
	const strategyParam = getStrategy === null ? 0 : parseInt(getStrategy) > 3 ? 0 : parseInt(getStrategy);
	const [strategyPanel, setStrategyPanel] = useState(strategyParam);
	const [selectedCompsPanel, setSelectedCompsPanel] = useState(0);
	const [recommendedStrategy, setRecommendedStrategy] = useState(null);
	const [activeToast, setActiveToast] = useState(null);
	const [propertyTaxSetting, setPropertyTaxSetting] = useState(null);
	const [toastText, setToastText] = useState("");
	const [noProperty, setNoProperty] = useState(false);
	const [showARVCompsAnalysis, setShowARVCompsAnalysis] = useState(false);
	const [showRentCompsAnalysis, setShowRentCompsAnalysis] = useState(false);
	const [arvComps, setArvComps] = useState([]);
	const [signUpModal, setSignUpModal] = useState(false);
	const [savedSalesCompData, setSavedSalesCompData] = useState(null);
	const [savedRentCompData, setSavedRentCompData] = useState(null);
	const [applyEstimateModal, setApplyEstimateModal] = useState(false);
	const [innerWidth, setInnerWidth] = useState(window.innerWidth);
	const compsRef = useRef(null);
	const navigate = useNavigate();
	const location = useLocation();
	const state = location.state;
	// const origin = window.location.origin;
	// const devMode = origin.includes("localhost") || window.location.hostname.includes("192.") || origin.includes("refi") ? true : false;

	const options = {
        animationData: LoadingAnimation,
        loop: true
    };
    const { View } = useLottie(options);

	const toggleMobileBottomBar = () => {
		if ( mobileBar === true ) {
			document.body.removeAttribute("style");
		}
		else {
			document.body.style = "overflow: hidden";
		}
		setMobileBar(!mobileBar);
	};

	const viewRentalComps = () => {
		if ( compsRef.current !== null ) {
			recordEvent("View Rental Comps", {
				"CityId": cityId,
				"zpid": id
			});
            compsRef.current.scrollIntoView({ 
                behavior: 'smooth',
            });
			setSelectedCompsPanel(0);
        }
	};

	const viewArvComps = () => {
		if ( compsRef.current !== null ) {
			recordEvent("View ARV Comps", {
				"CityId": cityId,
				"zpid": id
			});
            compsRef.current.scrollIntoView({ 
                behavior: 'smooth',
            });
			setSelectedCompsPanel(1);
        }
	};

	const closeToast = () => {
		setActiveToast(false);
	};

	const downloadPDF = async() => {
		if ( property.length !== 0 ) {
			const doc = <PropertyPDF property={property} propertySearch={false} partner={false} highLevelProfits={highLevelProfits} />;
			const asPdf = pdf([]); // {} is important, throws without an argument
			asPdf.updateContainer(doc);
			const blob = await asPdf.toBlob();
			saveAs(blob, `${property.address.streetAddress}, ${property.address.city}, ${property.address.zipcode}.pdf`);
		}
	};

	const mapImages = () => {
		if ( property.length !== 0 && property.images !== undefined && property.images !== null && property.images.length > 0 ) {
			const firstImage = property.images[0];
			if ( firstImage.includes("maps.googleapis.com") ) {
				return true;
			}
			else {
				return false;
			}
		}
		else {
			return false;
		}
	};

	const runComps = () => {
		recordEvent("Run Comps", {
			zpid: id
		});

		if ( selectedCompsPanel === 0 ) {
			setShowRentCompsAnalysis(true);
		}
		else {
			setShowARVCompsAnalysis(true);
		}
	};

	const switchCompsTool = (val) => {
		const type = val === 1 ? "Sales" : "Rent";
		recordEvent(`Switch Comps - ${type}`, {
			zpid: id
		});

		if ( selectedCompsPanel === 0 ) {
			setShowRentCompsAnalysis(false);
			setShowARVCompsAnalysis(true);
		}
		else {
			setShowRentCompsAnalysis(true);
			setShowARVCompsAnalysis(false);
		}
		setSelectedCompsPanel(val);
	};

	const closeCompsAnalysis = () => {
		recordEvent("Comps Analysis Closed", {});
		setShowARVCompsAnalysis(false);
		setShowRentCompsAnalysis(false);
	};

	const saveCompsAnalysis = () => {
		if ( userId === null ) {
			setSignUpModal(true);
		}
		else {
			setShowARVCompsAnalysis(false);
			setShowRentCompsAnalysis(false);
			recordEvent("Comps Analysis Saved", {});
		}
	};

	return (
		<div className={"property-outer-container " + (bugModal === true ? "property-outer-container-relative" : mobileBar === true ? "property-mobile-bar-active" : "") + (blurred === true ? " property-blurred" : "")}>
			<Header
				subscriptions={null}
                users={userDetails}
                queries={[false, true]}
				mobileNav={true}
			/>
			{
				blurred === true ?
				<MadeUpData />
				:
				null
			}
			<PropertyPageEffect
				cityId={cityId}
				setProperty={setProperty}
				setMobileBar={setMobileBar}
				setFreeTrial={setFreeTrial}
				setUserDetails={setUserDetails}
				setFavourites={setFavourites}
				navigate={navigate}
				setBlurred={setBlurred}
				id={id}
				setAddBedroom={setAddBedroom}
				editedProperty={editedProperty}
				queryMade={queryMade}
				setQueryMade={setQueryMade}
				setLoading={setLoading}
				setEditedProperty={setEditedProperty}
				setDownPayment={setDownPayment}
				setInterestRate={setInterestRate}
				setClosingPercentage={setClosingPercentage}
				setTotalRehabCost={setTotalRehabCost}
				setCostPerSqFoot={setCostPerSqFoot}
				setDefaultCostPerSqFoot={setDefaultCostPerSqFoot}
				setHighLevelProfits={setHighLevelProfits}
				setRecurringCosts={setRecurringCosts}
				setSpreadsheetProperty={setSpreadsheetProperty}
				propertySearch={false}
				state={state}
				setCityState={setCityState}
				setUserSettings={setUserSettings}
				setLoanYears={setLoanYears}
				setDefaultDownPayment={setDefaultDownPayment}
				setDefaultInterestRate={setDefaultInterestRate}
				setRecommendedStrategy={setRecommendedStrategy}
				getStrategy={getStrategy}
				setStrategyPanel={setStrategyPanel}
				setPropertyTaxSetting={setPropertyTaxSetting}
				setNeighbourhoodBounds={setNeighbourhoodBounds}
				setNeighbourhoodData={setNeighbourhoodData}
				setTracts={setTracts}
				strategyPanel={strategyPanel}
				setNoProperty={setNoProperty}
				testMode={false}
				setCityId={null}
				setCommercialProperty={null}
				setLoadingStep={null}
				setArvComps={setArvComps}
				partner={false}
                partnerId={null}
				setUserId={setUserId}
				setSavedSalesCompData={setSavedSalesCompData}
				setSavedRentCompData={setSavedRentCompData}
				setInnerWidth={setInnerWidth}
				setPartnerAmendments={null}
			/>
			{
				bugModal === true ?
				<div className="property-outer-container-bug-modal"></div>
				:
				null
			}
			{
				showARVCompsAnalysis === true && property.arvCompsSimilarProp !== null ?
				<ARVCompsAnalysis
					property={property}
					neighbourhoodData={neighbourhoodData}
					neighbourhoodBounds={neighbourhoodBounds}
					comps={arvComps}
					closeCompsAnalysis={closeCompsAnalysis}
					saveCompsAnalysis={saveCompsAnalysis}
					userId={userId}
					signUpModal={signUpModal}
					setSignUpModal={setSignUpModal}
					savedData={savedSalesCompData}
					setSavedData={setSavedSalesCompData}
					editedProperty={editedProperty}
					setEditedProperty={setEditedProperty}
					loanYears={loanYears}
					strategyPanel={strategyPanel}
					switchCompsTool={switchCompsTool}
				/>
				:
				showRentCompsAnalysis === true ?
				<RentCompsAnalysis
					property={property}
					neighbourhoodData={neighbourhoodData}
					neighbourhoodBounds={neighbourhoodBounds}
					comps={property.rentComps}
					closeCompsAnalysis={closeCompsAnalysis}
					saveCompsAnalysis={saveCompsAnalysis}
					userId={userId}
					signUpModal={signUpModal}
					setSignUpModal={setSignUpModal}
					savedData={savedRentCompData}
					setSavedData={setSavedRentCompData}
					editedProperty={editedProperty}
					setEditedProperty={setEditedProperty}
					loanYears={loanYears}
					strategyPanel={strategyPanel}
					switchCompsTool={switchCompsTool}
				/>
				:
				null
			}
			<div className="property-relative-container">
				{
					property.length === 0 || highLevelProfits === "" || userDetails === null ?
					null
					:
					<StickyROI
						formatter={formatterLong}
						highLevelProfits={highLevelProfits}
						toggleFavourite={toggleFavourite}
						favourites={favourites}
						setFavourites={setFavourites}
						setFavouriteDisable={setFavouriteDisable}
						navigate={navigate}
						favouriteDisable={favouriteDisable}
						freeTrial={freeTrial}
						downloadCSV={downloadExcel}
						property={property}
						cityId={cityId}
						downPayment={downPayment}
						blurred={blurred}
						spreadsheetProperty={spreadsheetProperty}
						interestRate={interestRate}
						propertySearch={false}
						partner={false}
						partnerId={null}
						partnerView={false}
						downloadPDF={downloadPDF}
						strategyPanel={strategyPanel}
						oldFormat={false}
						testMode={false}
					/>
				}
			</div>
			{
				loading === true ?
				<div className="property-loading-container">
					{View}
				</div>
				:
				(queryMade === true && property.length === 0) || (noProperty === true) ?
				<div className="property-body-container property-no-property-container">
					<h2 className="property-not-found-text">
						No property found {":("}
					</h2>
				</div>
				:
				property.length !== 0 && userDetails !== null ?
				<div className={"property-body-container " + (mobileBar === true ? "relative-container" : "") + (showARVCompsAnalysis === true || showRentCompsAnalysis ? " desktop-none" : "")}>
					{
						property.images !== undefined && property.images !== null && property.images.length > 0 && mapImages() === false ?
						<ImageGrid 
							property={property}
							images={property.images !== undefined ? property.images : [property.imgSrc]}
							title={property.address.streetAddress}
							preview={false}
							blurred={blurred}
						/>
						:
						null
					}
					{/* {
						devMode === true ?
						<SellerFinanceCalculator
							property={property}
							editedProperty={editedProperty}
						/>
						:
						null
					} */}
					<div className="property-inner-body-container">
						<div className="property-inner-body-left-container">
							{
								property.images === undefined || property.images === null || property.images.length === 0 ?
								<OffMarketImageContainer
									property={property}
								/>
								:
								null
							}
							<PropertyAbstract
								property={property}
								formatter={formatterLong}
								propertySearch={false}
								queryARV={null}
								arvLoading={false}
								arvErrorMessage={null}
								partner={false}
								strategy={strategyPanel}
								runComps={runComps}
							/>
							<PropertyDetails
								property={property}
								formatter={formatterLong}
								propertySearch={false}
							/>
							{
								property.lastDataUpdateTimestamp !== undefined && property.lastDataUpdateTimestamp !== null && property.lastDataUpdateTimestamp !== "None" ?
								<LastUpdated
									property={property}
								/>
								:
								null
							}
							{
								property.ownerAttributes !== undefined ?
								<OwnerDetails
									property={property}
									blurred={blurred}
									freeTrial={freeTrial}
								/>
								:
								null
							}
							{
								property.offMarket === true ?
								<PurchaseDetails
									property={property}
								/>
								:
								null
							}
							{
								neighbourhoodData.length !== 0 ?
								<NeighbourhoodData
									neighbourhoodData={neighbourhoodData}
									property={property}
									setShowUpgradeModal={null}
								/>
								:
								null
							}
							<Schools
								property={property}
							/>
						</div>
						{
							mobileBar === true ?
							<ExitScreen
								clickScreen={() => toggleMobileBottomBar()}
							/>
							:
							null
						}
						{
							(showARVCompsAnalysis === true || showRentCompsAnalysis === true || applyEstimateModal === true) && innerWidth > 999 ?
							null
							:
							editedProperty !== "" ?
							<StrategiesPanel
								property={property}
								userDetails={userDetails}
								editedProperty={editedProperty}
								formatter={formatterLong}
								downPayment={downPayment}
								interestRate={interestRate}
								closingPercentage={closingPercentage}
								setHighLevelProfits={setHighLevelProfits}
								setDownPayment={setDownPayment}
								setInterestRate={setInterestRate}
								setClosingPercentage={setClosingPercentage}
								setEditedProperty={setEditedProperty}
								formatterCompact={formatterCompact}
								addBedroom={addBedroom}
								setAddBedroom={setAddBedroom}
								bugModal={bugModal}
								setBugModal={setBugModal}
								highLevelProfits={highLevelProfits}
								mobileBar={mobileBar}
								blurred={blurred}
								recurringCosts={recurringCosts}
								setRecurringCosts={setRecurringCosts}
								renovationRadio={renovationRadio}
								setRenovationRadio={setRenovationRadio}
								spreadsheetProperty={spreadsheetProperty}
								setSpreadsheetProperty={setSpreadsheetProperty}
								setProperty={setProperty}
								costPerSqFoot={costPerSqFoot}
								setCostPerSqFoot={setCostPerSqFoot}
								totalRehabCost={totalRehabCost}
								setTotalRehabCost={setTotalRehabCost}
								defaultCostPerSqFoot={defaultCostPerSqFoot}
								partnerData={null}
								userSettings={userSettings}
								strategyPanel={strategyPanel}
								setStrategyPanel={setStrategyPanel}
								cityId={cityId}
								loanYears={loanYears}
								defaultTotalRehabCost={property.livingArea !== undefined && property.livingArea !== null ? Number(property.livingArea) * Number(defaultCostPerSqFoot) : 0}
								defaultDownPayment={defaultDownPayment}
								defaultInterestRate={defaultInterestRate}
								viewRentalComps={viewRentalComps}
								viewArvComps={viewArvComps}
								recommendedStrategy={recommendedStrategy}
								getStrategy={getStrategy}
								favouriteDisable={favouriteDisable}
								setFavouriteDisable={setFavouriteDisable}
								favourites={favourites}
								setFavourites={setFavourites}
								navigate={navigate}
								toggleMobileBottomBar={toggleMobileBottomBar}
								setActiveToast={setActiveToast}
								propertyTaxSetting={propertyTaxSetting}
								setToastText={setToastText}
							/>
							:
							null
						}
					</div>
					{
						neighbourhoodData.length !== 0 && neighbourhoodBounds.length !== 0 ?
						<Location
							property={property}
							cityId={cityId}
							cityState={cityState}
							neighbourhoodData={neighbourhoodData}
							tracts={tracts}
							neighbourhoodBounds={neighbourhoodBounds}
							setShowUpgradeModal={null}
						/>
						:
						null
					}
					{
						property.rentComps !== null || property.unitPropertyComps !== undefined ?
						<Comparables
							property={property}
							blurred={blurred}
							propertySearch={false}
							compsRef={compsRef}
							selectedPanel={selectedCompsPanel}
							setSelectedPanel={setSelectedCompsPanel}
							runComps={runComps}
							savedData={savedSalesCompData}
							setSavedData={setSavedSalesCompData}
							savedRentData={savedRentCompData}
							setSavedRentData={setSavedRentCompData}
							editedProperty={editedProperty}
							setEditedProperty={setEditedProperty}
							strategyPanel={strategyPanel}
							loanYears={loanYears}
							applyEstimateModal={applyEstimateModal}
							setApplyEstimateModal={setApplyEstimateModal}
						/>
						:
						null
					}
					<div className="property-bottom-body-container">
						{
							property.listedBy === null || property.attributionInfo === null ?
							null
							:
							<ContactAgent
								property={property}
								partnerData={null}
								state={state}
							/>
						}
						<ShareButtons
							property={property}
							favourites={favourites}
							toggleFavourite={toggleFavourite}
							favouriteDisable={favouriteDisable}
							setFavourites={setFavourites}
							setFavouriteDisable={setFavouriteDisable}
							navigate={navigate}
							cityId={cityId}
							userDetails={userDetails}
							freeTrial={freeTrial}
							spreadsheetProperty={spreadsheetProperty}
							downPayment={downPayment}
							propertySearch={false}
							partner={false}
							downloadPDF={downloadPDF}
							strategyPanel={strategyPanel}
						/>
						<ToastMessage
							text={toastText}
							close={closeToast}
							active={activeToast}
						/>
					</div>
					{
						property.length === 0 || highLevelProfits === "" || userDetails === null ?
						null
						:
						<MobileBottomBar
							highLevelProfits={highLevelProfits}
							formatter={formatterLong}
							toggleMobileBottomBar={toggleMobileBottomBar}
							mobileBar={mobileBar}
							blurred={blurred}
							strategy={strategyPanel}
							property={property}
							favourites={favourites}
							setFavourites={setFavourites}
							favouriteDisable={favouriteDisable}
							setFavouriteDisable={setFavouriteDisable}
							navigate={navigate}
							cityId={cityId}
							newVersion={true}
						/>
					}
				</div>
				:
				null
			}
			{
				property.length !== 0 ?
				<Footer />
				:
				null
			}
		</div>
	)
}

export default Property;