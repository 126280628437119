import React, { useState, useRef, useEffect } from 'react';
import { ActionButton, FormInput, WhiteActionButton, CustomSelect, colour } from '../styles/GlobalStyles';
import { useSelector, useDispatch } from 'react-redux';
import { emailAction, passwordAction, firstNameAction, lastNameAction } from '../actions';
import '../styles/SignUpComponent.css';
import { createUser, setData, emailVerification, getStripeCustomer, signInWithGoogle, getDocument, recordEvent, mailchimpAPI } from '../functions/index';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GoogleLogo } from '../assets';
import { Loading } from '../components';
import TagManager from 'react-gtm-module';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';

function SignUpComponent(props) {

	const route = props.route;
	const loginRoute = props.loginRoute;
	const state = props.state;

	const firstName = useSelector(state => state.firstName);
	const lastName = useSelector(state => state.lastName);
	const email = useSelector(state => state.email);
	const password = useSelector(state => state.password);
	// const [phoneNumber, setPhoneNumber] = useState("");
	const [disabled, setDisabled] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	// const [phoneNumberList, setPhoneNumberList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [selectOpen, setSelectOpen] = useState(false);
	const [searchParams] = useSearchParams({});
	const emailParam = searchParams.get("email");
	const options = [
        {
            label: "Real Estate Rookie Podcast",
            value: false,
            gaVal: "real_estate_rookie_podcast"
        },
        {
            label: "Analytics Ariel (Tech in Real Estate)",
            value: false,
            gaVal: "analytics_ariel"
        },
        {
            label: "YouTube Ad",
            value: false,
            gaVal: "youtube_ad"
        },
        {
            label: "Facebook Group",
            value: false,
            gaVal: "facebook_group"
        },
        {
            label: "Bigger Pockets",
            value: false,
            gaVal: "bigger_pockets"
        },
        {
            label: "Google search",
            value: false,
            gaVal: "google_search"
        },
        {
            label: "TikTok",
            value: false,
            gaVal: "tiktok"
        },
        {
            label: "Instagram",
            value: false,
            gaVal: "instagram"
        },
		{
            label: "Cold email",
            value: false,
            gaVal: "cold_email"
        },
        {
            label: "Word-of-mouth",
            value: false,
            gaVal: "word_of_mouth"
        },
        {
            label: "Other",
            value: false,
            gaVal: "other"
        }
    ];
	// const [tooltip, setTooltip] = useState(false);
	const actionButtonRef = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const devMode = window.location.hostname === "localhost" || window.location.hostname.includes("refi-787d3") ? true : false;

	useEffect(() => {
		document.title = "Coffee Clozers | Sign Up";

		if ( emailParam !== null ) {
			dispatch(emailAction(emailParam));
		}

		// const getPhoneNumbers = async() => {
		// 	const colRef = "Phone Numbers";
		// 	const docRef = "All";

		// 	const queryData = await getDocument(colRef, docRef);
		// 	if ( queryData.status === 200 ) {
		// 		setPhoneNumberList(queryData.data.data);
		// 	}
		// };
		
		// getPhoneNumbers();
	}, [dispatch, emailParam]);

	const authenticateEmail = async() => {
		setLoading(true);
		setDisabled(true);
		// const unformattedPhoneNumber = `+1${phoneNumber.replace("(", "").replace(")", "").replace(" ", "").replace("-", "")}`;

		// if ( phoneNumberList.indexOf(unformattedPhoneNumber) !== -1 ) {
		// 	setErrorMessage("Error - it seems that phone number already in use 🤔");
		// 	setDisabled(false);
		// 	setLoading(false);
		// }
		// else {
		const signUpUser = await createUser(email, password);
		if ( signUpUser.status === 200 ) {
			complete(signUpUser, false);
		}
		else {
			if ( signUpUser.errorCode === "auth/weak-password" ) {
				setErrorMessage("Please use at least 6 characters for your password 🙃");
			}
			else if ( signUpUser.errorCode === "auth/invalid-email" ) {
				setErrorMessage("Please enter a valid email address 👍");
			}
			else if ( signUpUser.errorCode === "auth/email-already-in-use" ) {
				setErrorMessage("Email already in use 😔");
			}
			else if ( signUpUser.errorCode === "auth/network-request-failed" ) {
				setErrorMessage("Connection failed, please try again");
			}
			else {
				alert(signUpUser.message);
			}
			setDisabled(false);
			setLoading(false);
		}
		// }
	}

	const getNames = (words) => {
		const n = words.split(" ");
		const object = {
			firstName: n[0],
			lastName: n[n.length - 1]
		}
		return object;
	}

	const googleSignIn = async() => {
		const signUpUser = await signInWithGoogle();
		if ( signUpUser.status === 200 ) {
			setLoading(true);
			setDisabled(true);
			const displayName = signUpUser.user.displayName;
			const displayEmail = signUpUser.user.email;
			const names = getNames(displayName);
			signUpUser.user.firstName = names.firstName;
			signUpUser.user.lastName = names.lastName;
			signUpUser.user.displayName = displayName;
			signUpUser.user.email = displayEmail;
			
			const colRef = "Users";
			const userId = signUpUser.user.uid;
			const checkUserDocument = await getDocument(colRef, userId);
			if ( checkUserDocument.status !== 200 ) {
				complete(signUpUser, true);
			}
			else {
				navigate(route, {
					state: state
				});
			}
			// else if ( checkUserDocument.data.data.phoneNumberVerified === false ) {
			// 	navigate("/phone-verification", {
			// 		state: state
			// 	});
			// }
		}
		else {
			if ( signUpUser.code === "auth/weak-password" ) {
				setErrorMessage("Please use at least 6 characters for your password 🙃");
			}
			else if ( signUpUser.code === "auth/invalid-email" ) {
				setErrorMessage("Please enter a valid email address 👍");
			}
			else if ( signUpUser.code === "auth/email-already-in-use" ) {
				setErrorMessage("Email already in use 😔");
			}
			else if ( signUpUser.code === "auth/network-request-failed" ) {
				setErrorMessage("Connection failed, please try again");
			}
			else if ( signUpUser.code === "auth/popup-closed-by-user" || signUpUser.code === "auth/cancelled-popup-request") {
				console.log("User closed the popup");
			}
			else {
				alert(signUpUser.message);
			}
			setDisabled(false);
			setLoading(false);
		}
	};

	const complete = async(signUpUser, googleAuth) => {
		setErrorMessage("");
		const mode = window.location.hostname.includes("coffeeclozers") ? "live" : "test";
		const customer = {
			email: googleAuth === false ? email : signUpUser.user.email,
			name: googleAuth === false ? `${firstName} ${lastName}` : signUpUser.user.displayName,
			phone: "",
			mode: mode
		};
		const getCustomer = await getStripeCustomer(customer);
		// const unformattedPhoneNumber = phoneNumber.replace("(", "").replace(")", "").replace(" ", "").replace("-", "");
		const amendedOptions = [];
		const tagManagerArgs = {
            dataLayer: {
                event: "user_acquisition"
            }
        };
		for (let index = 0; index < selectedOptions.length; index++) {
            const element = selectedOptions[index];
			const fullOptionIndex = options.findIndex(e => e.label === element);
			if ( fullOptionIndex !== -1 ) {
				const option = options[fullOptionIndex];
				option.value = true;
				amendedOptions.push(option);
				tagManagerArgs.dataLayer[option.gaVal] = true;
			}
        };
        TagManager.dataLayer(tagManagerArgs);
		const startDate = new Date();
		const endDate = moment(startDate).add(7, "days");
		const val = [
			{
				startDate: new Date(),
				endDate: endDate._d
			}
		];

		const colRef = "Users";
		const docRef = signUpUser.user.uid;
		const data = {
			buyBoxAsk: true,
			credits: 5,
			email: googleAuth === false ? email : signUpUser.user.email,
			favourites: [],
			firstName: googleAuth === false ? firstName : signUpUser.user.firstName,
			freeTrial: val,
			lastName: googleAuth === false ? lastName : signUpUser.user.lastName,
			onboarding: true,
			payments: [],
			phoneNumber: "",
			phoneNumberPrefix: "+1",
			phoneNumberVerified: false,
			repeatUser: false,
			sidePanel: false,
			subscriptions: [],
			viewedProperties: []
		};

		if ( getCustomer.status === 200 ) {
			data.customerId = getCustomer.customerId;
		}

		if ( googleAuth === false ) {
			data.acquisitionChannels = amendedOptions;
		}

		if ( state !== undefined && state !== null && state.partnerId !== undefined ) {
			data.affiliate = state.partnerId;
		}
		else {
			data.affiliate = null;
		}

		const mailchimpObject = {
			list_id: "d7f5358698",
			get: false,
			values: {
				email_address: data.email,
				status: "subscribed",
				merge_fields: {
					FNAME: data.firstName,
					LNAME: data.lastName,
					PHONE: ""
				}

			}
		}
		await mailchimpAPI(mailchimpObject);

		const createDoc = await setData(colRef, docRef, data);
		if ( createDoc.status === 200 ) {
			recordEvent("Activate Free Trial", {});
			recordEvent("Account Created", {
				userId: docRef,
				email: googleAuth === false ? email : signUpUser.user.email,
			});
			const tagManagerArgs = {
				dataLayer: {
					event: "sign_up",
					userId: signUpUser.user.uid
				}
			};
			TagManager.dataLayer(tagManagerArgs);

			emailVerification();
			if ( route !== "" ) {
				navigate(route, {
					state: state
				});
			}
			else if ( state !== undefined ) {
				navigate(state.freeTrial, {
					state: state
				});
			}
			else {
				navigate('/profile');
			}

			if ( devMode === false ) {
				window.fbq('track', 'StartTrial');
			}
			setLoading(false);
			setDisabled(false);
		}
	};

	// const changePhoneNumber = (value) => {
	// 	// return nothing if no value
	// 	// if (!value) return value; 

	// 	// only allows 0-9 inputs

	// 	if ( value.length < phoneNumber.length ) {
    //         setPhoneNumber(value);
    //     }
    //     else {
	// 		if (!value) setPhoneNumber(value); 

	// 		const currentValue = value.replace(/[^\d]/g, '');
	// 		const cvLength = currentValue.length; 
		
	// 		if (!phoneNumber || value.length > phoneNumber.length) {
	// 			if (cvLength < 4) setPhoneNumber(currentValue);
			
	// 			if (cvLength < 7) setPhoneNumber(`(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`); 
			
	// 			setPhoneNumber(`(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`);
	// 		}
	// 	}
	// }

	const handleKeyDown = event => {
		if ( event.key === "Enter" ) {
			actionButtonRef.current.focus();
		}
	};

	const termsAndConditions = () => {
		navigate("/terms-conditions", {
			state: state
		});
	};

	const loginPage = () => {
		navigate(loginRoute, {
			state: state
		});
	};

	const changeOptions = (vals) => {
		const newArray = [...selectedOptions];
		const index = newArray.indexOf(vals.label);
		if ( index === -1 ) {
			newArray.push(vals.label);
		}
		else {
			newArray.splice(index, 1);
		}
		setSelectedOptions(newArray);
	};

	const optionsSelected = (val) => {
		const index = selectedOptions.indexOf(val);
		if ( index === -1 ) {
			return false;
		}
		else {
			return true;
		}
	};

	const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
		}
    };

	return (
		<div onKeyDown={handleKeyDown} className="sign-up-component-outer-container">
			<div className="sign-up-component-inner-container">
				{
					loading === true ?
					<Loading />
					:
					null
				}
				<div className="sign-up-component-row">
					<div className="sign-up-component-element-container sign-up-component-margin-right">
						<FormInput
							value={firstName}
							type="text"
							label="First name *"
							id="first-name-input"
							placeholder="First Name"
							onChange={(text) => dispatch(firstNameAction(text.target.value))}
						/>
					</div>
					<div className="sign-up-component-element-container sign-up-component-margin-left">
						<FormInput
							value={lastName}
							type="text"
							label="Last name *"
							id="last-name-input"
							placeholder="Last Name"
							onChange={(text) => dispatch(lastNameAction(text.target.value))}
						/>
					</div>
				</div>
				<div className="sign-up-component-long-element-container">
					<FormInput
						value={email}
						type="email"
						label="Email *"
						id="email-input"
						placeholder="youremail@email.com"
						onChange={(text) => dispatch(emailAction(text.target.value))}
					/>
				</div>
				<div className="sign-up-component-long-element-container">
					<FormInput
						value={password}
						type="password"
						label="Password *"
						id="password-input"
						placeholder="Password"
						onChange={(text) => dispatch(passwordAction(text.target.value))}
					/>
				</div>
				<div className="sign-up-component-long-element-container">
					<h3 className="body-semibold colour-primary margin-x-small">
						How did you hear about us?
					</h3>
					<Box>
						<FormControl fullWidth>
							<Select
								labelId="how-did-you-hear-select-label"
								id="how-did-you-hear-select"
								value={selectedOptions.length === 0 ? ["My gynecologist's brother in law, Steve"] : selectedOptions}
								label=""
								renderValue={(selected) => selected.join(", ")}
								input={<CustomSelect />}
								multiple
								open={selectOpen}
								onOpen={() => setSelectOpen(true)}
								onClose={() => setSelectOpen(false)}
								style={{ color: selectedOptions.length === 0 ? colour.textTertiary : colour.textPrimary }}
							> 
								{
									options.map((item, index) =>
										<MenuItem 
											key={index}
											value={item.label}
											style={styles.menuItem}
											onClick={() => changeOptions(item)}
										>
											<div className="sign-up-component-how-did-you-hear-row">
												<div className="checkbox-container">
													{
														optionsSelected(item.label) === true ?
														<div className="checkbox-icon">
														</div>
														:
														null
													}
												</div>
												<span className="body-regular colour-primary">
													{item.label}
												</span>
											</div>
										</MenuItem>	
									)
								}
							</Select>
						</FormControl>
					</Box>   
				</div>
				{/* <div className={"relative-container sign-up-component-long-element-container " + (errorMessage !== "" ? "no-margin" : "")}>
					<FormInput
						value={phoneNumber}
						type="tel"
						label="Phone Number *"
						id="phone-number-input"
						placeholder="(555) 555-5555"
						InputProps={{
							startAdornment: <InputAdornment position="start">+1</InputAdornment>,
						}}
						onChange={(text) => changePhoneNumber(text.target.value)}
					/>
					<span className="label-regular colour-secondary block-text margin-top-x-small">
						*We need this to verify your identity with a one-time code. 
						Standard message and data rates apply.
					</span>
					<img
						src={Info}
						className="sign-up-component-info-icon"
						alt="Tooltip"
						onMouseOver={() => setTooltip(true)}
						onMouseLeave={() => setTooltip(false)}
					/>
					{
						tooltip === true ?
						<DefaultTooltip
							title="Why do we need this?"
							description="Since we don't ask for your credit card to start your free trial, this is the only way we can limit free trials to one per person. We're not going to spam you with BS text messages, we promise 😊"
						/>
						:
						null
					}
				</div> */}
				{
					errorMessage !== "" ?
					<div className="sign-up-component-incorrect-password-container">
						<span className="body-regular colour-error">
							{errorMessage}
						</span>
					</div>
					:
					null
				}
				<span 
					className="body-regular colour-link block-text margin-x-small"
					onClick={() => termsAndConditions()}
				>
					By signing up, you agree to our <span className="underline">terms of service.</span>
				</span>
				<div className="sign-up-component-button-container">
					<ActionButton
						onClick={() => authenticateEmail()}
						disabled={firstName === "" ? true : lastName === "" ? true : email === "" ? true : password === "" ? true : disabled === true ? true : false}
						ref={actionButtonRef}
					>
						Signup
					</ActionButton>
				</div>
				<div className="sign-up-component-google-button-container">
					<WhiteActionButton
						onClick={() => googleSignIn()}
						disabled={disabled}
					>
						<img
							src={GoogleLogo}
							className="sign-up-component-google-logo"
							alt="Sign in with Google"
						/>
						Sign up with Google
					</WhiteActionButton>
				</div>
				<div className="sign-up-component-login-container">
					<span
						className="body-regular colour-secondary"
					>
						Already have an account? <span onClick={() => loginPage()} className="body-regular-underline colour-link">Login</span>
					</span>
				</div>
			</div>
		</div>
	)
}

export default SignUpComponent;