import React, { useState } from 'react';
import '../styles/PropertyListTopBar.css';
import { PinBlue } from '../assets';
import { Download, Filters } from '../assets';
import { BadgeExplanation, BuyBoxSelector, ExitScreen, FilterDropdown } from './';
import { recordEvent, resetFilterType } from '../functions';
import { colour, CustomSelect, neighbourhoodDataOptions } from '../styles/GlobalStyles';
import { Box, FormControl, MenuItem, Select } from '@mui/material';

function PropertyListTopBar(props) {
    const cityStateName = props.cityStateName;
    const setFiltersModalOpen = props.setFiltersModalOpen;
    const freeTrial = props.freeTrial;
    const downloadCSV = props.downloadCSV;
    const downloadCSVFreeTrial = props.downloadCSVFreeTrial;
    const setCardIndex = props.setCardIndex;
    const cityId = props.cityId;
    const temporaryId = props.temporaryId;
    const setSignUpModal = props.setSignUpModal;
    const searchParams = props.searchParams;
    const setSearchParams = props.setSearchParams;
    const setChangeMade = props.setChangeMade;
    const setPage = props.setPage;
    const setAddressSearchedProperty = props.setAddressSearchedProperty;
    const setGoogleMapProperty = props.setGoogleMapProperty;
    const propertyTypes = props.propertyTypes;
	const setPropertyTypes = props.setPropertyTypes;
	const units = props.units;
	const setUnits = props.setUnits;
	const budgetRange = props.budgetRange;
	const setBudgetRange = props.setBudgetRange;
	const bedrooms = props.bedrooms;
	const setBedrooms = props.setBedrooms;
	const bathrooms = props.bathrooms;
	const setBathrooms = props.setBathrooms;
	const squareFootage = props.squareFootage;
	const setSquareFootage = props.setSquareFootage;
	const constructionYears = props.constructionYears;
	const setConstructionYears = props.setConstructionYears;
	const onlyADU = props.onlyADU;
	const setOnlyADU = props.setOnlyADU;
	const onlyExtraBedroom = props.onlyExtraBedroom;
	const setOnlyExtraBedroom = props.setOnlyExtraBedroom;
	const onlySellerFinancing = props.onlySellerFinancing;
	const setOnlySellerFinancing = props.setOnlySellerFinancing;
	const priceReduction = props.priceReduction;
	const setPriceReduction = props.setPriceReduction;
	const priceReductionIndex = props.priceReductionIndex;
	const setPriceReductionIndex = props.setPriceReductionIndex;
	const hideFixerUppers = props.hideFixerUppers;
	const setHideFixerUppers = props.setHideFixerUppers;
	const onlyFixerUppers = props.onlyFixerUppers;
	const setOnlyFixerUppers = props.setOnlyFixerUppers;
    const setPropertiesOnPageChange = props.setPropertiesOnPageChange;
    const subscriptions = props.subscriptions;
    const buyBoxes = props.buyBoxes;
    const neighbourhoodOption = props.neighbourhoodOption;
    const setNeighbourhoodOption = props.setNeighbourhoodOption;
    const fixerUpperOption = props.fixerUpperOption;
    const setFixerUpperOption = props.setFixerUpperOption;
    const fsbo = props.fsbo;
    const setFsbo = props.setFsbo;
    const neighbourhoodGradeMin = props.neighbourhoodGradeMin;
    const neighbourhoodGradeMax = props.neighbourhoodGradeMax;
    const setNeighbourhoodGradeMin = props.setNeighbourhoodGradeMin;
    const setNeighbourhoodGradeMax = props.setNeighbourhoodGradeMax;

    const [openFilters, setOpenFilters] = useState(null);
    const [buyBoxSelectorContainer, setBuyBoxSelectorContainer] = useState(false);

    const filterTypes = [
        {
            label: "Property type"
        },
        {
            label: "Budget"
        },
        {
            label: "Property specifics"
        },
        {
            label: "Value-add"
        },
        {
            label: "More filters"
        }
    ];

    const cityButtonClick = () => {
        setBuyBoxSelectorContainer(true);
    };

    const openFiltersModal = () => {
		setFiltersModalOpen(true)
        recordEvent("Open Filters", {});
	};

    const clickOutside = (e) => {
        setCardIndex(null)
        setAddressSearchedProperty(null);
        setGoogleMapProperty(null);
    }

    const openFilterDropdown = (index, e) => {
        const target = e.target;

        if ( (target.tagName === "path" || target.tagName === "svg") ) {
            if ( openFilters === index ) {
                setOpenFilters(null);
            }
            else {
                setOpenFilters(index);
            }
            return;
        }
        else if ( target.className.includes("filter-dropdown-chip") && openFilters === index) {
            setOpenFilters(null);
            return;
        }

        if ( index !== openFilters ) {
            setOpenFilters(index);
        }
    };

    const resetAllFilters = async() => {
		searchParams.delete("propertyType");
		searchParams.delete("budgetRange");
		searchParams.delete("propertySpecifics");
		searchParams.delete("valueAdd");
		searchParams.delete("more");
		await resetFilterType(
			"all",
			setPropertyTypes, 
            setUnits,
            setBudgetRange,
            setBedrooms,
            setBathrooms,
            setSquareFootage,
            setConstructionYears,
            setOnlyADU,
            setOnlyExtraBedroom,
            setHideFixerUppers,
            setOnlyFixerUppers,
            setPriceReduction,
            setPriceReductionIndex,
            setOnlySellerFinancing,
            setFixerUpperOption,
            setFsbo,
            setNeighbourhoodGradeMin,
            setNeighbourhoodGradeMax
		);
		setSearchParams(searchParams);
        setChangeMade("filters");
        recordEvent("Reset Filters", {});
	};
    
    const closeEverything = () => {
        setOpenFilters(null);
        setBuyBoxSelectorContainer(false);
    };

    const onChangeOption = (value) => {
        setNeighbourhoodOption(value);
        recordEvent("Change Neighbourhood Option", {
            "Neighbourhood Option": neighbourhoodDataOptions[value].label
        });
    };

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
		}
	};

    return (
        <div className="property-list-top-bar-outer-container">
            {
                openFilters !== null || buyBoxSelectorContainer === true ?
                <ExitScreen
                    clickScreen={() => closeEverything()}
                />
                :
                null
            }
            <div 
                className="property-list-top-bar-inner-container"
                onClick={(e) => clickOutside(e)}
            >
                <div className="property-list-top-bar-left-container relative-container">
                    <div 
                        className="property-list-top-bar-city-button-container text-button"
                        onClick={() => cityButtonClick()}
                    >
                        <img
                            src={PinBlue}
                            className="pin-icon"
                            alt="Pin"
                        />
                        <span className={(buyBoxSelectorContainer === true ? "body-semibold" : "body-regular") + " colour-link"}>
                            {cityStateName}
                        </span>
                    </div>
                    {
                        buyBoxSelectorContainer === true ?
                        <BuyBoxSelector
                            buyBoxes={buyBoxes}
                            searchParams={searchParams}
                            cityId={cityId}
                            freeTrial={freeTrial}
                            subscriptions={subscriptions}
                        />
                        :
                        null
                    }
                    <div className="property-list-top-bar-csv-download-container icon-button">
                        <img
                            src={Download}
                            className="top-bar-download-icon"
                            alt="Download as CSV"
                            onClick={() => freeTrial === true ? downloadCSVFreeTrial() : downloadCSV()}
                        />
                        <BadgeExplanation
                            title="Download as CSV"
                            description="CSV download is only available on a premium plan"
                        />
                    </div>
                    <div 
                        className="property-list-top-bar-filters-button"
                        onClick={() => temporaryId === true ? setSignUpModal(true) : openFiltersModal()}
                    >
                        <img
                            src={Filters}
                            className="top-bar-filters-icon"
                            alt="Filters"
                        />
                        <span className="body-regular text-link">
                            Filters
                        </span>
                    </div>
                    <div className="property-list-top-bar-filter-chips-row">
                        {
                            filterTypes.map((item, index) =>
                                <FilterDropdown
                                    item={item}
                                    key={index}
                                    openFilters={openFilters}
                                    setOpenFilters={setOpenFilters}
                                    openFilterDropdown={openFilterDropdown}
                                    index={index}
                                    searchParams={searchParams}
                                    setSearchParams={setSearchParams}
                                    propertyTypes={propertyTypes}
                                    setPropertyTypes={setPropertyTypes}
                                    units={units}
                                    setUnits={setUnits}
                                    budgetRange={budgetRange}
                                    setBudgetRange={setBudgetRange}
                                    bedrooms={bedrooms}
                                    setBedrooms={setBedrooms}
                                    bathrooms={bathrooms}
                                    setBathrooms={setBathrooms}
                                    squareFootage={squareFootage}
                                    setSquareFootage={setSquareFootage}
                                    constructionYears={constructionYears}
                                    setConstructionYears={setConstructionYears}
                                    onlyADU={onlyADU}
                                    setOnlyADU={setOnlyADU}
                                    onlyExtraBedroom={onlyExtraBedroom}
                                    setOnlyExtraBedroom={setOnlyExtraBedroom}
                                    priceReduction={priceReduction}
                                    setPriceReduction={setPriceReduction}
                                    priceReductionIndex={priceReductionIndex}
                                    setPriceReductionIndex={setPriceReductionIndex}
                                    onlySellerFinancing={onlySellerFinancing}
                                    setOnlySellerFinancing={setOnlySellerFinancing}
                                    hideFixerUppers={hideFixerUppers}
                                    setHideFixerUppers={setHideFixerUppers}
                                    onlyFixerUppers={onlyFixerUppers}
                                    setOnlyFixerUppers={setOnlyFixerUppers}
                                    setChangeMade={setChangeMade}
                                    setPage={setPage}
                                    setPropertiesOnPageChange={setPropertiesOnPageChange}
                                    fixerUpperOption={fixerUpperOption}
                                    setFixerUpperOption={setFixerUpperOption}
                                    fsbo={fsbo}
                                    setFsbo={setFsbo}
                                    neighbourhoodGradeMin={neighbourhoodGradeMin}
                                    neighbourhoodGradeMax={neighbourhoodGradeMax}
                                    setNeighbourhoodGradeMin={setNeighbourhoodGradeMin}
                                    setNeighbourhoodGradeMax={setNeighbourhoodGradeMax}
                                />
                            )
                        }
                        {
                            searchParams.get("propertyType") !== null ||
                            searchParams.get("budgetRange") !== null ||
                            searchParams.get("propertySpecifics") !== null ||
                            searchParams.get("valueAdd") !== null ||
                            searchParams.get("more") !== null ?
                            <span 
                                className="body-regular-underline colour-link block-text subtext-margin-left-large"
                                onClick={() => resetAllFilters()}
                            >
                                Clear filters
                            </span>
                            :
                            null
                        }
                    </div>
                </div>
                <div className="property-list-top-bar-right-container">
                    <div className="map-types-row">
                        <Box>
                            <FormControl fullWidth>
                                <Select
                                    labelId="neighbourhood-option-select-label"
                                    id={`neighbourhood-option-select`}
                                    value={neighbourhoodOption}
                                    label=""
                                    onChange={(event) => onChangeOption(event.target.value)}
                                    input={<CustomSelect />}
                                >
                                    {
                                        neighbourhoodDataOptions.map((item, index) =>
                                            <MenuItem
                                                key={index}
                                                value={index}
                                                style={styles.menuItem}
                                                disabled={false}
                                            >
                                                {item.label}
                                            </MenuItem>	
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertyListTopBar;