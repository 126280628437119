import React from 'react';
import '../styles/OneCityLoading.css';
import LoadingStatic from "./LoadingStatic";

function OneCityLoading() {
    return (
        <div className="one-city-loading-outer-container margin-large">
            <div className="one-city-loading-inner-container">
                <div className="one-city-loading-animation-container margin-large">
                    <LoadingStatic />
                </div>
                <h1 className="heading-small-semibold colour-primary margin-x-small text-align-center">
                    Uploading data
                </h1>
                <span className="body-regular colour-secondary block-text margin-large text-align-center">
                    We’re pulling the data for this city – this could take up to an
                    hour. Don’t worry though, we’ll email you once it’s ready!
                </span>
            </div>
        </div>
    )
};

export default OneCityLoading;