import React from 'react';
import '../styles/NoBuyBoxCard.css';

function NoBuyBoxCard(props) {
    const func = props.func;
    const param = props.param;
    return (
        <div 
            className="no-buy-box-card-outer-container cursor-pointer"
            onClick={() => func(param)}
        >
            <div className="no-buy-box-card-inner-container">
                <div className="no-buy-box-card-plus-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path 
                            d="M11.197 23.8216C10.8996 23.8216 10.6468 23.7323 10.4387 23.5539C10.2602 23.3457 10.171 23.0929 10.171 22.7955V13.5167H1.02602C0.728625 13.5167 0.475836 13.4275 0.267658 13.2491C0.0892193 13.0409 0 12.7881 0 12.4907V11.0632C0 10.7658 0.0892193 10.5279 0.267658 10.3494C0.475836 10.1413 0.728625 10.0372 1.02602 10.0372H10.171V1.02602C10.171 0.728625 10.2602 0.490707 10.4387 0.312269C10.6468 0.104089 10.8996 0 11.197 0H12.7584C13.0558 0 13.2937 0.104089 13.4721 0.312269C13.6803 0.490707 13.7844 0.728625 13.7844 1.02602V10.0372H22.974C23.2714 10.0372 23.5093 10.1413 23.6877 10.3494C23.8959 10.5279 24 10.7658 24 11.0632V12.4907C24 12.7881 23.8959 13.0409 23.6877 13.2491C23.5093 13.4275 23.2714 13.5167 22.974 13.5167H13.7844V22.7955C13.7844 23.0929 13.6803 23.3457 13.4721 23.5539C13.2937 23.7323 13.0558 23.8216 12.7584 23.8216H11.197Z" 
                            fill="#2F4858"
                        />
                    </svg>
                </div>
                <div className="no-buy-box-card-text-container">
                    <h2 className="heading-small-semibold colour-link margin-x-small">
                        Create a buy box
                    </h2>
                    <span className="body-regular colour-tertiary">
                        Buy boxes are like a saved search on Zillow (but better) – 
                        they make your life easier, ensure you get notified whenever 
                        a new listing that meets your criteria pops up, and only take 
                        a minute to set up.
                    </span>
                </div>
            </div>
        </div>
    )
};

export default NoBuyBoxCard;