import React from 'react';
import '../styles/MonthlyCashFlowBadge.css';
import { formatterLong } from '../styles/GlobalStyles';

function MonthlyCashFlowBadge(props) {
    const item = props.item;
    const strategy = props.strategy;
    const cashFlow = strategy === 2 ? item.houseHackingFeatures.totalCashFlow : strategy === 3 && item.cashFlowBrrrrDefault !== undefined ? item.cashFlowBrrrrDefault : 0;
    const bgColour = strategy === 2 && item.houseHackingFeatures.ratioOfMortgageCovered >= 1 ? "bg-colour-green" : strategy === 2 && item.houseHackingFeatures.ratioOfMortgageCovered >= 0.7 ? "bg-colour-yellow" : strategy === 2 ? "bg-colour-red" : cashFlow >= 0 ? "bg-colour-green" : "bg-colour-red";

    return (
        <div className={"monthly-cash-flow-badge-outer-container " + (bgColour)}>
            <div className="monthly-cash-flow-badge-inner-container">
                <span className="label-semibold colour-primary block-text subtext-margin-right">
                    Mo. cash-flow
                </span>
                <span className="label-regular colour-primary">
                    {formatterLong.format(cashFlow).replace(".00", "")}
                </span>
            </div>
        </div>
    )
};

export default MonthlyCashFlowBadge;