import React, { useEffect, useState } from 'react';
import '../styles/EditBuyBox.css';
import { ChooseBudget, ChooseBuyBoxName, ChoosePropertySpecifics, ChoosePropertyType, ChooseValueAddOpportunity, Footer, Header, SideNavigation, Loading, DeleteModal, EditStrategy, EditNeighbourhoodGrade } from '../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeft } from '../assets';
import { ActionButton } from '../styles/GlobalStyles';
import { checkOnAuthStateChanged, getDocument, setData } from '../functions';

function EditBuyBox() {

    const location = useLocation();
    const state = location.state;
    const userData = state !== null ? state.userData : null;
    const buyBox = state !== null ? state.buyBox : null;
    const [title, setTitle] = useState(buyBox !== null ? buyBox.title : null)
    const [propertyTypes, setPropertyTypes] = useState(buyBox !== null ? buyBox.propertyTypes : null);
    const [units, setUnits] = useState(buyBox !== null ? buyBox.units : null);
    const [budgetRange, setBudgetRange] = useState(buyBox !== null ? buyBox.budgetRange : null);
    const [bedrooms, setBedrooms] = useState(buyBox !== null ? buyBox.bedrooms : null);
    const [bathrooms, setBathrooms] = useState(buyBox !== null ? buyBox.bathrooms : null);
    const [squareFootage, setSquareFootage] = useState(buyBox !== null ? buyBox.squareFootage : null);
    const [constructionYears, setConstructionYears] = useState(buyBox !== null ? buyBox.constructionYears : null);
    const [onlyADU, setOnlyADU] = useState(buyBox !== null ? buyBox.onlyADU : null);
    const [onlyExtraBedroom, setOnlyExtraBedroom] = useState(buyBox !== null ? buyBox.onlyExtraBedroom : null);
    const [noFixerUpper, setNoFixerUpper] = useState(buyBox !== null ? buyBox.noFixerUpper : null);
    const [frequency, setFrequency] = useState(buyBox !== null ? buyBox.frequency : null);
    const [strategy, setStrategy] = useState(buyBox !== null && buyBox.strategy !== undefined ? buyBox.strategy : "")
    const [neighbourhoodGradeMin, setNeighbourhoodGradeMin] = useState(buyBox !== null && buyBox.neighbourhoodGradeMin !== undefined ? buyBox.neighbourhoodGradeMin : 1);
    const [neighbourhoodGradeMax, setNeighbourhoodGradeMax] = useState(buyBox !== null && buyBox.neighbourhoodGradeMax !== undefined ? buyBox.neighbourhoodGradeMax : 8);
    const [buyBoxMetroCity, setBuyBoxMetroCity] = useState(buyBox !== null && buyBox.metroArea === true && buyBox.buyBoxMetroCity !== undefined ? buyBox.buyBoxMetroCity : "all");
    const [loading, setLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        if ( state === null || userData === null || buyBox === null )  {
            navigate("/my-cities");
        }
    }, [state, navigate, buyBox, userData]);

    const strategies = [
        {
            title: "Turnkey (Buy-and-hold)",
            description: "This is a long-term investment strategy of purchasing properties to hold and rent them for steady cash flow and potential appreciation.",
            value: "turnkey"
        },
        {
            title: "BRRRR (Buy, Rehab, Rent, Refinance, Repeat)",
            description: "BRRRR is a real estate strategy involving buying undervalued properties, renovating for added value, renting out for income, refinancing to recover capital, and repeating the cycle.",
            value: "brrrr"
        },
        {
            title: "Househacking",
            description: "Househacking means buying an investment property with the intention of living in one of the units or rooms (for at least one year) and renting out the others to cover expenses and generate income. This strategy allows the investor to make a much smaller down payment. We calculate this with a 5% down payment.",
            value: "househacking"
        },
        {
            title: "Fix & Flip",
            description: "Fix & flip is the short-term strategy of buying distressed properties, renovating them, and selling at a higher price to make a profit.",
            value: "fix-flip"
        },
        {
            title: "No strategy",
            description: "",
            value: ""
        }
    ];

    const goBack = () => {
        navigate("/my-cities", {
            state: state
        });
    };

    const deleteBuyBox = async() => {
        setDeleteModal(false);
        setLoading(true);
        const colRef = "Buy Boxes";
        const user = await checkOnAuthStateChanged();
        if ( user.status === 200 ) {
            const docRef = user.userId;
            const queryDocument = await getDocument(colRef, docRef);
            if ( queryDocument.status === 200 ) {
                const buyBoxes = queryDocument.data.data;
                const buyBoxCityIndex = buyBox.metroArea === true ? buyBoxes.findIndex(e => e.msaCode === buyBox.msaCode) : buyBoxes.findIndex(e => e.cityId === buyBox.cityId);
                if ( buyBoxCityIndex !== -1 ) {
                    const buyBoxIndex = buyBoxes[buyBoxCityIndex].buyBoxes.findIndex(e => e.id === buyBox.id);
                    if ( buyBoxIndex !== -1 ) {
                        buyBoxes[buyBoxCityIndex].buyBoxes.splice(buyBoxIndex, 1);
                        const updateDocument = await setData(colRef, docRef, buyBoxes);
                        if ( updateDocument.status === 200 ) {
                            navigate("/my-cities", {
                                state: state
                            });
                        }
                    }
                }
                else {
                    navigate("/my-cities");
                }
            }
            else {
                navigate("/my-cities");
            }
        }
        else {
            navigate("/sign-up");
        }
        setLoading(false);
    };

    const saveChanges = async() => {
        setLoading(true);
        const colRef = "Buy Boxes";
        const user = await checkOnAuthStateChanged();
        if ( user.status === 200 ) {
            const docRef = user.userId;
            const queryDocument = await getDocument(colRef, docRef);
            if ( queryDocument.status === 200 ) {
                const buyBoxes = queryDocument.data.data;
                const buyBoxCityIndex = buyBox.metroArea === true ? buyBoxes.findIndex(e => e.msaCode === buyBox.msaCode) : buyBoxes.findIndex(e => e.cityId === buyBox.cityId);
                if ( buyBoxCityIndex !== -1 ) {
                    const buyBoxIndex = buyBoxes[buyBoxCityIndex].buyBoxes.findIndex(e => e.id === buyBox.id);
                    if ( buyBoxIndex !== -1 ) {
                        buyBoxes[buyBoxCityIndex].buyBoxes[buyBoxIndex] = {
                            ...buyBoxes[buyBoxCityIndex].buyBoxes[buyBoxIndex],
                            title: title,
                            propertyTypes: propertyTypes,
                            units: units,
                            budgetRange: budgetRange,
                            bedrooms: bedrooms,
                            bathrooms: bathrooms,
                            squareFootage: squareFootage,
                            constructionYears: constructionYears,
                            onlyADU: onlyADU,
                            onlyExtraBedroom: onlyExtraBedroom,
                            noFixerUpper: noFixerUpper,
                            frequency: frequency,
                            strategy: strategy,
                            neighbourhoodGradeMin: neighbourhoodGradeMin,
                            neighbourhoodGradeMax: neighbourhoodGradeMax,
                        };

                        if ( buyBox.metroArea === true ) {
                            buyBoxes[buyBoxCityIndex].buyBoxes[buyBoxIndex].buyBoxMetroCity = buyBoxMetroCity;
                        }

                        delete buyBoxes[buyBoxCityIndex].buyBoxes[buyBoxIndex].lastCheck;
                        const updateDocument = await setData(colRef, docRef, buyBoxes);
                        if ( updateDocument.status === 200 ) {
                            navigate("/my-cities", {
                                state: state
                            });
                        }
                    }
                    else {
                        navigate("/my-cities");
                    }
                }
                else {
                    navigate("/my-cities");
                }
            }
        }
        else {
            navigate("/sign-up");
        }
        setLoading(false);
    };

    const onChangeTitle = (val) => {
        setTitle(val);
        setDisabled(false);
    };

    const onChangeMetroCity = (val) => {
        setBuyBoxMetroCity(val);
        setDisabled(false);
    };

    const onChangeFrequency = (val) => {
        setFrequency(val);
        setDisabled(false);
    };
        
    const onChangePropertyTypes = (val) => {
        setPropertyTypes(val);
        
        if ( val.length === 2 && val[0].included === false && val[1].included === false ) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    };

    const onChangeUnits = (val) => {
        setUnits(val);
        setDisabled(false);
    };

    const onChangeBudget = (val) => {
        setBudgetRange(val);
        setDisabled(false);
    };

    const onChangeBedrooms = (val) => {
        setBedrooms(val);
        setDisabled(false);
    };

    const onChangeBathrooms = (val) => {
        setBathrooms(val);
        setDisabled(false);
    };

    const onChangeSquareFootage = (val) => {
        setSquareFootage(val);
        setDisabled(false);
    };

    const onChangeConstructionYears = (val) => {
        setConstructionYears(val);
        setDisabled(false);
    };

    const onChangeOnlyADU = (val) => {
        setOnlyADU(val);
        setDisabled(false);
    };

    const onChangeOnlyExtraBedroom = (val) => {
        setOnlyExtraBedroom(val);
        setDisabled(false);
    };

    const onChangeNoFixerUpper = (val) => {
        setNoFixerUpper(val);
        setDisabled(false);
    };

    const onChangeStrategy = (val) => {
        setStrategy(val);
        setDisabled(false);
    };

    const changeNeighbourhoodGrade = (val, index) => {
        if ( index === 0 ) {
            setNeighbourhoodGradeMin(val);
        }
        else {
            setNeighbourhoodGradeMax(val);
        }
        setDisabled(false);
    };

    return (
        <div className="edit-buy-box-outer-container">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
                mobileNav={true}
            />
            {
                state === null ?
                null
                :
                <div className="edit-buy-box-outer-row-container relative-container">
                    <SideNavigation
                        navigate={navigate}
                        state={state}
                        userData={userData}
                    />
                    <div className="edit-buy-box-body-container">
                        {
                            loading === true ?
                            <Loading />
                            :
                            null
                        }
                        {
                            deleteModal === true ?
                            <DeleteModal
                                deleteFunction={deleteBuyBox}
                                setDeleteModal={setDeleteModal}
                                description="Are you sure you want to delete this buy box?"
                                deleteParam={null}
                            />
                            :
                            null
                        }
                        <div className="edit-buy-box-outer-title-container margin-x-small">
                            <div className="edit-buy-box-title-container">
                                <div 
                                    className="button-row text-button subtext-margin-right-x-large"
                                    onClick={() => goBack()}
                                >
                                    <img
                                        src={ChevronLeft}
                                        className="edit-buy-box-back-chevron"
                                        alt="Back"
                                    />
                                    <span className="body-regular text-link">
                                        Back
                                    </span>
                                </div>
                                <div className="edit-buy-box-title-text-container">
                                    <h1 className="heading-large-semibold colour-primary margin-x-small">
                                        Edit buy box
                                    </h1>
                                    <span className="body-regular colour-secondary">
                                        Change your buy box parameters.
                                    </span>
                                </div>
                            </div>
                            <div className="edit-buy-box-title-buttons-container">
                                <div 
                                    className="text-button subtext-margin-right-x-large"
                                    onClick={() => setDeleteModal(true)}
                                >
                                    <span className="body-regular colour-error">
                                        Delete this buy box
                                    </span>
                                </div>
                                <div className="edit-buy-box-save-container">
                                    <ActionButton
                                        disabled={loading === true ? true : disabled}
                                        onClick={() => saveChanges()}
                                    >
                                        Save changes
                                    </ActionButton>
                                </div>
                            </div>
                        </div>
                        <div className="edit-buy-box-divider margin-tops">
                        </div>
                        <div className="edit-buy-box-change-container margin-top-x-large">
                            <div className="edit-buy-box-inner-change-container">
                                <div className="edit-buy-box-section-container edit-buy-box-no-padding-top">
                                    <h2 className="heading-small-semibold colour-quaternary margin-medium">
                                        Buy box
                                    </h2>
                                    <ChooseBuyBoxName
                                        title={title}
                                        setTitle={onChangeTitle}
                                        buyBox={buyBox}
                                        frequency={frequency}
                                        setFrequency={onChangeFrequency}
                                        buyBoxCity={buyBox !== null && buyBox.metroArea === true ? state.buyBoxCity : null}
                                        buyBoxMetroCity={buyBoxMetroCity}
                                        onChangeMetroCity={onChangeMetroCity}
                                    />
                                </div>
                                <div className="edit-buy-box-major-divider">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="4" viewBox="0 0 512 4" fill="none">
                                        <path d="M2 2H510" stroke="#EEDFD2" strokeWidth="3" strokeLinecap="round"/>
                                    </svg>
                                </div>
                                <div className="edit-buy-box-section-container">
                                    <h2 className="heading-small-semibold colour-quaternary margin-medium">
                                        Property requirements
                                    </h2>
                                    <ChoosePropertyType
                                        propertyTypes={propertyTypes}
                                        setPropertyTypes={onChangePropertyTypes}
                                        units={units}
                                        setUnits={onChangeUnits}
                                    />
                                </div>
                                <div className="edit-buy-box-divider margin-tops">
                                </div>
                                <div className="edit-buy-box-section-container">
                                    <ChooseBudget
                                        budgetRange={budgetRange}
                                        setBudgetRange={onChangeBudget}
                                    />
                                </div>
                                <div className="edit-buy-box-divider margin-tops">
                                </div>
                                <div className="edit-buy-box-section-container">
                                    <ChoosePropertySpecifics
                                        bedrooms={bedrooms}
                                        setBedrooms={onChangeBedrooms}
                                        bathrooms={bathrooms}
                                        setBathrooms={onChangeBathrooms}
                                        squareFootage={squareFootage}
                                        setSquareFootage={onChangeSquareFootage}
                                        constructionYears={constructionYears}
                                        setConstructionYears={onChangeConstructionYears}
                                    />
                                </div>
                                <div className="edit-buy-box-divider margin-tops">
                                </div>
                                <div className="edit-buy-box-section-container">
                                    <ChooseValueAddOpportunity
                                        onlyADU={onlyADU}
                                        setOnlyADU={onChangeOnlyADU}
                                        onlyExtraBedroom={onlyExtraBedroom}
                                        setOnlyExtraBedroom={onChangeOnlyExtraBedroom}
                                        noFixerUpper={noFixerUpper}
                                        setNoFixerUpper={onChangeNoFixerUpper}
                                        filtersModal={false}
                                        fixerUpperError={false}
                                    />
                                </div>
                                <div className="edit-buy-box-divider margin-tops">
                                </div>
                                <div className="edit-buy-box-section-container">
                                    <EditStrategy
                                        strategies={strategies}
                                        strategy={strategy}
                                        onChangeStrategy={onChangeStrategy}
                                    />
                                </div>
                                <div className="edit-buy-box-divider margin-tops">
                                </div>
                                <div className="edit-buy-box-section-container">
                                    <EditNeighbourhoodGrade
                                        neighbourhoodGradeMin={neighbourhoodGradeMin}
                                        neighbourhoodGradeMax={neighbourhoodGradeMax}
                                        changeNeighbourhoodGrade={changeNeighbourhoodGrade}
                                        filtersModal={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
};

export default EditBuyBox;