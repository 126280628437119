import React, { useState, useEffect } from 'react';
import '../styles/PropertySearchLandingPage.css';
import { Loading, PlaceInput } from '../components';
import { containsNumbers, checkOnAuthStateChanged, getDocument, setData, getIp, getWhereDoc, writeSearch, makeId, recordEvent, getUserDocuments } from '../functions';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { LandingImage } from '../assets/images';

function QuickReport(props) {
    const [searchParams] = useSearchParams({});
    const getAddress = searchParams.get("address");
    const [searchLocation, setSearchLocation] = useState(getAddress === null ? "" : getAddress);
    const [error, setError] = useState("");
    const [savedUserId, setSavedUserId] = useState("");
    const [credits, setCredits] = useState(0);
    const [maxCredits, setMaxCredits] = useState(null);
    const [ipError, setIpError] = useState(false);
    const [googlePlacesDisabled, setGooglePlacesDisabled] = useState(false);
    const [ipUser, setIpUser] = useState(null);
    const [noUserId, setNoUserId] = useState("");
    const subscriptions = props.subscriptions;
    const userData = props.userData;
    const [queriedUser, setQueriedUser] = useState(false);
    // const percentageFilled = maxCredits === null ? 100 : (credits / maxCredits) * 100 > 100 ? 100 : (credits / maxCredits) * 100;
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    useEffect(() => {
        document.title = "Quick Report";

        const fetchUserData = async() => {
			const getUserId = await checkOnAuthStateChanged();
			if ( getUserId.status === 200 ) {
                const userId = getUserId.userId;
                setSavedUserId(userId);
                const collections = [
                    "Property Searches",
                    "Financial Preferences",
				];

				const queryData = await getUserDocuments(collections, userId);
                if ( subscriptions.length > 0) {
                    const propertySearchData = queryData[0].status === 200 ? queryData[0].data : {
                        searches: [],
                        credits: 5
                    };

                    const today = new Date();
                    const todaySeconds = today.getTime() / 1000;
                    let paidPlan = false;
                    for (let index = 0; index < subscriptions.length; index++) {
                        const element = subscriptions[index];
                        const endDate = element.endDate.seconds;
                        if ( endDate > todaySeconds ) {
                            paidPlan = true;
                        }
                    };

                    if ( paidPlan === false && propertySearchData.credits > 10 ) {
                        const remainingCredits = 5 - propertySearchData.searches.length;

                        setCredits(remainingCredits < 0 ? 0 : remainingCredits);
                        const newObject = {
                            credits: remainingCredits,
                            searches: propertySearchData.searches
                        };
                        await setData("Property Searches", userId, newObject);
                    }
                    else if ( paidPlan === false && propertySearchData.credits > 5 ) {
                        setMaxCredits(10);
                        setCredits(propertySearchData.credits);
                    }
                    else if ( paidPlan === true ) {
                        setMaxCredits(50);
                        setCredits(propertySearchData.credits);
                    }
                }
                else {
                    const propertySearchData = queryData[0].status === 200 ? queryData[0].data : {
                        searches: [],
                        credits: 5
                    };
                    if ( propertySearchData.credits > 10 ) {
                        setCredits(5);
                        const newObject = {
                            credits: 5,
                            searches: propertySearchData.searches
                        };
                        await setData("Property Searches", userId, newObject);
                    }
                    else if ( propertySearchData.credits > 5 ) {
                        setMaxCredits(10);
                        setCredits(propertySearchData.credits);
                    }
                    else {
                        setMaxCredits(5);
                        setCredits(propertySearchData.credits);
                    }
                }

                setQueriedUser(true);

                setIpError(false);
                setIpUser(false);
                setQueriedUser(true);
            }
            else {
                setQueriedUser(true);
                setMaxCredits(3);
                const getIpAddress = await getIp();
                if ( getIpAddress.error === true ) {
                    setIpError(true);
                    setGooglePlacesDisabled(true);
                    setSearchLocation("");
                }
                else {
                    setIpError(false);
                    const ipAddress = getIpAddress.ip;
                    const userCity = getIpAddress.city;
                    const userRegion = getIpAddress.region;
                    const userCountry = getIpAddress.country;
                    const userLocation = {
                        city: userCity,
                        region: userRegion,
                        country: userCountry
                    };

                    const col = "IP Addresses"
                    const field = "data.ipAddress";
                    const operator = "==";
                    const val = ipAddress;
                    const getUserDocument = await getWhereDoc(col, field, operator, val);
                    if ( getUserDocument.status === 200 ) {
                        const ipUserData = getUserDocument.data[0];
                        if ( ipUserData.searches === undefined ) {
                            ipUserData.searches = ipUserData.propertySearches;
                            delete ipUserData.propertySearches;
                        }

                        setCredits(ipUserData.credits);
                        setIpUser(true);
                        setNoUserId(ipUserData.id);
                    }
                    else {
                        defaultIPCredits();
                        writeIPAddressData(col, ipAddress, userLocation);
                        setIpUser(true);
                    }
                }
            }
        };

        const writeIPAddressData = async(col, ipAddress, userLocation) => {
            const randomId = await makeId(15);
            const defaultData = {
                ipAddress: ipAddress,
                userLocation: userLocation,
                credits: 3,
                searches: [],
                id: randomId
            };
            const queryRandomId = await getDocument(col, randomId);
            if ( queryRandomId.status !== 200 ) {
                await setData(col, randomId, defaultData);
                defaultIPCredits();
                setIpUser(true);
                setNoUserId(randomId);
            }
            else {
                writeIPAddressData(col, ipAddress, userLocation);   
            }
        }

        fetchUserData();
    }, [subscriptions]);

    const changeLocation = (text) => {
        setSearchLocation(text);
    };

    const selectedLocation = async(place) => {
        if ( place !== null ) {
            const addressElements = place.value.terms;
            const firstElement = addressElements.length !== 0 ? addressElements[0].value : "";
            const address = place.label;
            const firstWord = firstElement.replace(/ .*/,'');
            const checkNumber = await containsNumbers(firstWord);
            if ( checkNumber === true ) {
                setError("");
                const encodedAddress = encodeURIComponent(address);
                const save = await saveNewSearch(place, encodedAddress);
                if ( save.status === 200 ) {
                    recordEvent("Property Searched", {
                        "Address": address
                    })

                    navigate(`/quick-report/${encodedAddress}`, {
                        state: {
                            redirect: true,
                            preSaved: save.preSaved,
                            ipUser: ipUser,
                            noUserId: noUserId,
                            creditRemoved: save.creditRemoved
                        }
                    });
                }
                else {
                    setError("There was an error saving your search");
                }
            }
            else {
                setError("It doesn't look like you entered an address.. 🤔");
            }
            setSearchLocation("");
        }
    };

    const saveNewSearch = async(place, encodedAddress) => {
        if ( savedUserId !== "" ) {
            const colRef = "Property Searches";
            const writeDetails = await writeSearch(colRef, savedUserId, place, encodedAddress, credits);
            return writeDetails;
        }
        else if ( noUserId !== "" )  {
            const colRef = "IP Addresses";
            const writeDetails = await writeSearch(colRef, noUserId, place, encodedAddress, credits);
            return writeDetails;
        }
    };

    const defaultIPCredits = () => {
        setCredits(3);
    };

    const createAccount = () => {
        navigate("/sign-up");
        recordEvent("Property Search - Signup Button Clicked", {});
    };

    const buyCredits = () => {
        if ( userData === null ) {
            createAccount();
        }
        else {
            navigate("/buy-credits", {
                state: state
            });
        }
    };

    // const styles = {
    //     progress: {
    //         width: `${percentageFilled}%`
    //     }
    // };
    
    return (
        <div className="property-search-outer-container">
            <div className="property-search-inner-container">
                <div className="property-search-gradient-container">
                    <div className="property-search-landing-component-container">
                        <div className="property-search-landing-component-image-container">
                            <img
                                src={LandingImage}
                                className="property-search-landing-image"
                                alt="Where to invest"
                            />
                        </div>
                        <div className="property-search-landing-component-description-container">
                            <h1 className="display-xl-semibold colour-primary text-align-left margin-medium">
                                Get a quick report on any property
                            </h1>
                            <span className="body-regular colour-secondary block-text text-align-left">
                                Instantly see rent estimate, comps, cash-on-cash return, neighborhood heatmap, ARV estimate, 
                                and recommended investment strategy– includes off-market properties!
                            </span>
                            <div className="property-search-input-container">
                                <PlaceInput
                                    placeholder="Search any residential address"
                                    localLocation={searchLocation}
                                    changeLocation={changeLocation}
                                    selectedLocation={selectedLocation}
                                    types={['address']}
                                    disabled={credits === 0 || queriedUser === false ? true : googlePlacesDisabled}
                                />
                                {
                                    ipError === true ?
                                    <span className="body-regular colour-error block-text margin-top-small">
                                        We couldn't verify your IP address to figure out 
                                        how many credits you have left..<span onClick={() => createAccount()} className="underline cursor-pointer">create a free account here</span>
                                        {' '}to get some free credits
                                    </span>
                                    :
                                    error === "" ?
                                    null
                                    :
                                    <span className="body-regular colour-error block-text margin-top-small">
                                        {error}
                                    </span>
                                }
                            </div>
                            {
                                queriedUser === false ?
                                <div className="property-search-landing-page-loading-container">
                                    <Loading />
                                </div>
                                :
                                credits > 100 ?
                                <div className="property-search-credits-container">
                                    <span className="body-regular colour-primary block-text">
                                        Unlimited credits 🎉
                                    </span>
                                </div>
                                :
                                <div className="property-search-credits-container">
                                    <span className={"body-regular block-text text-align-center subtext-margin-right " + (credits > 0 ? "colour-primary" : "colour-error")}>
                                        <span className="body-semibold">{credits}</span> of {maxCredits === null ? ".." : maxCredits} Free reports remaining
                                    </span>
                                    {
                                        queriedUser === false ?
                                        null
                                        :
                                        userData === null ?
                                        <div 
                                            className="text-button"
                                            onClick={() => createAccount()}
                                        >
                                            <span className="body-regular text-link">
                                                Signup for 5 FREE credits
                                            </span>
                                        </div>
                                        :
                                        <div 
                                            className="text-button"
                                            onClick={() => buyCredits()}
                                        >
                                            <span className="body-regular text-link">
                                                Get unlimited credits
                                            </span>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuickReport;