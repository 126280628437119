import React, { useEffect, useState } from 'react';
import '../styles/ChooseCounties.css';
import '../styles/UpgradeCity.css';
import '../styles/PickPlan.css';
import { Header, Footer, PickPlanCounty, Loading } from '../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { ActionButton } from '../styles/GlobalStyles';
import { checkUserId, getUserDocuments, saveUserInformation, updateWaitingRoom, writeBuyBoxes, setData, checkOnAuthStateChanged, unassignedCountyWrite, sendEmail } from '../functions';

function ChooseCounties() {
    const location = useLocation();
    const state = location.state;
    const [counties, setCounties] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [pickCountyIndex, setPickCountyIndex] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Choose Counties | Coffee Clozers";

        const checkState = async() => {
            const user = await checkOnAuthStateChanged();
            if ( user.status !== 200 ) {
                navigate("/my-cities");
            }
            else if ( state === null || state.counties === undefined ) {
                navigate("/my-cities");
            }
            else if ( counties.length === 0 ) {
                const countiesBought = state.counties;
                const newArray = [];
                for (let index = 0; index < countiesBought; index++) {
                    const newObject = {
                        msaTitle: "",
                        msaCode: ""
                    };
                    newArray.push(newObject);
                };
                setCounties(newArray);
            };            
        };
        checkState();

    }, [navigate, counties.length, state]);

    const checkDisabled = () => {
        let check = true;
        for (let index = 0; index < counties.length; index++) {
            const element = counties[index];
            if ( element.msaCode !== "" ) {
                check = false;
                break;
            }
        };
        return check;
    };

    const chooseCounty = (index) => {
        setPickCountyIndex(index);
    };

    const confirmCounty = async(item) => {
        const newObject = {
            msaTitle: `${item.countyName}, ${item.state}`,
            msaCode: item.countyId,
            msaCityIds: item.msaCityIds,
            metroCities: item.metroCities,
            metroArea: item.metroArea
        };
        const newArray = [...counties];
        newArray[pickCountyIndex] = newObject;
        setCounties(newArray);
        setPickCountyIndex(null);
    };

    const complete = async() => {
        setDisabled(true);
        setLoading(true);

        const selectedCounties = [];
        for (let index = 0; index < counties.length; index++) {
            const element = counties[index];
            if ( element.msaCode === "" ) {
                continue;
            }
            else {
                selectedCounties.push(element);
            }
        };

        const user = await checkUserId();
        if ( user.status === 200 ) {
            // Add user to waiting room
            const collections = ["Users", "Subscriptions"];
            const userId = user.userId;
            const queryData = await getUserDocuments(collections, userId);
            if ( queryData[0].status === 200 ) {
                const userDetails = queryData[0].data;
                const email = userDetails.email;
                const currentSubscriptions = queryData[1].status === 200 ? queryData[1].data : [];
                const emptySubscriptionCounties = [];

                for (let index = 0; index < selectedCounties.length; index++) {
                    const element = selectedCounties[index];
                    const emptySubscription = currentSubscriptions.findIndex(e => e.msaCode === "");
                    if ( emptySubscription !== -1 ) {
                        currentSubscriptions[emptySubscription].msaCode = element.msaCode;
                        currentSubscriptions[emptySubscription].msaCityIds = element.msaCityIds;
                        currentSubscriptions[emptySubscription].metroCities = element.metroCities;
                        currentSubscriptions[emptySubscription].msaTitle = element.msaTitle;

                        selectedCounties[index].endDate = currentSubscriptions[emptySubscription].endDate;
                        emptySubscriptionCounties.push(selectedCounties[index]);
                    }
                }

                if ( emptySubscriptionCounties.length > 0 ) {
                    await setData("Subscriptions", userId, currentSubscriptions);
                    const waitingRoomParams = {
                        userId: userId,
                        email: email,
                        selectedCounties: emptySubscriptionCounties,
                        paid: true
                    };
                    await updateWaitingRoom(waitingRoomParams);
                    await writeBuyBoxes(userId, emptySubscriptionCounties);
                    await saveUserInformation(userId, emptySubscriptionCounties, true);
                }

                if ( emptySubscriptionCounties.length === counties.length ) {
                    await unassignedCountyWrite(userId, null);
                }

                const emailCounties = [];
                for (let index = 0; index < selectedCounties.length; index++) {
                    const element = selectedCounties[index];
                    const newObject = {
                        msaCode: element.msaCode,
                        county: element.msaTitle
                    };
                    emailCounties.push(newObject);
                }

                const contactEmail = "info@coffeeclozers.com";
                const dynamicTemplate = "d-22834a5e440a44249de8e7511b910370";
                const emailData = {
                    to: ["ariel.herrera@coffeeclozers.com", "liam.maher@coffeeclozers.com"],
                    from: contactEmail,
                    templateId: dynamicTemplate,
                    dynamic_template_data: {
                        creative: false,
                        email: email,
                        userId: userId,
                        counties: emailCounties
                    }
                };
                await sendEmail(emailData);
            }
            else {
                navigate("/my-cities");
            }
        }
        else {
            navigate("/my-cities");
        }

        navigate("/my-cities");
        setDisabled(false);
        setLoading(false);
    };

    return (
        <div className="choose-counties-outer-container bg-colour-light">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={true}
            />
            {
                pickCountyIndex !== null ?
                <div className="choose-counties-pick-county-container margin-x-x-large">
                    <div className="choose-counties-title-container margin-x-x-large">
                        <h1 className="heading-large-semibold colour-primary text-align-center margin-medium">
                            County selection
                        </h1>
                        <h2 className="body-regular colour-secondary block-text text-align-center">
                            Search for a county to select your investment market.
                        </h2>
                    </div>
                    <PickPlanCounty
                        disabled={disabled}
                        setDisabled={setDisabled}
                        goToCheckout={null}
                        setSelectedCity={null}
                        confirmCounty={confirmCounty}
                    />
                </div>
                :
                <div className="choose-counties-inner-container margin-x-large relative-container">
                    {
                        loading === true ?
                        <Loading />
                        :
                        null
                    }
                    <div className="choose-counties-title-container margin-x-x-large">
                        <h1 className="heading-large-semibold colour-primary text-align-center margin-medium">
                            Select your counties
                        </h1>
                        <h2 className="body-regular colour-secondary block-text text-align-center">
                            Select at least 1 to continue.
                        </h2>
                    </div>
                    <div className="choose-counties-body-container margin-x-x-large">
                        {
                            counties.map((item, index) =>
                                <div 
                                    className={" " + (item.msaTitle === "" ? "choose-counties-element-container cursor-pointer " : "choose-counties-selected-element-container ") + (index === counties.length - 1 ? "" : "margin-x-large")}
                                    key={index}
                                    onClick={() => item.msaTitle === "" ? chooseCounty(index) : null}
                                >
                                    <div className="choose-counties-inner-element-container">
                                        <div className="choose-counties-left-container">
                                            <h3 className="body-semibold text-link margin-x-x-small">
                                                {
                                                    item.msaTitle !== "" ?
                                                    item.msaTitle
                                                    :
                                                    index === 0 ?
                                                    "Select your first county"
                                                    :
                                                    "Select county"
                                                }
                                            </h3>
                                            {
                                                item.msaTitle !== "" ?
                                                null
                                                :
                                                <span className="body-regular colour-secondary text-italic">
                                                    {
                                                        index === 0 ?
                                                        "Required"
                                                        :
                                                        "Or choose later if you prefer"
                                                    }
                                                </span>
                                            }
                                        </div>
                                        <div className="choose-counties-right-container">
                                            {
                                                item.msaTitle === "" ?
                                                <svg 
                                                    xmlns="http://www.w3.org/2000/svg" 
                                                    width="15" 
                                                    height="16" 
                                                    viewBox="0 0 15 16" 
                                                    fill="none"
                                                >
                                                    <path 
                                                        d="M14.6521 8.71155L8.24807 15.1155C7.85509 15.5085 7.21794 15.5085 6.82496 15.1155C6.43198 14.7226 6.43198 14.0854 6.82496 13.6924L11.5111 9.00629L1.06 9.00629C0.504242 9.00629 0.0537109 8.55576 0.0537109 8C0.0537109 7.44424 0.504242 6.99371 1.06 6.99371L11.5111 6.99371L6.82496 2.30757C6.43198 1.91458 6.43198 1.27744 6.82496 0.884457C7.21794 0.491476 7.85509 0.491477 8.24807 0.884457L14.6521 7.28844C15.045 7.68142 15.045 8.31857 14.6521 8.71155Z" 
                                                        fill="#2F4858"
                                                    />
                                                </svg>
                                                :
                                                <span 
                                                    className="body-regular-underline colour-link block-text text-align-right cursor-pointer"
                                                    onClick={() => chooseCounty(index)}
                                                >
                                                    Change
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className={"choose-counties-button-container margin-x-small " + (checkDisabled() === true ? "choose-counties-long-button" : "choose-counties-short-button")}>
                        <ActionButton
                            disabled={checkDisabled() === false ? disabled : true}
                            onClick={() => complete()}
                        >
                            {
                                checkDisabled() === true ?
                                "Make a selection to continue"
                                :
                                "Confirm selection"
                            }
                        </ActionButton>
                    </div>
                    {
                        checkDisabled() === false ?
                        <div className="choose-counties-next-step-container">
                            <span className="body-regular colour-secondary block-text text-align-center">
                                We’ll start gathering your data, which will be available within 1 hour or less. 
                                Please note, once you choose a county, it cannot be changed until 
                                the next billing cycle.
                            </span>
                        </div>
                        :
                        null
                    }
                </div>
            }
            <Footer />
        </div>
    );
};

export default ChooseCounties;