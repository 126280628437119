import React, { useState } from 'react';
import '../styles/OwnerDetails.css';
import { OffMarketBadge, UpgradeModal } from '../components';
import { RedirectArrowBlue } from '../assets';
import { recordEvent } from '../functions';
import { colour, formatterLong, percentage } from '../styles/GlobalStyles';

function OwnerDetails(props) {
    const property = props.property;
    const listingSubType = property.listingSubType;
    const newConstruction = listingSubType.newConstruction !== undefined ? listingSubType.newConstruction : false;
    const skipTraceWebsite = property.ownerAttributes.ownerType === "Company" ? "OpenCorporates" : "FastPeopleSearch";
    const motivationLevel = newConstruction === true ? "New construction" : property.ownerAttributes.motivationLabel === "low" ? "Likely Low" : property.ownerAttributes.motivationLabel === "medium" ? "Likely Medium" : "Likely High";
    const badgeBG = newConstruction === true ? "bg-colour-dark" : property.ownerAttributes.motivationLabel === "low" ? "bg-colour-red" : property.ownerAttributes.motivationLabel === "medium" ? "bg-colour-yellow" : "bg-colour-green";
    const [viewUpgradeModal, setViewUpgradeModal] = useState(false);

    const ownershipArray = [
        {
            label: "Estimated equity",
            value: percentage.format(property.ownerAttributes.estEquityRatio)
        },
        {
            label: "Est. mortgage balance",
            value: formatterLong.format(Number(property.ownerAttributes.estMortgageBalance)).replace(".00", "")
        },
        {
            label: "Est. mortgage payment",
            value: formatterLong.format(Number(property.ownerAttributes.estMortgagePayment)).replace(".00", "")
        }
    ];

    const ownerInformation = [
        {
            label: "Name",
            value: property.ownerAttributes.ownerName
        },
        {
            label: "Owner type",
            value: property.ownerAttributes.ownerType
        },
        {
            label: "Owned for",
            value: property.ownerAttributes.ownershipLength === null ? "N/A" : Number(property.ownerAttributes.ownershipLength) < 12 ? `${property.ownerAttributes.ownershipLength} month${Number(property.ownerAttributes.ownershipLength) % 12 === 1 ? "" : "s"}` : `${Math.floor(Number(property.ownerAttributes.ownershipLength) / 12)} years${Number(property.ownerAttributes.ownershipLength) % 12 === 0 ? "" : ` ${Number(property.ownerAttributes.ownershipLength) % 12} month${Number(property.ownerAttributes.ownershipLength) % 12 === 1 ? "" : "s"}`}`
        }
    ];

    const purchaseInformation = [
        {
            label: "Purchase method",
            value: property.ownerAttributes.purchaseMethod === "Cash Purchase" ? "Cash" : "Financed"
        },
        {
            label: "Investment property?",
            value: property.ownerAttributes.investorBuyer === false ? "No" : "Yes"
        },
        {
            label: "Multiple parcels?",
            value: property.ownerAttributes.multipleParcels === false ? "No" : "Yes"
        }
    ];

    const distressSignalsLeft = [
        {
            value: property.ownerAttributes.absenteeOwner === true ? true : false,
            icon: "🫥",
            label: "Absentee owner"
        },
        {
            value: property.ownerAttributes.auction === true ? true : false,
            icon: "🪧",
            label: "Auction"
        },
        {
            value: property.ownerAttributes.freeClear === true ? true : false,
            icon: "🧾",
            label: "Free & Clear"
        },
        {
            value: property.ownerAttributes.highEquity === true ? true : false,
            icon: "🐷🏦",
            label: "High Equity"
        },
        {
            value: property.ownerAttributes.inStateAbsenteeOwner === true ? true : false,
            icon: "😶‍🌫️",
            label: "In-State Absentee owner"
        },
        {
            value: property.ownerAttributes.inherited_flag === true ? true : false,
            icon: "🫴",
            label: "Inherited"
        },
        {
            value: property.ownerAttributes.lien === true ? true : false,
            icon: "🤝",
            label: "Lien"
        }
    ];

    const distressSignalsRight = [
        {
            value: property.ownerAttributes.multipleMortgages === true ? true : false,
            icon: "💰",
            label: "Multiple Mortgages"
        },
        {
            value: property.ownerAttributes.ownerOccupied === true ? true : false,
            icon: "🧓🏠",
            label: "Owner Occupied"
        },
        {
            value: property.ownerAttributes.preForeclosure === true ? true : false,
            icon: "📉",
            label: "Pre-Foreclosure"
        },
        {
            value: property.ownerAttributes.death === true ? true : false,
            icon: "🕊️",
            label: "Recent death"
        },
        {
            value: property.ownerAttributes.spousalDeath === true ? true : false,
            icon: "💍🕊️",
            label: "Spousal Death"
        },
        {
            value: property.ownerAttributes.taxLien === true ? true : false,
            icon: "🗂️🤝",
            label: "Tax Lien"
        },
        {
            value: property.ownerAttributes.vacant === true ? true : false,
            icon: "🏚️",
            label: "Vacant"
        }
    ];

    const openFastPeopleSearch = () => {
        if ( property.ownerAttributes.ownerType === "Company" ) {
            // corporate owner https://opencorporates.com
            recordEvent("Viewed Open Corporates", {});
            const companyName = property.ownerAttributes.ownerName.replace(/ /g, "+");
            const corporateLink = `https://opencorporates.com/companies?utf8=✓&utf8=✓&q=${companyName}&jurisdiction_code=&type=companies`;
            window.open(corporateLink, "_blank");
        }
        else {
            recordEvent("Viewed FastPeopleSearch", {});
            const street = property.address.streetAddress.toLowerCase().replace(/ /g, "-");
            const city = property.address.city.toLowerCase().replace(/ /g, "-");
            const state = property.address.state.toLowerCase();
            window.open(`https://www.fastpeoplesearch.com/address/${street}_${city}-${state}`, "_blank");
        }
    };

    return (
        <div className="owner-details-outer-container">
            {
                viewUpgradeModal === true ?
                <UpgradeModal
                    setViewUpgradeModal={setViewUpgradeModal}
                    freeTrial={true}
                    subscriptions={[]}
                />
                :
                null
            }
            <div className="owner-details-inner-container">
                <div className="owner-details-title-container margin-x-small">
                    <h2 className="heading-small-semibold colour-primary margin-x-small">
                        Seller motivation
                    </h2>
                    <p className="body-regular colour-secondary">
                        Understanding the owner’s motivation to sell can help determine suitability for creative finance, 
                        low ball offers, etc.
                    </p>
                </div>
                <div className={"owner-details-badge-container margin-medium " + (badgeBG)}>
                    <span className="body-semibold colour-white block-text text-align-center">
                        {motivationLevel}
                    </span>
                </div>
                {
                    newConstruction !== true ?
                    <div className="owner-details-body-row margin-x-x-large">
                        {
                            ownershipArray.map((item, index) =>
                                <div 
                                    className="owner-details-equity-element"
                                    key={index}
                                >
                                    <span className="label-regular colour-primary block-text margin-x-x-small">
                                        {item.label}
                                    </span>
                                    <span className="heading-small-semibold colour-primary">
                                        {item.value}
                                    </span>
                                </div>
                            )
                        }
                    </div>
                    :
                    <div className="owner-details-no-prior-owner margin-x-x-large">
                        <span className="body-regular colour-primary">
                            No previous owner information.
                        </span>
                    </div>
                }
                {
                    newConstruction !== true ?
                    <div className="owner-details-body-container margin-x-x-large">
                        <span className="body-semibold colour-primary margin-large block-text">
                            About the owner
                        </span>
                        <div className="owner-details-body-row margin-large">
                            {
                                ownerInformation.map((item, index) =>
                                    <div 
                                        className="owner-details-body-row-element"
                                        key={index}
                                    >
                                        <span className="label-regular colour-primary margin-x-x-small block-text">
                                            {item.label}
                                        </span>
                                        <h3 className="body-semibold colour-primary">
                                            {item.value}
                                        </h3>
                                    </div>
                                )
                            }
                        </div>
                        <div className="margin-large">
                            <span className="label-regular colour-primary margin-x-x-small block-text">
                                Mailing address
                            </span>
                            <h3 className="body-semibold colour-primary">
                                {property.ownerAttributes.mailingAddress}
                            </h3>
                        </div>
                        <div className="">
                            <div 
                                className="owner-details-fast-people-search-container"
                                onClick={() => openFastPeopleSearch()}
                            >
                                <span className="body-regular-underline colour-link subtext-margin-right">
                                    View more on {skipTraceWebsite}
                                </span>
                                <img
                                    src={RedirectArrowBlue}
                                    className="owner-details-redirect-arrow-blue"
                                    alt={skipTraceWebsite}
                                />
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                {
                    newConstruction !== true ?
                    <div className="owner-details-body-container margin-x-x-large">
                        <span className="body-semibold colour-primary margin-large block-text">
                            About the purchase
                        </span>
                        <div className="owner-details-body-row margin-large">
                            {
                                purchaseInformation.map((item, index) =>
                                    <div 
                                        className="owner-details-body-row-element"
                                        key={index}
                                    >
                                        <span className="label-regular colour-primary margin-x-x-small block-text">
                                            {item.label}
                                        </span>
                                        <h3 className="body-semibold colour-primary">
                                            {item.value}
                                        </h3>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    :
                    null
                }
                <div className="owner-details-body-container">
                    <span className="body-semibold colour-primary block-text margin-medium">
                        Distress signals
                    </span>
                    <div className="owner-detials-distress-row">
                        <div className="owner-details-distress-grid">
                            {
                                distressSignalsLeft.map((item, index) =>
                                    <div 
                                        className={"owner-details-distress-element " + (item.value === false ? "owner-details-distress-inactive" : "") + (index === 13 ? "" : " margin-x-small")}
                                        key={index}
                                    >
                                        <div className="owner-details-tick-container subtext-margin-right">
                                            {
                                                item.value === true ?
                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg" 
                                                        width="16" 
                                                        height="14" 
                                                        viewBox="0 0 16 14" 
                                                        fill="none"
                                                    >
                                                        <path 
                                                            fillRule="evenodd" 
                                                            clipRule="evenodd" 
                                                            d="M15.4995 0.437654C16.1415 1.0449 16.1696 2.05758 15.5623 2.69952L6.22901 12.5662C5.93211 12.8801 5.52089 13.0605 5.08888 13.0665C4.65687 13.0725 4.2408 12.9035 3.93529 12.598L0.468629 9.13137C-0.15621 8.50653 -0.15621 7.49347 0.468629 6.86863C1.09347 6.24379 2.10653 6.24379 2.73137 6.86863L5.0348 9.17206L13.2376 0.500484C13.8449 -0.141464 14.8576 -0.169594 15.4995 0.437654Z" 
                                                            fill={colour.greenGreen02}
                                                        />
                                                    </svg>
                                                :
                                                null
                                            }
                                        </div>
                                        <OffMarketBadge
                                            emoji={item.icon}
                                            text={item.label}
                                        />
                                    </div>
                                )
                            }
                        </div>
                        <div className="owner-details-distress-grid">
                            {
                                distressSignalsRight.map((item, index) =>
                                    <div 
                                        className={"owner-details-distress-element " + (item.value === false ? "owner-details-distress-inactive" : "") + (index === 6 ? "" : " margin-x-small")}
                                        key={index}
                                    >
                                        <div className="owner-details-tick-container subtext-margin-right">
                                            {
                                                item.value === true ?
                                                    <svg 
                                                        xmlns="http://www.w3.org/2000/svg" 
                                                        width="16" 
                                                        height="14" 
                                                        viewBox="0 0 16 14" 
                                                        fill="none"
                                                    >
                                                        <path 
                                                            fillRule="evenodd" 
                                                            clipRule="evenodd" 
                                                            d="M15.4995 0.437654C16.1415 1.0449 16.1696 2.05758 15.5623 2.69952L6.22901 12.5662C5.93211 12.8801 5.52089 13.0605 5.08888 13.0665C4.65687 13.0725 4.2408 12.9035 3.93529 12.598L0.468629 9.13137C-0.15621 8.50653 -0.15621 7.49347 0.468629 6.86863C1.09347 6.24379 2.10653 6.24379 2.73137 6.86863L5.0348 9.17206L13.2376 0.500484C13.8449 -0.141464 14.8576 -0.169594 15.4995 0.437654Z" 
                                                            fill={colour.greenGreen02}
                                                        />
                                                    </svg>
                                                :
                                                null
                                            }
                                        </div>
                                        <OffMarketBadge
                                            emoji={item.icon}
                                            text={item.label}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OwnerDetails;