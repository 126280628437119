import React from 'react';
import '../styles/CityFiltering.css';

function CityFiltering(props) {
    const filteredResults = props.filteredResults;
    const func = props.func;
    const selectedFilteredResult = props.selectedFilteredResult;
    const activeSearching = props.activeSearching;
    const mapSearchAddressAutocomplete = props.mapSearchAddressAutocomplete;
    const selectGoogleMapProperty = props.selectGoogleMapProperty;

    return (
        <div className="city-filtering-outer-container">
            {
                activeSearching === true ?
                <div className="city-filtering-loading-container">
                    <span className="body-regular colour-primary">
                        Loading...
                    </span>
                </div>
                :
                null
            }
            {
                activeSearching === false ?
                    filteredResults.map((item, index) =>
                        <div 
                            className={"city-filtering-element " + (selectedFilteredResult === index ? "city-filtering-element-selected" : "")}
                            key={index}
                            onClick={() => func(item)}
                        >
                            <span className="body-regular colour-primary">
                                {item.city}, {item.state}
                            </span>
                        </div>
                    )
                :
                null
            }
            {
                activeSearching === false ?
                    mapSearchAddressAutocomplete.map((item, index) =>
                        <div 
                            className={"city-filtering-element " + (selectedFilteredResult === index ? "city-filtering-element-selected" : "")}
                            key={index}
                            onClick={() => selectGoogleMapProperty(item)}
                        >
                            <span className="body-regular colour-primary">
                                {item.description}
                            </span>
                        </div>
                    )
                :
                null
            }
        </div>
    )
}

export default CityFiltering;