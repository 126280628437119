import React, { useEffect, useState, useRef } from 'react';
import '../styles/BuyBoxSetup.css';
import { Footer, Header, ChoosePropertyType, ChooseBudget, ChoosePropertySpecifics, Loading, ChooseStrategy, EditNeighbourhoodGrade } from '../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeftThick, Pin } from '../assets';
import { ActionButton, colour } from '../styles/GlobalStyles';
import { checkOnAuthStateChanged, getDocument, makeId, recordEvent, setData, getFreeTrialEnd, getCitySearch, timer, sendCityIDErrorEmail, cloudFunctionV2, savePreferences } from '../functions';

function BuyBoxSetup() {

    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState(location.state);
    const chosenCity = state === null ? null : state.chosenCity;
    const [step, setStep] = useState(1);
    const [propertyTypes, setPropertyTypes] = useState([
        {
            code: "MultiFamily",
            included: false,
            property: "Multi-Family",
            abbreviation: "MFH"
        },
        {
            code: "SingleFamily",
            included: false,
            property: "Single-Family",
            abbreviation: "SFH"
        }
    ]);
    const [units, setUnits] = useState(1);
    const [budgetRange, setBudgetRange] = useState([0, 1000000000]);
    const [bedrooms, setBedrooms] = useState(1);
	const [bathrooms, setBathrooms] = useState(1);
	const [squareFootage, setSquareFootage] = useState([0, 999999]);
	const [constructionYears, setConstructionYears] = useState(["", ""]);
    const [strategy, setStrategy] = useState("");
    const [mobileIntro, setMobileIntro] = useState(false);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [firstRender, setFirstRender] = useState(false);
    const [paidCity, setPaidCity] = useState(false);
    const [error, setError] = useState("");
    const [subscriptions, setSubscriptions] = useState([]);
    const [neighbourhoodGradeMin, setNeighbourhoodGradeMin] = useState(1);
    const [neighbourhoodGradeMax, setNeighbourhoodGradeMax] = useState(8);
    const buyBoxSetupRef = useRef();

    const steps = [
        {
            title: "Property type",
            description: "Which property types are you interested in?"
        },
        {
            title: "Budget",
            description: "Indicate a budget range."
        },
        {
            title: "Property specifics",
            description: "Let’s get specific! Filter by these property details."
        },
        {
            title: "Strategy",
            description: "Optionally choose a strategy to get search results tailored to you."
        },
        {
            title: "Location requirements",
            description: "Limit properties based on their neighborhood score."
        }
    ];

    const strategies = [
        {
            title: "Turnkey (Buy-and-hold)",
            description: "This is a long-term investment strategy of purchasing properties to hold and rent them for steady cash flow and potential appreciation.",
            text: "Turnkey",
            value: "turnkey"
        },
        {
            title: "BRRRR (Buy, Rehab, Rent, Refinance, Repeat)",
            description: "BRRRR is a real estate strategy involving buying undervalued properties, renovating for added value, renting out for income, refinancing to recover capital, and repeating the cycle.",
            text: "BRRRR",
            value: "brrrr"
        },
        {
            title: "Househacking",
            description: "Househacking means buying an investment property with the intention of living in one of the units or rooms (for at least one year) and renting out the others to cover expenses and generate income. This strategy allows the investor to make a much smaller down payment. We calculate this with a 5% down payment.",
            text: "Househacking",
            value: "househacking"
        },
        {
            title: "Fix & Flip",
            description: "Fix & flip is the short-term strategy of buying distressed properties, renovating them, and selling at a higher price to make a profit.",
            text: "Fix & Flip",
            value: "fix-flip"
        }
    ];

    useEffect(() => {
        document.title = state === null || state.chosenCity === undefined ? "Buy Box Setup" : chosenCity.metroArea === true ? `Buy Box Setup - ${chosenCity.msaTitle}` : `Buy Box Setup - ${state.chosenCity.city}, ${state.chosenCity.state}`;
        const checkListings = async() => {
            if ( state.onboarding === true ) {
                const colRef = "Buy Boxes";
                const user = await checkOnAuthStateChanged();
                if ( user.status === 200 ) {
                    const userId = user.userId;
                    const queryDocument = await getDocument(colRef, userId);
                    if ( queryDocument.status === 200 ) {
                        const stateClone = {...state};
                        stateClone.onboarding = false;
                        setState(stateClone);
                    }
                }
                else {
                    navigate("/pick-city");
                }
            }

            const colRef = "Subscriptions";
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const userId = user.userId;
                const queryDocument = await getDocument(colRef, userId);
                if ( queryDocument.status === 200 ) {
                    const data = queryDocument.data.data;
                    setSubscriptions(data);
                    const today = new Date();
                    const todaySeconds = today.getTime() / 1000;
                    const cityIndex = data.findIndex(e => e.city === state.chosenCity.city && e.state === state.chosenCity.state && e.endDate.seconds > todaySeconds);
                    if ( cityIndex !== -1 ) {
                        setPaidCity(true);
                    }
                }
            }
        };

        if ( state === null ) {
            navigate('/pick-city');
        }
        else if ( firstRender === false ) {
            checkListings();
            setFirstRender(true);
        }

        const handleWindowResize = () => {
			setInnerWidth(window.innerWidth);
		};
	  
		window.addEventListener('resize', handleWindowResize);
    }, [navigate, state, bathrooms, bedrooms, budgetRange, constructionYears, firstRender, propertyTypes, squareFootage, chosenCity.metroArea, chosenCity.msaTitle]);

    const goBack = () => {
        if ( step === 1 && innerWidth < 1000 && mobileIntro === true) {
            setMobileIntro(false);
        }
        else if ( step === 1 && state.onboarding === true ) {
            navigate("/pick-city", {
                state: state
            });
            recordEvent("Back to 'Where To Invest' Page", {});
        }
        else if ( step === 1 && state.onboarding === false ) {
            navigate("/my-cities", {
                state: state
            });
            recordEvent("Back to 'My Cities' Page", {});
        }
        else {
            recordEvent(`Back to Step ${step}`, {});
            setStep(step - 1);
        }
    };

    const changeNeighbourhoodGrade = (val, index) => {
        if ( index === 0 ) {
            setNeighbourhoodGradeMin(val);
        }
        else {
            setNeighbourhoodGradeMax(val);
        }
    };

    const next = async() => {
        if ( step === 5 ) {
            complete();
        }
        else {
            if ( step === 3 ) {
                setConstructionYears([1800, 2024]);
            }
            setStep(step + 1);
        }

        if ( buyBoxSetupRef.current !== null ) {
            buyBoxSetupRef.current.scrollIntoView();
        }
    };

    const completeIntro = () => {
        setMobileIntro(true);
    };

    const checkDisabled = () => {
        if ( disabled === true ) {
            return true;
        }
        else if ( step === 1 ) {
            if ( propertyTypes[0].included === false && propertyTypes[1].included === false ) {
                return true;
            }
            else {
                return false;
            }
        }
        else if ( step === 2 ) {
            return false;
        }
        else if ( step === 3 ) {
            return false;
        }
        else if ( step === 4 ) {
            return false;
        }
        else if ( step === 5 ) {
            return false;
        }
    };

    const complete = async() => {
        setDisabled(true);
        setLoading(true);
        const colRef = "Buy Boxes";
        // Create a new buy box

        if ( chosenCity.cityId === undefined && chosenCity.metroArea !== true ) {
            const params = {
                city: chosenCity.city,
                state: chosenCity.state,
                returnCityId: "True"
            }

            const getCityParams = await getCitySearch(params, false);
            const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
            const gatewayData = {
                type: "gateway",
                resourceId: "citySearchLive",
                queryString: getCityParams
            };
            const queryCityIdData = await cloudFunctionV2(gatewayURL, gatewayData);
            if ( queryCityIdData.status === 200 ) {
                const getCityIdBody = queryCityIdData.body;
                const getCityId = getCityIdBody.cityId;
                if ( getCityId === null || getCityId === undefined ) {
                    if ( chosenCity.metroArea === true ) {
                        setError(`We're having an issue with ${chosenCity.msaTitle}. Please contact us for more details.`);
                    }
                    else {
                        setError(`We're having an issue with ${chosenCity.city}, ${chosenCity.state}. Please contact us for more details.`);
                    }
                    const cityIdErrorPage = "Buy Box Setup";
			        const apiResponse = `City ID null or undefined: ${getCityId}`;
                    await sendCityIDErrorEmail(chosenCity.city, chosenCity.state, cityIdErrorPage, apiResponse);
                }
                else {
                    const stateClone = {...state};
                    stateClone.chosenCity.cityId = getCityId;
                    setState(stateClone);
                    await timer(500);
                    complete();
                }
            }
            else {
                if ( chosenCity.metroArea === true ) {
                    setError(`We're having an issue with ${chosenCity.msaTitle}. Please contact us for more details.`);
                }
                else {
                    setError(`We're having an issue with ${chosenCity.city}, ${chosenCity.state}. Please contact us for more details.`);
                }

                if ( chosenCity.metroArea !== true ) {
                    const cityIdErrorPage = "Buy Box Setup";
                    const apiResponse = `Query came back with bad status: ${JSON.stringify(queryCityIdData)}`;
                    await sendCityIDErrorEmail(chosenCity.city, chosenCity.state, cityIdErrorPage, apiResponse);
                }
            }
        }
        else {
            setError("");
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const userId = user.userId;
                const queryDocument = await getDocument(colRef, userId);
                let pastBuyBoxes = [];
                if ( queryDocument.status === 200 ) {
                    pastBuyBoxes = queryDocument.data.data;
                }
                const freeTrialEnd = await getFreeTrialEnd(userId);

                const id = await makeId(10);
                const cityIndex = chosenCity.metroArea === true && chosenCity.msaCode !== undefined ? pastBuyBoxes.findIndex(e => e.msaCode === chosenCity.msaCode) : chosenCity.cityId !== undefined ? pastBuyBoxes.findIndex(e => e.cityId === chosenCity.cityId) : pastBuyBoxes.findIndex(e => e.city === chosenCity.city && e.state === chosenCity.state);
                const strategyIndex = strategies.findIndex(e => e.value === strategy);
                const chosenStrategy = strategy === "" || strategyIndex === -1 ? "" : ` ${strategies[strategyIndex].text}`;
                const newBuyBox = {
                    activeListings: 0,
                    bathrooms: bathrooms,
                    bedrooms: bedrooms,
                    budgetRange: budgetRange,
                    constructionYears: constructionYears,
                    frequency: "daily",
                    id: id,
                    lastUpdate: paidCity === true ? new Date() : freeTrialEnd.startDate,
                    metroArea: chosenCity.metroArea === true ? true : false,
                    neighbourhoodGradeMin: neighbourhoodGradeMin,
                    neighbourhoodGradeMax: neighbourhoodGradeMax,
                    newListings: 0,
                    noFixerUpper: false,
                    onlyADU: false,
                    onlyExtraBedroom: false,
                    propertyTypes: propertyTypes,
                    sent: [],
                    squareFootage: squareFootage,
                    strategy: strategy,
                    title: chosenCity.metroArea === true ? `My ${chosenCity.msaTitle}${chosenStrategy} buy box` : `My ${chosenCity.city}, ${chosenCity.state}${chosenStrategy} buy box`,
                    totalListings: 0,
                    units: units
                };

                if ( chosenCity.metroArea === true ) {
                    newBuyBox.msaTitle = chosenCity.msaTitle;
                    newBuyBox.msaCode = chosenCity.msaCode;
                }
                else {
                    newBuyBox.city = chosenCity.city;
                    newBuyBox.state = chosenCity.state;
                    newBuyBox.cityId = chosenCity.cityId;
                }

                if ( cityIndex === -1 ) {
                    const newCityBuyBox = {
                        metroArea: chosenCity.metroArea === true ? true : false,
                        buyBoxes: [],
                        loaded: true
                    };

                    if ( chosenCity.metroArea === true ) {
                        newCityBuyBox.msaTitle = chosenCity.msaTitle;
                        newCityBuyBox.msaCode = chosenCity.msaCode;
                    }
                    else {
                        newCityBuyBox.city = chosenCity.city;
                        newCityBuyBox.state = chosenCity.state;
                        newCityBuyBox.cityId = chosenCity.cityId;
                    }

                    newCityBuyBox.buyBoxes.unshift(newBuyBox);
                    pastBuyBoxes.unshift(newCityBuyBox);
                }
                else {
                    pastBuyBoxes[cityIndex].buyBoxes.unshift(newBuyBox);
                }
                await setData(colRef, userId, pastBuyBoxes);
                const preferenceCities = [
                    {
                        metroArea: chosenCity.metroArea === true ? true : false,
                        allCities: false
                    }
                ];

                if ( chosenCity.metroArea === true ) {
                    preferenceCities[0].msaTitle = chosenCity.msaTitle;
                    preferenceCities[0].msaCode = chosenCity.msaCode;
                }
                else {
                    preferenceCities[0].city = chosenCity.city;
                    preferenceCities[0].state = chosenCity.state;
                    preferenceCities[0].cityId = chosenCity.cityId;
                }

                await savePreferences(userId, preferenceCities);

                recordEvent("New Buy Box Saved", {
                    "City": chosenCity.metroArea === true ? chosenCity.msaTitle : `${chosenCity.city}, ${chosenCity.state}`,
                    "City ID": chosenCity.metroArea === true ? chosenCity.msaCode : chosenCity.cityId,
                });

                navigate("/my-cities", {
                    state: state
                });
            }
            else {
                navigate("/sign-up", {
                    state: state
                });
            }
        }
        setLoading(false);
        setDisabled(false);
    };

    return (
        <div ref={buyBoxSetupRef} className="buy-box-setup-outer-container">
            <Header
                subscriptions={subscriptions}
                users={null}
                queries={[true, false]}
                mobileNav={true}
            />
            {
                state === null ?
                null
                :
                innerWidth < 1000 && mobileIntro === false ?
                <div className="buy-box-setup-inner-container relative-container">
                    <div className="buy-box-setup-mobile-title-container">
                        <h1 className="heading-large-semibold colour-primary margin-medium text-align-center">
                            Buy Box Setup
                        </h1>
                        <span className="body-regular colour-secondary block-text text-align-center margin-medium">
                            Let’s set up a buy box – this helps us show you the best 
                            properties and notify you when new ones within your buy box are listed.
                        </span>
                        <div className="buy-box-setup-row margin-large">
                            <img
                                src={Pin}
                                className="buy-box-setup-pin"
                                alt="pin"
                            />
                            <span className="body-regular colour-secondary block-text text-align-center">
                                {
                                    chosenCity.metroArea === true ?
                                    chosenCity.msaTitle
                                    :
                                    `${chosenCity.city}, ${chosenCity.state}`
                                }
                            </span>
                        </div>
                        <div className="buy-box-setup-mobile-intro-button-container margin-x-x-large">
                            <ActionButton
                                onClick={() => completeIntro()}
                            >
                                Start
                            </ActionButton>
                        </div>
                    </div>
                </div>
                :
                <div className="buy-box-setup-inner-container">
                    {
                        loading === true ?
                        <Loading />
                        :
                        null
                    }
                    <div className="buy-box-setup-title-container">
                        <h1 className="heading-large-semibold colour-primary margin-medium text-align-center">
                            Buy Box Setup
                        </h1>
                        <span className="body-regular colour-secondary block-text text-align-center margin-medium">
                            Let’s set up a buy box – this helps us show you the best 
                            properties and notify you when new ones within your buy box are listed.
                        </span>
                        <div className="buy-box-setup-row margin-large">
                            <img
                                src={Pin}
                                className="buy-box-setup-pin"
                                alt="pin"
                            />
                            <span className="body-regular colour-secondary block-text text-align-center">
                                {
                                    chosenCity.metroArea === true ?
                                    chosenCity.msaTitle
                                    :
                                    `${chosenCity.city}, ${chosenCity.state}`
                                }
                            </span>
                        </div>
                    </div>
                    <div className="buy-box-setup-mobile-top-fixed-container margin-x-small">
                        <div 
                            className="buy-box-setup-back-button-container text-button cursor-pointer"
                            onClick={() => goBack()}
                        >
                            <img
                                src={ChevronLeftThick}
                                className="buy-box-setup-back-button"
                                alt="Go back"
                            />
                            <span className="body-regular text-link">
                                Back
                            </span>
                        </div>
                        <div className="buy-box-setup-mobile-steps-container">
                            <span className="label-regular colour-secondary">
                                Step {step} of 5
                            </span>
                        </div>
                    </div>
                    <div className="buy-box-setup-mobile-progress-bar-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="4" viewBox="0 0 100% 4" fill="none">
                            <rect 
                                width="100%" 
                                height="4" 
                                rx="2" 
                                fill="#F2E1D0"
                            />
                            <rect
                                width={`${step * 25}%`} 
                                height="4" 
                                rx="2" 
                                fill={colour.blueBlue03}
                            />
                        </svg>
                    </div>
                    <div className="buy-box-setup-body-container margin-large">
                        <div className="buy-box-setup-body-inner-container">
                            <div className="buy-box-setup-body-mobile-top-container">
                                <div className="buy-box-setup-row margin-large">
                                    <img
                                        src={Pin}
                                        className="buy-box-setup-pin"
                                        alt="pin"
                                    />
                                    <span className="body-regular colour-secondary block-text text-align-center">
                                        {
                                            chosenCity.metroArea === true ?
                                            chosenCity.msaTitle
                                            :
                                            `${chosenCity.city}, ${chosenCity.state}`
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="buy-box-setup-body-bottom-container">
                                <div className="buy-box-setup-step-subtitle-container margin-large">
                                    <h2 className="heading-small-semibold colour-primary block-text text-align-center margin-x-small">
                                        {steps[step - 1].title}
                                    </h2>
                                    <span className="body-regular colour-secondary block-text text-align-center">
                                        {steps[step - 1].description}
                                    </span>
                                </div>
                                {
                                    step === 1 ?
                                    <ChoosePropertyType
                                        propertyTypes={propertyTypes}
                                        setPropertyTypes={setPropertyTypes}
                                        units={units}
                                        setUnits={setUnits}
                                    />
                                    :
                                    step === 2 ?
                                    <ChooseBudget
                                        budgetRange={budgetRange}
                                        setBudgetRange={setBudgetRange}
                                    />
                                    :
                                    step === 3 ?
                                    <ChoosePropertySpecifics
                                        bedrooms={bedrooms}
                                        setBedrooms={setBedrooms}
                                        bathrooms={bathrooms}
                                        setBathrooms={setBathrooms}
                                        squareFootage={squareFootage}
                                        setSquareFootage={setSquareFootage}
                                        constructionYears={constructionYears}
                                        setConstructionYears={setConstructionYears}
                                    />
                                    :
                                    step === 4 ?
                                    <ChooseStrategy
                                        strategies={strategies}
                                        strategy={strategy}
                                        setStrategy={setStrategy}
                                    />
                                    :
                                    step === 5 ?
                                    <EditNeighbourhoodGrade
                                        neighbourhoodGradeMin={neighbourhoodGradeMin}
                                        neighbourhoodGradeMax={neighbourhoodGradeMax}
                                        changeNeighbourhoodGrade={changeNeighbourhoodGrade}
                                        filtersModal={false}
                                    />
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="buy-box-setup-buttons-row margin-x-x-large">
                        <div 
                            className="buy-box-setup-back-button-container text-button cursor-pointer"
                            onClick={() => goBack()}
                        >
                            <img
                                src={ChevronLeftThick}
                                className="buy-box-setup-back-button"
                                alt="Go back"
                            />
                            <span className="body-regular text-link">
                                Back
                            </span>
                        </div>
                        <div className="buy-box-setup-next-button">
                            {
                                error !== "" ?
                                <div className="margin-x-small">
                                    <span className="body-regular colour-error">
                                        {error}
                                    </span>
                                </div>
                                :
                                null
                            }
                            <ActionButton
                                disabled={checkDisabled()}
                                onClick={() => next()}
                            >
                                Next
                            </ActionButton>
                        </div>
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
};

export default BuyBoxSetup;