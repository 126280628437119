import React, { useState, useEffect } from 'react';
import '../styles/MyCities.css';
import { Header, Footer, Chip, ExitScreen, SideNavigation, Loading, AddCity, AllCitiesBuyBox, OneCityBuyBox, DeleteModal, UpgradeMetroModal, AllCitiesLoading, PremiumModal, Modal, UnassignedCounties } from '../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { CityEmoji } from '../assets/city-emojis';
import { ChevronLeft, ChevronRight, Cog } from '../assets';
import { changeBuyBoxFrequency, checkOnAuthStateChanged, checkWhichAddCityPath, deleteBuyBoxFrequency, getUserDocuments, recordEvent, checkBuyBoxUpdates, changeBuyBoxStrategy, getDocument, setData, checkUserId, unassignedCountyWrite } from '../functions';
import { EspressoShots } from '../assets/images';
import { ActionButton } from "../styles/GlobalStyles";

function MyCities() {
    const location = useLocation();
    let state = location.state;
    const navigate = useNavigate();

    const [cities, setCities] = useState([]);
    const [selectedPanel, setSelectedPanel] = useState(0);
    const [mobileSelectedPanel, setMobileSelectedPanel] = useState(null);
    const [buyBoxMore, setBuyBoxMore] = useState(null);
    const [buyBoxFrequency, setBuyBoxFrequency] = useState(null);
    const [openStrategy, setOpenStrategy] = useState(null);
    const [userData, setUserData] = useState(null);
    const [buyBoxes, setBuyBoxes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteParam, setDeleteParam] = useState(null);
    const [freeTrial, setFreeTrial] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);
    const [allCitiesLoading, setAllCitiesLoading] = useState(false);
    const [firstRender, setFirstRender] = useState(false);
    const [premiumModalOpen, setPremiumModalOpen] = useState(false);
    const [deleteCityModal, setDeleteCityModal] = useState(false);
    const [deleteCityParam, setDeleteCityParam] = useState(null);
    const [loadingBuyBoxes, setLoadingBuyBoxes] = useState([]);
    const [metroUpgradeModal, setMetroUpgradeModal] = useState(false);
    const [metroUpgradeCity, setMetroUpgradeCity] = useState(null);
    const [unassignedCountyModal, setUnassignedCountyModal] = useState(false);
    const [anyUnassignedCounty, setAnyUnassignedCounty] = useState(false);

    useEffect(() => {
        document.title = "My Markets";
        const fetchUserData = async() => {
            setFirstRender(true);
            const collections = [
                "Users",
                "Subscriptions",
                "Buy Boxes"
            ];
            const user = await checkOnAuthStateChanged();
            if ( user.status === 200 ) {
                const userId = user.userId;
                const queryUserData = await getUserDocuments(collections, userId);
                let checkFreeTrial = false;
				if ( queryUserData[0].status === 200 ) {
                    const data = queryUserData[0].data;
                    if ( data.freeTrial[0].endDate.seconds > new Date().getTime() / 1000 ) {
                        checkFreeTrial = true;
                    }
                    setUserData(data);
                }

                if ( queryUserData[1].status === 200 ) {
                    setFreeTrial(checkFreeTrial);
                    setSubscriptions(queryUserData[1].data);

                    for (let index = 0; index < queryUserData[1].data.length; index++) {
                        const element = queryUserData[1].data[index];
                        if ( element.metroArea === true && element.msaCode === "" ) {
                            if ( queryUserData[0].status === 200 ) {
                                const data = queryUserData[0].data;
                                if ( data.unassignedCounties !== undefined && data.unassignedCounties[0] === true ) {
                                    setUnassignedCountyModal(true);
                                }
                            }
                            setAnyUnassignedCounty(true);
                        }
                    }
                }

                if ( queryUserData[2].status === 200 ) {
                    const queriedBuyBoxes = queryUserData[2].data;
                    const loadedBuyBoxes = [];
                    for (let index = 0; index < queriedBuyBoxes.length; index++) {
                        const element = queriedBuyBoxes[index];
                        loadedBuyBoxes.push(element.loaded);
                    }

                    if ( loadedBuyBoxes.indexOf(true) === -1 && queriedBuyBoxes.length > 0 ) {
                        setAllCitiesLoading(true);
                    }
                    
                    const buyBoxCities = queryUserData[2].data
                    const citiesClone = [
                        {
                            label: "All cities",
                            value: "all-cities"
                        }
                    ];

                    for (let index = 0; index < buyBoxCities.length; index++) {
                        const element = buyBoxCities[index];
                        const cityValue = element.metroArea === true ? element.msaTitle.toLowerCase().replace(/\s/g, '-').replace(',', '') : `${element.city}, ${element.state}`.toLowerCase().replace(/\s/g, '-').replace(',', '');
                        const newObject = {
                            label: element.metroArea === true ? element.msaTitle : `${element.city}, ${element.state}`,
                            value: cityValue
                        }
                        citiesClone.push(newObject);
                    };
                    setCities(citiesClone);
                    setBuyBoxes(queriedBuyBoxes);
                    setLoading(false);

                    // Check how many new properties have been uploaded to the buy box
                    const checkSubscriptions = queryUserData[1].status === 200 ? queryUserData[1].data : [];
                    const buyBoxUpdates = await checkBuyBoxUpdates(queryUserData[2].data, userId, checkSubscriptions, checkFreeTrial, setLoadingBuyBoxes);
                    if ( buyBoxUpdates.changes === true ) {
                        setBuyBoxes(buyBoxUpdates.data);
                    }
                }
                else {
                    const citiesClone = [
                        {
                            label: "All cities",
                            value: "all-cities"
                        }
                    ];
                    setCities(citiesClone);
                    setLoading(false);
                }
            }
            else {
                setLoading(false);
                const checkPath = await checkWhichAddCityPath(freeTrial, subscriptions);
                const chosenPath = checkPath.path;
                const newState = checkPath.state;
        
                navigate(chosenPath, {
                    state: newState === null ? state : newState
                });
            }
        };

        if ( firstRender === false ) {
            fetchUserData();
        }
    }, [cities, navigate, state, firstRender, freeTrial, subscriptions]);

    const changeSelectedPanel = (index) => {
        setSelectedPanel(index);
    };

    const newBuyBox = (city) => {
        const newState = {
            onboarding: false
        };

        if ( city.metroArea === true ) {
            newState.chosenCity = {
                msaCode: city.msaCode,
                msaTitle: city.msaTitle,
                metroArea: true
            }
        }
        else {
            newState.chosenCity = {
                city: city.city,
                state: city.state,
                cityId: city.cityId,
                metroArea: false
            };
        }

        navigate("/buy-box-setup", {
            state: newState
        })
    };

    const triggerExit = () => {
        setBuyBoxFrequency(null);
        setBuyBoxMore(null);
        setOpenStrategy(null);
    };

    const exploreMore = async() => {
        const checkPath = await checkWhichAddCityPath(freeTrial, subscriptions);
        const chosenPath = checkPath.path;
        const newState = checkPath.state;

        navigate(chosenPath, {
            state: newState === null ? state : newState
        });
    };

    const onChangeBuyBoxFrequency = async(frequency, id, item) => {
        setOpenStrategy(null);
        setLoading(true);
        const buyBoxChange = await changeBuyBoxFrequency(item, frequency, id);
        if ( buyBoxChange.status === 200 ) {
            setBuyBoxes(buyBoxChange.data);
            setLoading(false);
        }
        else if ( buyBoxChange.status === 400 ) {
            const checkPath = await checkWhichAddCityPath(freeTrial, subscriptions);
            const chosenPath = checkPath.path;
            const newState = checkPath.state;
    
            navigate(chosenPath, {
                state: newState === null ? state : newState
            });
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    };

    const onChangeBuyBoxStrategy = async(val, buyBoxItem) => {
        setLoading(true);
        const buyBoxChange = await changeBuyBoxStrategy(buyBoxItem, val);
        if ( buyBoxChange.status === 200 ) {
            setBuyBoxes(buyBoxChange.data);
            setLoading(false);
        }
        else if ( buyBoxChange.status === 400 ) {
            navigate("/pick-city");
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    };

    const selectMobilePanel = (index) => {
        recordEvent("Mobile Select City", {
            city: cities[index].label
        });
        setMobileSelectedPanel(index);
    };

    const mobileBack = () => {
        setMobileSelectedPanel(null);
    };

    const manageSubscriptions = () => {
        navigate("/profile?tab=1", {
            state: state
        });
    };

    const editBuyBox = (item) => {
        if ( state === null ) {
            state = {
                userData: userData,
                buyBox: item,
            }
        }
        else {
            state.userData = userData;
            state.buyBox = item;
        }

        if ( item.metroArea === true ) {
            const msaCode = item.msaCode;
            const cityIndex = buyBoxes.findIndex(e => e.msaCode === msaCode);
            if ( cityIndex !== -1 ) {
                state.buyBoxCity = buyBoxes[cityIndex];
            }
        }
        else {
            delete state.buyBoxCity;
        }

        recordEvent("Edit Buy Box", {
            item: item
        });
        navigate("/edit-buy-box", {
            state: state
        });
    };

    const viewCity = (item) => {
        if ( item.metroArea === true ) {
            if ( item.msaCode.includes("CTY") ) {
                window.open(`/county-properties-list/${item.msaCode}`, "_blank");
            }
            else {
                window.open(`/metro-properties-list/${item.msaCode}/${item.msaCityIds[0]}`, "_blank");
            }
        }
        else {
            window.open(`/properties-list/${item.cityId}`, "_blank");
        }
    };

    const onChangeMetroUpgradeModal = (city) => {
        setMetroUpgradeCity(city);
        setMetroUpgradeModal(true);
        recordEvent("Upgrade Metro Modal - My Cities", {
            msaTitle: city.msaTitle
        });
    };

    const goToFreeCities = () => {
        navigate("/pick-city");;
    };

    const openUpgradeModal = () => {
        setPremiumModalOpen(true);
    };

    const openDeleteModal = () => {
        setDeleteModal(true);
    };

    const deleteBuyBox = async(item) => {
        setDeleteModal(false);
        setLoading(true);
        const deletion = await deleteBuyBoxFrequency(item);
        if ( deletion.status === 200 ) {
            setBuyBoxes(deletion.data);
        }
        setLoading(false);
        setBuyBoxMore(null);

        const eventData = {};
        if ( item.metroArea === true ) {
            eventData.msaTitle = item.msaTitle;
            eventData.msaCode = item.msaCode;
        }
        else {
            eventData.city = item.city;
            eventData.state = item.state;
        }
        recordEvent("Delete Buy Box", eventData);
        setDeleteParam(null);
    };

    const openDeleteCityModal = (city) => {
        setDeleteCityModal(true);
        setDeleteCityParam(city);
    };

    const deleteCity = async() => {
        setLoading(true);
        setSelectedPanel(0);
        const user = await checkOnAuthStateChanged();
        if ( user.status === 200 ) {
            const userId = user.userId;
            const getBuyBoxes = await getDocument("Buy Boxes", userId);
            if ( getBuyBoxes.status === 200 ) {
                const data = getBuyBoxes.data.data;

                if ( deleteCityParam.metroArea !== true ) {
                    const cityIndex = data.findIndex(e => e.cityId === deleteCityParam.cityId);
                    if ( cityIndex !== -1 ) {
                        data.splice(cityIndex, 1);
                        await setData("Buy Boxes", userId, data);
                        setBuyBoxes(data);

                        const cityValue = `${deleteCityParam.city}, ${deleteCityParam.state}`.toLowerCase().replace(/\s/g, '-').replace(',', '');
                        const cityTabIndex = cities.findIndex(e => e.value === cityValue);
                        const newCities = [...cities];
                        if ( cityTabIndex !== -1 ) {
                            newCities.splice(cityTabIndex, 1);
                            setCities(newCities);
                        }
                    }
                }
                else {
                    const cityIndex = data.findIndex(e => e.msaCode === deleteCityParam.msaCode);
                    if ( cityIndex !== -1 ) {
                        data.splice(cityIndex, 1);
                        await setData("Buy Boxes", userId, data);
                        setBuyBoxes(data);

                        const cityValue = deleteCityParam.msaTitle.toLowerCase().replace(/\s/g, '-').replace(',', '').toLowerCase().replace(/\s/g, '-').replace(',', '');
                        const cityTabIndex = cities.findIndex(e => e.value === cityValue);
                        const newCities = [...cities];
                        if ( cityTabIndex !== -1 ) {
                            newCities.splice(cityTabIndex, 1);
                            setCities(newCities);
                        }
                    }
                }
            }

            setLoading(false);
            setDeleteCityModal(false);
        }
        else {
            navigate("/sign-up");
        }
    };

    const closeUnassignedCountyModal = async() => {
		setUnassignedCountyModal(false);

        const currentUnassignedCounties = userData.unassignedCounties;
		if ( currentUnassignedCounties !== undefined ) {
			currentUnassignedCounties[0] = false;
			const user = await checkUserId();
			if ( user.status === 200 ) {
				const userId = user.userId;
				await unassignedCountyWrite(userId, currentUnassignedCounties);
			}
		}
	};

	const goToUnassignedCounty = async() => {
		let unassignedCounties = 0;
		for (let index = 0; index < subscriptions.length; index++) {
			const element = subscriptions[index];
			if ( element.metroArea === true && element.msaCode === "" ) {
				unassignedCounties++;
			}
		}

        const currentUnassignedCounties = userData.unassignedCounties;
		if ( currentUnassignedCounties !== undefined ) {
			currentUnassignedCounties[0] = false;
			const user = await checkUserId();
			if ( user.status === 200 ) {
				const userId = user.userId;
				await unassignedCountyWrite(userId, currentUnassignedCounties);
			}
		}

		navigate("/choose-counties", {
			state: {
				counties: unassignedCounties
			}
		});
	};

    const goToChooseCounties = () => {
        let unassignedCounties = 0;
		for (let index = 0; index < subscriptions.length; index++) {
			const element = subscriptions[index];
			if ( element.metroArea === true && element.msaCode === "" ) {
				unassignedCounties++;
			}
		}

        navigate("/choose-counties", {
			state: {
				counties: unassignedCounties
			}
		});
    };

    return (
        <div className="my-cities-outer-container relative-container">
            <Header
                subscriptions={subscriptions}
                users={userData}
                queries={[false, false]}
                mobileNav={true}
            />
            {
                premiumModalOpen === true || deleteModal === true || deleteCityModal === true || unassignedCountyModal === true ? 
                <div className="my-cities-modal-background">
                </div>
                :
                null
            }
            {
                (deleteModal === false && deleteCityModal === false) && (buyBoxFrequency !== null || buyBoxMore !== null || openStrategy !== null) ?
                <ExitScreen
                    clickScreen={() => triggerExit()}
                />
                :
                null
            }
            {
                deleteModal === true ?
                <DeleteModal
                    deleteFunction={deleteBuyBox}
                    setDeleteModal={setDeleteModal}
                    description="Are you sure you want to delete this buy box?"
                    deleteParam={deleteParam}
                />
                :
                null
            }
            {
                deleteCityModal === true ?
                <DeleteModal
                    deleteFunction={deleteCity}
                    setDeleteModal={setDeleteCityModal}
                    description="Are you sure you want to delete this city and all its buy boxes?"
                    deleteParam={deleteParam}
                />
                :
                null
            }
            {
                premiumModalOpen === true ?
                <PremiumModal
                    setPremiumModalOpen={setPremiumModalOpen}
                    state={null}
                />
                :
                null
            }
            {
                metroUpgradeModal === true ?
                <UpgradeMetroModal
                    setViewUpgradeModal={setMetroUpgradeModal}
                    upgradeMetro={metroUpgradeCity}
                />
                :
                null
            }
            <div className="my-cities-inner-container relative-container">
                <SideNavigation
                    navigate={navigate}
                    state={state}
                    userData={userData}
                />
                <div className="my-cities-body-container">
                    <div className="my-cities-inner-body-container">
                    {
                            unassignedCountyModal === true ?
                            <Modal
                                title="You have counties that are unassigned"
                                description="You have counties that you bought which need to be assigned to your account!"
                                closeButtonText="Close"
                                saveButtonText="Choose counties"
                                closeModal={closeUnassignedCountyModal}
                                saveModal={goToUnassignedCounty}
                            />
                            :
                            null
                        }
                        {
                            loading === true ?
                            <div className="my-cities-loading-container">
                                <Loading />
                            </div>
                            :
                            null
                        }
                        <div className="my-cities-title-container">
                            <div className="my-cities-title-left-container">
                                <img
                                    src={CityEmoji}
                                    className="my-cities-title-emoji"
                                    alt="My Markets"
                                />
                                <div className="my-cities-title-left-text-container">
                                    <h1 className="heading-large-semibold colour-primary margin-x-small">
                                        My markets
                                    </h1>
                                    <span className="body-regular colour-secondary">
                                        The home for your cities and buy boxes.
                                    </span>
                                    {
                                        anyUnassignedCounty === true ?
                                        <UnassignedCounties
                                            cta={goToChooseCounties}
                                        />
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className="my-cities-title-right-container">
                                <div 
                                    className="button-row text-button subtext-margin-right-x-large"
                                    onClick={() => manageSubscriptions()}
                                >
                                    <img
                                        src={Cog}
                                        className="my-cities-title-cog"
                                        alt="Manage subscriptions"
                                    />
                                    <span className="body-regular text-link">
                                        Manage subscriptions
                                    </span>
                                </div>
                                {/* <div className="button-row text-button">
                                    <img
                                        src={Sort}
                                        className="my-cities-title-sort"
                                        alt="Sort"
                                    />
                                    <span className="body-regular text-link">
                                        Sort
                                    </span>
                                </div> */}
                            </div>
                        </div>
                        <div className="my-cities-divider-line margin-tops">
                        </div>
                        <div className="my-cities-chips-row margin-large">
                            {
                                cities.map((item, index) =>
                                    <Chip
                                        label={item.label}
                                        func={changeSelectedPanel}
                                        index={index}
                                        selected={selectedPanel}
                                        key={index}
                                        changeIcon={null}
                                        recommended={false}
                                        hover={null}
                                        leave={null}
                                        disabled={false}
                                    />
                                )
                            }
                            <AddCity
                                freeTrial={freeTrial}
                                subscriptions={subscriptions}
                            />
                        </div>
                        <div className="my-cities-sub-body-container">
                            {
                                cities.length === 1 && userData !== null ?
                                <div className="my-cities-zero-state-container">
                                    <img
                                        src={EspressoShots}
                                        className="my-cities-espresso-shots margin-large"
                                        alt="Espresso Shots"
                                    />
                                    <h2 className="heading-small-semibold colour-primary margin-x-small">
                                        Ready to start exploring?
                                    </h2>
                                    <span className="body-regular colour-secondary block-text text-align-center margin-large">
                                        You don’t have any cities yet. Choose a sample city to browse and it will appear here.
                                    </span>
                                    <div className="my-cities-zero-state-button-container">
                                        <ActionButton
                                            onClick={() => goToFreeCities()}
                                        >
                                            Explore sample markets
                                        </ActionButton>
                                    </div>
                                </div>
                                :
                                allCitiesLoading === true ?
                                <AllCitiesLoading />
                                :
                                selectedPanel === 0 ?
                                <AllCitiesBuyBox
                                    buyBoxes={buyBoxes}
                                    newBuyBox={newBuyBox}
                                    buyBoxMore={buyBoxMore}
                                    setBuyBoxMore={setBuyBoxMore}
                                    buyBoxFrequency={buyBoxFrequency}
                                    setBuyBoxFrequency={setBuyBoxFrequency}
                                    onChangeBuyBoxFrequency={onChangeBuyBoxFrequency}
                                    mobile={false}
                                    exploreMore={exploreMore}
                                    editBuyBox={editBuyBox}
                                    deleteBuyBox={openDeleteModal}
                                    viewCity={viewCity}
                                    setDeleteParam={setDeleteParam}
                                    freeTrial={freeTrial}
                                    subscriptions={subscriptions}
                                    openUpgradeModal={openUpgradeModal}
                                    openStrategy={openStrategy}
                                    setOpenStrategy={setOpenStrategy}
                                    onChangeBuyBoxStrategy={onChangeBuyBoxStrategy}
                                    deleteCity={openDeleteCityModal}
                                    loadingBuyBoxes={loadingBuyBoxes}
                                    onChangeMetroUpgradeModal={onChangeMetroUpgradeModal}
                                />
                                :
                                <OneCityBuyBox
                                    cities={cities}
                                    buyBoxes={buyBoxes}
                                    newBuyBox={newBuyBox}
                                    buyBoxMore={buyBoxMore}
                                    setBuyBoxMore={setBuyBoxMore}
                                    buyBoxFrequency={buyBoxFrequency}
                                    setBuyBoxFrequency={setBuyBoxFrequency}
                                    onChangeBuyBoxFrequency={onChangeBuyBoxFrequency}
                                    selectedPanel={selectedPanel}
                                    mobile={false}
                                    exploreMore={exploreMore}
                                    editBuyBox={editBuyBox}
                                    deleteBuyBox={openDeleteModal}
                                    viewCity={viewCity}
                                    setDeleteParam={setDeleteParam}
                                    freeTrial={freeTrial}
                                    subscriptions={subscriptions}
                                    openUpgradeModal={openUpgradeModal}
                                    openStrategy={openStrategy}
                                    setOpenStrategy={setOpenStrategy}
                                    onChangeBuyBoxStrategy={onChangeBuyBoxStrategy}
                                    deleteCity={openDeleteCityModal}
                                    loadingBuyBoxes={loadingBuyBoxes}
                                    onChangeMetroUpgradeModal={onChangeMetroUpgradeModal}
                                />
                            }
                        </div>
                        <div 
                            className="button-row"
                            onClick={() => exploreMore()}
                        >
                            <span className="body-regular colour-secondary block-text subtext-margin-right">
                                Want to expand?
                            </span>
                            <span className="body-regular-underline colour-link">
                                Explore more cities
                            </span>
                        </div>
                    </div>
                    {
                        mobileSelectedPanel === null ?
                        <div className="my-cities-mobile-body-container relative-container">
                            {
                                loading === true ?
                                <div className="my-cities-loading-container">
                                    <Loading />
                                </div>
                                :
                                null
                            }
                            <div className="margin-large">
                                <h1 className="heading-large-semibold colour-primary margin-x-small">
                                    My markets
                                </h1>
                                <h2 className="body-regular colour-secondary">
                                    The home for your cities and buy boxes.
                                </h2>
                                {
                                    anyUnassignedCounty === true ?
                                    <UnassignedCounties
                                        cta={goToChooseCounties}
                                    />
                                    :
                                    null
                                }
                            </div>
                            <div className="my-cities-light-divider-line">
                            </div>
                            <div className="my-cities-mobile-options-container">
                                <div className="my-cities-mobile-option-element">
                                    <div 
                                        className="button-row my-cities-mobile-option-padding"
                                        onClick={() => manageSubscriptions()}
                                    >
                                        <img
                                            src={Cog}
                                            className="my-cities-mobile-option-cog"
                                            alt="Manage subscriptions"
                                        />
                                        <span className="body-regular colour-link block-text subtext-margin-right">
                                            Manage subscriptions
                                        </span>
                                        <img
                                            src={ChevronRight}
                                            className="my-cities-mobile-option-arrow"
                                            alt="Manage subscriptions"
                                        />
                                    </div>
                                </div>
                                {
                                    cities.length === 1 && userData !== null ?
                                    <div className="my-cities-zero-state-container">
                                        <img
                                            src={EspressoShots}
                                            className="my-cities-espresso-shots margin-large"
                                            alt="Espresso Shots"
                                        />
                                        <h2 className="heading-small-semibold colour-primary margin-x-small">
                                            Ready to start exploring?
                                        </h2>
                                        <span className="body-regular colour-secondary block-text text-align-center margin-large">
                                            You don’t have any cities yet. Choose a sample city to browse and it will appear here.
                                        </span>
                                        <div className="my-cities-zero-state-button-container">
                                            <ActionButton
                                                onClick={() => goToFreeCities()}
                                            >
                                                Explore sample markets
                                            </ActionButton>
                                        </div>
                                    </div>
                                    :
                                    cities.map((item, index) =>
                                        <div 
                                            className="my-cities-mobile-option-element"
                                            key={index}
                                            onClick={() => selectMobilePanel(index)}
                                        >
                                            <div className="button-row my-cities-mobile-option-padding">
                                                <span className="body-regular colour-link block-text subtext-margin-right">
                                                    {item.label}
                                                </span>
                                                <img
                                                    src={ChevronRight}
                                                    className="my-cities-mobile-option-arrow"
                                                    alt={item.label}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <div 
                                className="my-cities-mobile-centre"
                                onClick={() => exploreMore()}
                            >
                                <span className="body-regular colour-secondary block-text subtext-margin-right">
                                    Want to expand?
                                </span>
                                <span className="body-regular-underline colour-link">
                                    Explore more cities
                                </span>
                            </div>
                        </div>
                        :
                        <div className="my-cities-mobile-body-container relative-container">
                            {
                                loading === true ?
                                <div className="my-cities-loading-container">
                                    <Loading />
                                </div>
                                :
                                null
                            }
                            <div 
                                className="my-cities-mobile-back-container button-row text-button margin-x-x-large"
                                onClick={() => mobileBack()}
                            >
                                <img
                                    src={ChevronLeft}
                                    className="my-cities-back-chevron-left"
                                    alt="Go back"
                                />
                                <span className="body-regular colour-link">
                                    Back
                                </span>
                            </div>
                            <div className="my-cities-sub-body-container">
                                {
                                    allCitiesLoading === true ?
                                    <AllCitiesLoading />
                                    :
                                    mobileSelectedPanel === 0 ?
                                    <AllCitiesBuyBox
                                        buyBoxes={buyBoxes}
                                        newBuyBox={newBuyBox}
                                        buyBoxMore={buyBoxMore}
                                        setBuyBoxMore={setBuyBoxMore}
                                        buyBoxFrequency={buyBoxFrequency}
                                        setBuyBoxFrequency={setBuyBoxFrequency}
                                        onChangeBuyBoxFrequency={onChangeBuyBoxFrequency}
                                        mobile={true}
                                        exploreMore={exploreMore}
                                        editBuyBox={editBuyBox}
                                        deleteBuyBox={openDeleteModal}
                                        viewCity={viewCity}
                                        setDeleteParam={setDeleteParam}
                                        freeTrial={freeTrial}
                                        subscriptions={subscriptions}
                                        openUpgradeModal={openUpgradeModal}
                                        openStrategy={openStrategy}
                                        setOpenStrategy={setOpenStrategy}
                                        onChangeBuyBoxStrategy={onChangeBuyBoxStrategy}
                                        deleteCity={openDeleteCityModal}
                                        loadingBuyBoxes={loadingBuyBoxes}
                                        onChangeMetroUpgradeModal={onChangeMetroUpgradeModal}
                                    />
                                    :
                                    <OneCityBuyBox
                                        cities={cities}
                                        buyBoxes={buyBoxes}
                                        newBuyBox={newBuyBox}
                                        buyBoxMore={buyBoxMore}
                                        setBuyBoxMore={setBuyBoxMore}
                                        buyBoxFrequency={buyBoxFrequency}
                                        setBuyBoxFrequency={setBuyBoxFrequency}
                                        onChangeBuyBoxFrequency={onChangeBuyBoxFrequency}
                                        selectedPanel={mobileSelectedPanel}
                                        mobile={true}
                                        exploreMore={exploreMore}
                                        editBuyBox={editBuyBox}
                                        deleteBuyBox={openDeleteModal}
                                        viewCity={viewCity}
                                        setDeleteParam={setDeleteParam}
                                        freeTrial={freeTrial}
                                        subscriptions={subscriptions}
                                        openUpgradeModal={openUpgradeModal}
                                        openStrategy={openStrategy}
                                        setOpenStrategy={setOpenStrategy}
                                        onChangeBuyBoxStrategy={onChangeBuyBoxStrategy}
                                        deleteCity={openDeleteCityModal}
                                        loadingBuyBoxes={loadingBuyBoxes}
                                        onChangeMetroUpgradeModal={onChangeMetroUpgradeModal}
                                    />
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default MyCities;