import React from 'react';
import { ActionButton, citiesForPurchase } from '../styles/GlobalStyles';
import { ChevronRight, PlusWhite } from '../assets';
import { BuyBoxCard, NoBuyBoxCard, OneCityLoading } from './';

function OneCityBuyBox(props) {
    const cities = props.cities;
    const buyBoxes = props.buyBoxes;
    const newBuyBox = props.newBuyBox;
    const buyBoxMore = props.buyBoxMore;
    const setBuyBoxMore = props.setBuyBoxMore;
    const buyBoxFrequency = props.buyBoxFrequency;
    const setBuyBoxFrequency = props.setBuyBoxFrequency;
    const onChangeBuyBoxFrequency = props.onChangeBuyBoxFrequency;
    const selectedPanel = props.selectedPanel;
    const mobile = props.mobile;
    const exploreMore = props.exploreMore;
    const editBuyBox = props.editBuyBox;
    const deleteBuyBox = props.deleteBuyBox;
    const viewCity = props.viewCity;
    const setDeleteParam = props.setDeleteParam;
    const freeTrial = props.freeTrial;
    const subscriptions = props.subscriptions;
    const openUpgradeModal = props.openUpgradeModal;
    const openStrategy = props.openStrategy;
    const setOpenStrategy = props.setOpenStrategy;
    const onChangeBuyBoxStrategy = props.onChangeBuyBoxStrategy;
    const deleteCity = props.deleteCity;
    const loadingBuyBoxes = props.loadingBuyBoxes;
    const onChangeMetroUpgradeModal = props.onChangeMetroUpgradeModal;

    const checkSubscription = (city) => {
        const today = new Date();
		const todaySeconds = today.getTime() / 1000;
        const coreCity = city.metroArea === true ? -1 : citiesForPurchase.findIndex(e => e.city_id === city.cityId);
        const subscriptionIndex = city.metroArea === true ? -1 : subscriptions.findIndex(e => e.cityId === city.cityId && e.endDate.seconds > todaySeconds);
        if ( coreCity !== -1 && subscriptionIndex === -1 ) {
            return false;
        }
        else {
            return true;
        }
    };

    return (
        <div className="my-cities-choen-city-outer-container">
            {
                buyBoxes[selectedPanel - 1].loaded === false ?
                <div className="margin-medium">
                    <span className="heading-small-semibold colour-secondary">
                        {cities[selectedPanel].label}
                    </span>
                </div>
                :
                <div className="my-cities-chosen-city-container margin-medium">
                    <div className="my-cities-chosen-city-left-container">
                        <div 
                            className="my-cities-chosen-city-container-padding button-row text-button"
                            onClick={() => viewCity(buyBoxes[selectedPanel - 1])}
                        >
                            <h2 className="heading-small-semibold colour-primary subtext-margin-right">
                                {cities[selectedPanel].label}
                            </h2>
                            <img
                                src={ChevronRight}
                                className="my-cities-chosen-city-arrow"
                                alt="Arrow"
                            />
                        </div>
                        {
                            checkSubscription(buyBoxes[selectedPanel - 1]) === false ?
                            <div className="text-button">
                                <span 
                                    className="body-regular colour-error"
                                    onClick={() => deleteCity(buyBoxes[selectedPanel - 1])}
                                >
                                    Remove city
                                </span>
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className="my-cities-new-buy-box-right-container">
                        {
                            freeTrial === true ?
                            <div 
                                className="text-button subtext-margin-right-x-large"
                                onClick={() => onChangeMetroUpgradeModal()}
                            >
                                <span className="body-regular colour-link">
                                    🚀 Upgrade to entire county
                                </span>
                            </div>
                            :
                            null
                        }
                        <div className="my-cities-new-buy-box-container">
                            <ActionButton
                                onClick={() => newBuyBox(buyBoxes[selectedPanel - 1])}
                            >
                                <img
                                    src={PlusWhite}
                                    className="my-cities-new-buy-box-arrow"
                                    alt="New buy box"
                                />
                                New buy box
                            </ActionButton>
                        </div>
                    </div>
                </div>
            }
            {
                buyBoxes[selectedPanel - 1].loaded === false ?
                <OneCityLoading />
                :
                <div className="my-cities-grid margin-large">
                    {
                        buyBoxes.map((city, i) =>
                            i === (selectedPanel - 1) ?
                            city.buyBoxes.length === 0 ?
                            <NoBuyBoxCard
                                func={newBuyBox}
                                param={buyBoxes[selectedPanel - 1]}
                                key={i}
                            />
                            :
                            city.buyBoxes.map((item, index) =>
                            <BuyBoxCard
                                item={item}
                                index={index}
                                key={index}
                                buyBoxMore={buyBoxMore === item.id ? true : false}
                                setBuyBoxMore={setBuyBoxMore}
                                buyBoxFrequency={buyBoxFrequency === item.id ? true : false}
                                setBuyBoxFrequency={setBuyBoxFrequency}
                                onChangeBuyBoxFrequency={onChangeBuyBoxFrequency}
                                editBuyBox={editBuyBox}
                                deleteBuyBox={deleteBuyBox}
                                setDeleteParam={setDeleteParam}
                                freeTrial={freeTrial}
                                subscriptions={subscriptions}
                                openUpgradeModal={openUpgradeModal}
                                openStrategy={openStrategy === item.id ? true : false}
                                setOpenStrategy={setOpenStrategy}
                                onChangeBuyBoxStrategy={onChangeBuyBoxStrategy}
                                city={city}
                                loadingBuyBoxes={loadingBuyBoxes}
                            />
                            )
                            :
                            null
                        )
                    }
                </div>
            }
            {
                mobile === true && buyBoxes[selectedPanel - 1].loaded === true ?
                <div className="my-cities-mobile-add-buy-box">
                        <ActionButton
                        onClick={() => newBuyBox(buyBoxes[selectedPanel - 1])}
                    >
                        <img
                            src={PlusWhite}
                            className="my-cities-new-buy-box-arrow"
                            alt="New buy box"
                        />
                        New buy box
                    </ActionButton>
                </div>
                :
                null
            }
            {
                freeTrial === true && mobile === true ?
                <div 
                    className="one-city-buy-box-upgrade-metro-area-container text-button margin-medium"
                    onClick={() => onChangeMetroUpgradeModal()}
                >
                    <span className="body-regular colour-link">
                        🚀 Upgrade to entire county
                    </span>
                </div>
                :
                null
            }
            {
                mobile === true ?
                <div 
                    className="button-row-center"
                    onClick={() => exploreMore()}
                >
                    <span className="body-regular colour-secondary block-text subtext-margin-right">
                        Want to expand?
                    </span>
                    <span className="body-regular-underline colour-link">
                        Explore more cities
                    </span>
                </div>
                :
                null
            }
        </div>
    )
};

export default OneCityBuyBox;