import React from 'react';
import '../styles/StrategiesPanelTopLevel.css';
import { formatterLong } from '../styles/GlobalStyles';

function StrategiesPanelTopLevel(props) {
    const strategyPanel = props.strategyPanel;
    const arv = props.defaultArv;
    const rent = props.defaultRent;
    const property = props.property;

    const values = strategyPanel < 2 ? [
        {
            label: "OUR ESTIMATE",
            value: formatterLong.format(property.propertyEstimateDefault).replace(".00", "")
        },
        {
            label: "RENT",
            value: formatterLong.format(rent).replace(".00", "")
        }
    ] 
    : 
    [
        {
            label: "OUR ESTIMATE",
            value: formatterLong.format(property.propertyEstimateDefault).replace(".00", "")
        },
        {
            label: "ARV",
            value: arv === property.price ? "Unknown" : formatterLong.format(arv).replace(".00", "")
        },
        {
            label: "RENT",
            value: formatterLong.format(rent).replace(".00", "")
        }
    ];

    return (
        <div className="strategies-panel-top-level-values-outer-container">
            <div className={"strategies-panel-top-level-values-inner-container " + (strategyPanel < 2 ? "strategies-panel-top-level-double-grid" : "strategies-panel-top-level-triple-grid")}>
                {
                    values.map((item, index) =>
                        <div 
                            className="strategies-panel-top-level-value-element bg-colour-light"
                            key={index}
                        >
                            <div className="strategies-panel-top-level-value-inner-element">
                                <span className="label-regular-caps colour-secondary">
                                    {item.label}
                                </span>
                                <span className="heading-small-semibold colour-primary">
                                    {item.value}
                                </span>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
};

export default StrategiesPanelTopLevel;