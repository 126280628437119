import React from 'react';
import '../styles/NoCredits.css';
import { Header, Footer } from '../components';
import { ActionButton } from '../styles/GlobalStyles';
import { useNavigate } from 'react-router-dom';
import { recordEvent } from '../functions';

function NoCredits() {
    const navigate = useNavigate();

    const upgrade = () => {
        recordEvent("Upgrade - Unlimited Credits", {});
        navigate("/buy-credits");
    };

    return (
        <div className="no-credits-outer-container bg-colour-light">
            <Header
                subscriptions={null}
                users={null}
                queries={[true, true]}
				mobileNav={true}
            />
            <div className="no-credits-inner-container">
                <div className="no-credits-title-container margin-x-large">
                    <h1 className="heading-large-semibold colour-primary text-align-center margin-x-small">
                        You're out of credits!
                    </h1>
                    <h2 className="body-regular colour-secondary text-align-center">
                        I'm afraid you've run out of Quick Report credits.
                    </h2>
                </div>
                <div className="no-credits-button-container">
                    <ActionButton
                        onClick={() => upgrade()}
                    >
                        Get unlimited credits
                    </ActionButton>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default NoCredits;