import { useEffect } from 'react';
import { getAPIGatewayDetail, checkOnAuthStateChanged, getUserDocuments, saveNewFavourites, setMergeData, cloudFunctionV2, saveMonthlyCosts, saveInitialCosts, getSubDocument, checkUserSettingsOnCosts, calculateMortgagePayment, saveOtherCosts, checkFinancialsInteger, getAPIGatewayDetailAddress, checkPayingCustomer, checkUserDetails, sendEmail, getDocument, addCreditRemoveSearch, makeId, getWhereDoc, getIp, setData, recordEvent } from '../functions';
import { citiesForPurchase, defaultUserData } from '../styles/GlobalStyles';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

function PropertyPageEffect(props) {
    const cityId = props.cityId;
	const coreCity = citiesForPurchase.findIndex(e => e.city_id === cityId) === -1 ? false : true;
    const setProperty = props.setProperty;
    const setMobileBar = props.setMobileBar;
    const setFreeTrial = props.setFreeTrial;
    const setUserDetails = props.setUserDetails;
    const setFavourites = props.setFavourites;
    const navigate = props.navigate;
    const setBlurred = props.setBlurred;
    const id = props.id;
    const setAddBedroom = props.setAddBedroom;
	const queryMade = props.queryMade;
    const setQueryMade = props.setQueryMade;
    const setLoading = props.setLoading;
	const setNoProperty = props.setNoProperty;
	const setUserSettings = props.setUserSettings;
	const location = useLocation();
	const state = location.state;
	const origin = window.location.origin;
	const devMode = origin.includes("localhost") || window.location.hostname.includes("192.") || origin.includes("refi") ? true : false;
	const setEditedProperty = props.setEditedProperty;
	const setRecurringCosts = props.setRecurringCosts;
    const setDownPayment = props.setDownPayment;
    const setInterestRate = props.setInterestRate;
	const setSpreadsheetProperty = props.setSpreadsheetProperty;
	const setLoanYears = props.setLoanYears;
	const setDefaultCostPerSqFoot = props.setDefaultCostPerSqFoot;
	const setDefaultDownPayment = props.setDefaultDownPayment;
	const setDefaultInterestRate = props.setDefaultInterestRate;
	const setRecommendedStrategy = props.setRecommendedStrategy;
	const setPropertyTaxSetting = props.setPropertyTaxSetting;
	const setNeighbourhoodData = props.setNeighbourhoodData;
	const setTracts = props.setTracts;
	const setNeighbourhoodBounds = props.setNeighbourhoodBounds;
	const strategyPanel = props.strategyPanel;
	const setCityId = props.setCityId;
	const setCommercialProperty = props.setCommercialProperty;
	const setLoadingStep = props.setLoadingStep;
	const propertySearch = props.propertySearch;
	const setArvComps = props.setArvComps;
	const partner = props.partner;
	const partnerId = props.partnerId;
	const setStrategyPanel = props.setStrategyPanel;
	const setTotalRehabCost = props.setTotalRehabCost;
	const setCostPerSqFoot = props.setCostPerSqFoot;
	const setUserId = props.setUserId;
	const setSavedSalesCompData = props.setSavedSalesCompData;
	const setInnerWidth = props.setInnerWidth;
	const setPartnerAmendments = props.setPartnerAmendments;
	const setSavedRentCompData = props.setSavedRentCompData;

    useEffect(() => {

		// Heirarchy of values


		// GET CORE VALUES AND STORE THEM IN STATE. NEVER GET DEFAULT VALUES FROM HERE.
		// 1. Saved property values
		// 		- Set every core value

		// GET DEFAULT VALUES AND STORE THEM IN STATE. IF NO SAVED VALUES, USE THESE
		// VALUES AS CORE VALUES TOO.
		// 2. User city settings
		// 		- Monthly Costs
		// 		- Rehab

		// IF NONE OF THE ABOVE, USE GENERAL VALUES FOR STEP 2
		// 3. User general settings
		// 		- Monthly Costs
		// 		- Rehab
		// 		- Initial Costs + Loan type

		// IF NONE OF THE ABOVE, USE AWS VALUES FOR BOTH CORE VALUES
		// AND DEFAULT VALUES
		// 4. Default values from AWS
		// 		- Use these for both default values AND the values themselves

		const handleWindowResize = () => {
			if ( window.innerWidth > 1000 ) {
				document.body.removeAttribute("style");
				setMobileBar(false);
			}
			setInnerWidth(window.innerWidth);
		};
	  
		window.addEventListener('resize', handleWindowResize);

		const changeLoadingStep = (step) => {
			if ( setLoadingStep !== null ) {
				setLoadingStep(step);
			}
		};

		async function queryUser() {
			// 1. Get user data
			// 2. Check if user is allowed to view this property
			// 3. If they are allowed, get the property
			// 4. 

			document.title = "Coffee Clozers | Property";

			const user = await checkOnAuthStateChanged();
			if ( user.status === 200 ) {
				const collections = [
					"Users",
                    "Financial Preferences",
					"Subscriptions",
					"Favourites"
				];
				const userId = user.userId;
				setUserId(userId);

				const queryUser = await getUserDocuments(collections, userId);
				if ( queryUser[0].status === 200 ) {
					changeLoadingStep(2);
					const userData = queryUser[0].data;
					let userSettings;
					if ( queryUser[1].status === 200 ) {
						userSettings = queryUser[1].data;
					}
					else {
						userSettings = defaultUserData.settings;
					}
					setUserSettings(userSettings);
					checkPropertyTaxSettings(userSettings);
					
					if ( queryUser[2].status === 200 ) {
						const subscriptions = queryUser[2].data;
						const subscriptionIndex = subscriptions.findIndex(e => e.cityId === cityId && e.endDate.seconds > new Date().getTime() / 1000);
						const msaSubscriptionIndex = subscriptions.findIndex(e => e.msaCityIds !== undefined && e.msaCityIds.includes(cityId) && e.endDate.seconds > new Date().getTime() / 1000);
						const coreCitySubscription = subscriptions.findIndex(e => e.allCities === true && e.endDate.seconds > new Date().getTime() / 1000);
						const countySubscription = cityId === null ? -1 : cityId.includes("CTY") ? subscriptions.findIndex(e => e.msaCode === cityId && e.endDate.seconds > new Date().getTime() / 1000) : -1;

						if ( coreCitySubscription !== -1 && coreCity === true ) {
							setFreeTrial(false);
						}
						else if ( subscriptionIndex === -1 && msaSubscriptionIndex === -1 && countySubscription === -1 ) {
							setFreeTrial(true);
						}
						else {
							setFreeTrial(false);
						}
					}

					let newFavourites = [];
					if ( queryUser[3].status === 200 ) {
						newFavourites = queryUser[3].data;
					}
					else {
						newFavourites = userData.favourites;
						saveNewFavourites(userData.favourites, userId);
					}
					
					// Rewrite code for checking who is paid vs free user, and for
					// which city they paid for

					setUserDetails(userData);
					setFavourites(newFavourites);

					const subscriptions = queryUser[2].status === 200 ? queryUser[2].data : [];

					if ( partner === true ) {
						checkPartnerPropertyAmendments(userSettings, userId, "");
					}
					else {
						queryGatewayProperties(userSettings, userId, subscriptions);
					}
				}
			}
			else if ( partner === true ) {
				checkPartnerPropertyAmendments(defaultUserData, "", "");
				setUserSettings(defaultUserData.settings);
				setUserDetails(defaultUserData);
			}
			else {
				setUserSettings(defaultUserData.settings);
				queryGatewayProperties(defaultUserData.settings, "", []);
				setUserDetails(defaultUserData);

				if ( setBlurred !== null ) {
					setBlurred(false);
				}
			}
		}

		const checkPartnerPropertyAmendments = async(userData, userId, colRef) => {
			const partnerRef = "Partners";
            const field = "data.id";
            const operator = "==";
            const queryDocument = await getWhereDoc(partnerRef, field, operator, partnerId);
            if ( queryDocument.status === 200 ) {
                const partnerData = queryDocument.data[0];
				const partnerListings = partnerData.listings;
				const listingIndex = partnerListings.findIndex(e => e.zpid === id);
				if ( listingIndex !== -1 ) {
					const listing = partnerListings[listingIndex];
					queryPartnerProperty(userData, userId, colRef, listing.amendments, listing.address, listing, defaultUserData.settings);
					setUserDetails(userData);
				}
			}
		};

		async function queryGatewayProperties(userSettings, userId, subscriptions) {
			const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
			const address = propertySearch === true ? decodeURIComponent(id) : "";

			const getGatewayQueryString = propertySearch === true ? await getAPIGatewayDetailAddress(userSettings, address) : await getAPIGatewayDetail(cityId, id, userSettings);
			const resourceId = "detail";
			const getGatewayParams = {
				type: "gateway",
				resourceId: resourceId,
				queryString: getGatewayQueryString
			};
			const getGateway = await cloudFunctionV2(gatewayURL, getGatewayParams);
			if ( getGateway.status === 200 ) {
				const getGatewayBody = getGateway.body;
				const propertyObject = getGatewayBody.prop;

				if ( propertyObject === null ) {
					setQueryMade(true);
					const errorMessage = getGatewayBody.errorMessage;
					setLoading(false);

					if ( errorMessage === "Property type invalid: Apartment, likely Commercial property type" && setCommercialProperty !== null ) {
						setCommercialProperty(true);
					}
					else {
						setNoProperty(true);
					}
				}
				else {
					changeLoadingStep(4);

					if ( propertySearch === true ) {
						if ( getGatewayBody.inProd === true ) {
							const zpid = propertyObject.zpid;
							const newCityId = propertyObject.cityId;
							
							const subscriptionIndex = subscriptions.findIndex(e => e.cityId === newCityId && e.endDate.seconds > new Date().getTime() / 1000);
							const msaSubscriptionIndex = subscriptions.findIndex(e => e.msaCityIds !== undefined && e.msaCityIds.includes(newCityId) && e.endDate.seconds > new Date().getTime() / 1000);
							const coreCitySubscription = subscriptions.findIndex(e => e.allCities === true && e.endDate.seconds > new Date().getTime() / 1000);
							const newCoreCityCheck = citiesForPurchase.findIndex(e => e.city_id === newCityId) === -1 ? false : true;

							if ( coreCitySubscription !== -1 && newCoreCityCheck === true ) {
								setNoProperty(false);
								addCreditRemoveSearch(userId, propertyObject);
								navigate(`/properties/${newCityId}/${zpid}`, {
									state: state
								});
								return;
							}
							else if ( subscriptionIndex !== -1 || msaSubscriptionIndex !== -1 ) {
								setNoProperty(false);
								addCreditRemoveSearch(userId, propertyObject);
								navigate(`/properties/${newCityId}/${zpid}`, {
									state: state
								});
								return;
							}
						}

						const colRef = userId === "" ? "IP Addresses" : "Property Searches";
						let docRef = userId === "" && state !== null ? state.noUserId : userId === "" ? "" : userId;
						let searches = [];
						let credits = 5;

						if ( docRef === "" ) {
							const queryIp = await getIp();
							const ipAddress = queryIp.error !== true ? queryIp.ip : "";
							if ( ipAddress === "" ) {
								getOutOfHere();
							}
							else {
								const field = "data.ipAddress";
								const operator = "==";
								const val = ipAddress;
								const getUserDocument = await getWhereDoc(colRef, field, operator, val);
								if ( getUserDocument.status === 200 ) {
									const ipUserData = getUserDocument.data[0];
									if ( ipUserData.searches === undefined ) {
										ipUserData.searches = ipUserData.propertySearches;
										delete ipUserData.propertySearches;
									}

									credits = ipUserData.credits;
									searches = ipUserData.searches;
									docRef = ipUserData.id;
								}
								else {
									credits = 3;
									searches = [];

									const userCity = queryIp.city;
									const userRegion = queryIp.region;
									const userCountry = queryIp.country;
									const userLocation = {
										city: userCity,
										region: userRegion,
										country: userCountry
									};

									const randomId = await makeId(15);
									const defaultData = {
										ipAddress: ipAddress,
										userLocation: userLocation,
										credits: 3,
										searches: [],
										id: randomId
									};
									await setData(colRef, randomId, defaultData);
									docRef = randomId;
								}
							}
						}
						else {
							const getPropertySearches = await getDocument(colRef, docRef);
							if ( getPropertySearches.status === 200 ) {
								const getPropertySearchesData = getPropertySearches.data.data;
								if ( getPropertySearchesData.propertySearches !== undefined )  {
									searches = getPropertySearchesData.propertySearches;
								}
								else {
									searches = getPropertySearchesData.searches;
								}
								credits = getPropertySearchesData.credits;
							}
							else {
								getOutOfHere();
							}
						}
						if ( docRef !== "" ) {
							await writeZPID(colRef, docRef, credits, propertyObject, searches);
						}
					}

					if ( setCityId !== null && propertyObject.cityId !== undefined && propertyObject.cityId !== null ) {
						setCityId(propertyObject.cityId);
					}
					
					if ( propertyObject.dripScoreOptions === undefined ) {
						navigate(`/old-properties/${propertyObject.cityId}/${propertyObject.zpid}`);
						return;
					}
	
					runValuesHierarchy(userId, userSettings, propertyObject);
	
					propertyObject.datePostedTS = new Date(propertyObject.datePosted);
					propertyObject.offMarket = propertyObject.motivatedSeller === undefined ? false : true;
	
					const checkAddBed = propertyObject.addBedOpportunity;
					setAddBedroom(checkAddBed);
	
					const a = moment(new Date());
					const b = moment(propertyObject.datePostedTS);
					const dateDifference = a.diff(b, 'days');
					propertyObject.dateDifference = dateDifference;
	
					if ( propertyObject.uniqueUnitPropertyDetails !== undefined && propertyObject.uniqueUnitPropertyDetails !== null && propertyObject.uniqueUnitPropertyDetails.length > 1 ) {
						for (let index = 0; index < propertyObject.uniqueUnitPropertyDetails.length; index++) {
							propertyObject.uniqueUnitPropertyDetails[index].value = propertyObject.uniqueUnitPropertyDetails[index].rentcast_rent;
						}
					}
	
					if ( propertyObject.rentComps !== null && propertyObject.rentComps !== undefined ) {
						propertyObject.rentComps.sort((a,b) => (a.prediction_label < b.prediction_label) ? 1 : ((b.prediction_label < a.prediction_label) ? -1 : 0));
					}
	
					if ( propertyObject.units !== undefined && propertyObject.units !== null && propertyObject.units > 4 ) {
						propertyObject.units = 4;
					}
	
					if ( propertyObject.unitPropertyComps !== null && propertyObject.unitPropertyComps !== undefined ) {
						if ( propertyObject.unitPropertyComps.length === 1 ) {
							propertyObject.unitPropertyComps[0].sort((a,b) => (a.correlation < b.correlation) ? 1 : ((b.correlation < a.correlation) ? -1 : 0));
						}
						else if ( propertyObject.unitPropertyComps.length > 1 ) {
							propertyObject.unitPropertyComps[0].sort((a,b) => (a.correlation < b.correlation) ? 1 : ((b.correlation < a.correlation) ? -1 : 0));
							propertyObject.unitPropertyComps[1].sort((a,b) => (a.correlation < b.correlation) ? 1 : ((b.correlation < a.correlation) ? -1 : 0));
						}
					}
	
					if ( propertyObject.imgSrc === null || propertyObject.imgSrc === undefined || propertyObject.imgSrc.includes("maps") ) {
						propertyObject.offMarketImage = true;
					}
					else {
						propertyObject.offMarketImage = false;
					}
	
					if ( propertyObject.arvComps === true && propertyObject.arvCompsSubjProp !== null ) {
						propertyObject.arvCompsSubjProp = propertyObject.arvCompsSubjProp[0];
					}

					const newArvComps = [];
					if ( propertyObject.arvCompsSimilarProp !== undefined && propertyObject.arvCompsSimilarProp !== null ) {
						for (let index = 0; index < propertyObject.arvCompsSimilarProp.length; index++) {
							const element = propertyObject.arvCompsSimilarProp[index];
							if ( element.zpid === undefined || element.zpid === null ) {
								const madeUpZPID = await makeId(10)
								element.zpid = `MDP-${madeUpZPID}`;
							};
							newArvComps.push(element);
						}
					}
					setArvComps(newArvComps);
	
					if ( propertyObject.recommendedStrategy !== undefined ) {
						const options = [
							"traditional",
							"houseHacking",
							"brrrr",
							"fixAndFlip"
						];
						const dripScroreOptionIndex = options.indexOf(propertyObject.recommendedStrategy);
						if ( dripScroreOptionIndex !== -1 ) {
							setRecommendedStrategy(dripScroreOptionIndex);
						}
					}

					if ( propertyObject.propertyTypeDimension === "Multi Family" && propertyObject.units !== undefined && propertyObject.units === null ) {
						propertyObject.units = 2;
						propertyObject.unitsGuess = true;
					}
	
					document.title = `Coffee Clozers | ${propertyObject.address.streetAddress}, ${propertyObject.address.city}, ${propertyObject.address.state} ${propertyObject.address.zipcode}`;
					const getCountyId = propertyObject.countyId !== undefined && propertyObject.countyId !== null ? propertyObject.countyId : null;
					getNeighbourhoodData(getCountyId, propertyObject);
					setProperty(propertyObject);
					setSpreadsheetProperty(propertyObject);
					setQueryMade(true);
					setLoading(false);
				}
			}
			else if ( getGateway.status === 405 ) {
				setLoading(false);
				setNoProperty(true);

				if ( propertySearch === true ) {
					// Remove search from user data
					const colRef = userId === "" ? "IP Addresses" : "Property Searches";
					const docRef = userId === "" && state !== null ? state.noUserId : userId === "" ? "" : userId;
					const getPropertySearches = userId === "" ? {status: 400} : await getDocument(colRef, docRef);
					if ( getPropertySearches.status === 200 ) {
						const getPropertySearchesData = getPropertySearches.data.data;
						const searches = getPropertySearchesData.searches;
						const searchIndex = searches.findIndex(e => e.address === id);
						if ( searchIndex !== -1 ) {
							searches.splice(searchIndex, 1);
							const data = {
								searches: searches,
								credits: getPropertySearchesData.credits + 1
							}
							await setMergeData(colRef, userId, data);
						}
					}

					const recipientEmail = ["liam.maher@coffeeclozers.com", "ariel.herrera@coffeeclozers.com"];
					const senderEmail = "liam.maher@coffeeclozers.com";
					const dynamicTemplate = "d-439e19e4be614abe847e1019e89b43a2";
					const getPayingCustomer = await checkPayingCustomer();
					const getUserDetails = await checkUserDetails();
					const getUser = getUserDetails.status === 200 ? getUserDetails.data : null;
					const errorObject = {
						name: getUser === null ? "" : `${getUser.firstName} ${getUser.lastName}`,
						email: getUser === null ? "" : getUser.email,
						userId: userId,
						address: address,
						payingUser: getPayingCustomer
					};

					const msg = {
						to: recipientEmail,
						from: senderEmail,
						templateId: dynamicTemplate,
						dynamic_template_data: errorObject
					};
					await sendEmail(msg);
				}
			}
			else {
				navigate("/404", {
					state: state
				});
			}
		};

		const queryPartnerProperty = async(userData, userId, colRef, partnerAmendments, partnerAddress, listing, userSettings) => {
			setPartnerAmendments(partnerAmendments);
			const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
			const getGatewayQueryString = await getAPIGatewayDetailAddress(userSettings, partnerAddress);
			const resourceId = "detail";
			const getGatewayParams = {
				type: "gateway",
				resourceId: resourceId,
				queryString: getGatewayQueryString
			};
			const getGateway = await cloudFunctionV2(gatewayURL, getGatewayParams);
			if ( getGateway.status === 200 && getGateway.body.prop !== null ) {
				const getGatewayBody = getGateway.body;
				const propertyObject = getGatewayBody.prop;
				propertyObject.propertySearch = false;

				if ( partnerAmendments.arvCompsStats !== null ) {
					propertyObject.arvCompsStats = partnerAmendments.arvCompsStats;
					propertyObject.arvComps = true;
					propertyObject.arvCompsSimilarProp = partnerAmendments.arvCompsSimilarProp;
				}
	
				if ( listing !== null ) {
					propertyObject.status = listing.status;
				}
	
				if ( partnerAmendments.price !== null ) {
					propertyObject.price = partnerAmendments.price;
				}
	
				if ( partnerAmendments.customImages !== undefined && partnerAmendments.customImages !== null ) {
					const newImageArray = [];
					const customImages = partnerAmendments.customImages;
					for (let index = 0; index < customImages.length; index++) {
						const element = customImages[index];
						newImageArray.push(element.url)
					}
					propertyObject.images = newImageArray;
					if ( newImageArray.length !== 0 ) {
						propertyObject.imgSrc = newImageArray[0];
					}
				}
	
				if ( partnerAmendments.rent !== null ) {
					propertyObject.financials.oldRent = propertyObject.financials.rent;
					propertyObject.financials.rent = partnerAmendments.rent;
				}
	
				if ( partnerAmendments.arv !== null ) {
					if ( propertyObject.arvCompsStats === undefined || propertyObject.arvCompsStats === null ) {
						propertyObject.arvCompsStats = {
							arvPrice: partnerAmendments.arv
						};
					}
					else {
						propertyObject.arvCompsStats.arvPrice = partnerAmendments.arv;
					}
				}
	
				if ( partnerAmendments.description !== null ) {
					propertyObject.description = partnerAmendments.description;
				}
	
				if ( partnerAmendments.bedrooms !== null ) {
					propertyObject.bedrooms = partnerAmendments.bedrooms;
				}
	
				if ( partnerAmendments.bathrooms !== null ) {
					propertyObject.bathrooms = partnerAmendments.bathrooms;
				}
	
				if ( partnerAmendments.livingArea !== null ) {
					propertyObject.livingArea = partnerAmendments.livingArea;
				}
	
				if ( partnerAmendments.propertyTypeDimension !== null ) {
					propertyObject.propertyTypeDimension = partnerAmendments.propertyTypeDimension;
				}
	
				if ( partnerAmendments.title !== undefined && partnerAmendments.title !== null ) {
					propertyObject.title = partnerAmendments.title;
				}
	
				if ( partnerAmendments.message !== undefined && partnerAmendments.message !== null ) {
					propertyObject.message = partnerAmendments.message;
				}
	
				if ( partnerAmendments.rentalComps !== undefined && partnerAmendments.rentalComps !== null ) {
					propertyObject.rentComps = partnerAmendments.rentalComps;
				}
	
				if ( partnerAmendments.downPayment !== undefined && partnerAmendments.downPayment !== null && userId === "" ) {
					setDownPayment(partnerAmendments.downPayment);
				}
	
				if ( partnerAmendments.interestRate !== undefined && partnerAmendments.interestRate !== null && userId === "" ) {
					setInterestRate(partnerAmendments.interestRate);
				}

				if ( partnerAmendments.strategy !== undefined && partnerAmendments.strategy !== null ) {
					const options = [
						"traditional",
						"houseHacking",
						"brrrr",
						"fixAndFlip"
					];
					const dripScroreOptionIndex = options.indexOf(partnerAmendments.strategy);
					if ( dripScroreOptionIndex !== -1 ) {
						setRecommendedStrategy(dripScroreOptionIndex);
						setStrategyPanel(dripScroreOptionIndex);
					}
				}
	
				if ( partnerAmendments.totalRehabCost !== undefined && partnerAmendments.totalRehabCost !== null ) {
					setTotalRehabCost(partnerAmendments.totalRehabCost);
					if ( propertyObject.livingArea !== null ) {
						setCostPerSqFoot(partnerAmendments.totalRehabCost / propertyObject.livingArea);
						setDefaultCostPerSqFoot(partnerAmendments.totalRehabCost / propertyObject.livingArea);
					}
				}

				if ( setCityId !== null && propertyObject.cityId !== undefined && propertyObject.cityId !== null ) {
					setCityId(propertyObject.cityId);
				}

				runValuesHierarchy(userId, userSettings, propertyObject);

				propertyObject.datePostedTS = new Date(propertyObject.datePosted);
				const checkAddBed = propertyObject.addBedOpportunity;
				setAddBedroom(checkAddBed);

				const a = moment(new Date());
				const b = moment(propertyObject.datePostedTS);
				const dateDifference = a.diff(b, 'days');
				propertyObject.dateDifference = dateDifference;

				if ( propertyObject.uniqueUnitPropertyDetails !== undefined && propertyObject.uniqueUnitPropertyDetails !== null && propertyObject.uniqueUnitPropertyDetails.length > 1 ) {
					for (let index = 0; index < propertyObject.uniqueUnitPropertyDetails.length; index++) {
						propertyObject.uniqueUnitPropertyDetails[index].value = propertyObject.uniqueUnitPropertyDetails[index].rentcast_rent;
					}
				}

				if ( propertyObject.rentComps !== null && propertyObject.rentComps !== undefined ) {
					propertyObject.rentComps.sort((a,b) => (a.prediction_label < b.prediction_label) ? 1 : ((b.prediction_label < a.prediction_label) ? -1 : 0));
				}

				if ( propertyObject.units !== undefined && propertyObject.units !== null && propertyObject.units > 4 ) {
					propertyObject.units = 4;
				}

				if ( propertyObject.unitPropertyComps !== null && propertyObject.unitPropertyComps !== undefined ) {
					if ( propertyObject.unitPropertyComps.length === 1 ) {
						propertyObject.unitPropertyComps[0].sort((a,b) => (a.correlation < b.correlation) ? 1 : ((b.correlation < a.correlation) ? -1 : 0));
					}
					else if ( propertyObject.unitPropertyComps.length > 1 ) {
						propertyObject.unitPropertyComps[0].sort((a,b) => (a.correlation < b.correlation) ? 1 : ((b.correlation < a.correlation) ? -1 : 0));
						propertyObject.unitPropertyComps[1].sort((a,b) => (a.correlation < b.correlation) ? 1 : ((b.correlation < a.correlation) ? -1 : 0));
					}
				}

				if ( propertyObject.imgSrc === null || propertyObject.imgSrc === undefined || propertyObject.imgSrc.includes("maps") ) {
					propertyObject.offMarketImage = true;
				}
				else {
					propertyObject.offMarketImage = false;
				}

				if ( propertyObject.arvComps === true && propertyObject.arvCompsSubjProp !== null ) {
					propertyObject.arvCompsSubjProp = propertyObject.arvCompsSubjProp[0];
				}

				const newArvComps = [];
				if ( propertyObject.arvCompsSimilarProp !== undefined && propertyObject.arvCompsSimilarProp !== null ) {
					for (let index = 0; index < propertyObject.arvCompsSimilarProp.length; index++) {
						const element = propertyObject.arvCompsSimilarProp[index];
						if ( element.zpid === undefined || element.zpid === null ) {
							const madeUpZPID = await makeId(10)
							element.zpid = `MDP-${madeUpZPID}`;
						};
						newArvComps.push(element);
					}
				}
				setArvComps(newArvComps);

				if ( propertyObject.propertyTypeDimension === "Multi Family" && propertyObject.units !== undefined && propertyObject.units === null ) {
					propertyObject.units = 2;
					propertyObject.unitsGuess = true;
				}

				document.title = `Coffee Clozers | ${propertyObject.address.streetAddress}, ${propertyObject.address.city}, ${propertyObject.address.state} ${propertyObject.address.zipcode}`;
				const getCountyId = propertyObject.countyId !== undefined && propertyObject.countyId !== null ? propertyObject.countyId : null;
				getNeighbourhoodData(getCountyId, propertyObject);
				setProperty(propertyObject);
				setQueryMade(true);
				setLoading(false);
				setSpreadsheetProperty(propertyObject);
			}
		};

		const getNeighbourhoodData = async(getCountyId, propertyObject) => {
			const checkedCityId = getCountyId === null ? cityId : getCountyId;
			if ( checkedCityId === null ) {
				return;
			}

			const neighbourhoodPath = checkedCityId.includes("CTY") || getCountyId !== null ? `geo_output/map_neighborhood/county/${checkedCityId.replace("CTY", "")}.json` : `output/map_neighborhood/${checkedCityId}.json`
			const params = {
				s3Data: {
					key: neighbourhoodPath,
					bucketName: "residentialpropertydata"
				},
				type: "s3"
			};
			const awsURL = process.env.REACT_APP_AWS_QUERY_URL;
			changeLoadingStep(4);
			const queryData = await cloudFunctionV2(awsURL, params);
			if ( queryData.status === 200 ) {
				const body = queryData.body;
				setNeighbourhoodData(body);

				const newBounds = [];
				const getTracts = [];
				for (let index = 0; index < body.length; index++) {
					const element = body[index];
					getTracts.push(element.region.tractId);
					const tractPolygon = element.tractPolygon.flat(1);
					newBounds.push(tractPolygon);
				};
				setTracts(getTracts);
				setNeighbourhoodBounds(newBounds);
			}
			else if ( propertyObject.awsMapLink !== undefined && propertyObject.awsMapLink !== null ) {
				const newParams = {
					s3Data: {
						key: propertyObject.awsMapLink,
						bucketName: "residentialpropertydata"
					},
					type: "s3"
				};
				const awsURL = process.env.REACT_APP_AWS_QUERY_URL;
				const queryAWSMap = await cloudFunctionV2(awsURL, newParams);
				if ( queryAWSMap.status === 200 ) {
					const body = queryAWSMap.body;
					setNeighbourhoodData(body);
	
					const newBounds = [];
					const getTracts = [];
					for (let index = 0; index < body.length; index++) {
						const element = body[index];
						getTracts.push(element.region.tractId);
						const tractPolygon = element.tractPolygon.flat(1);
						newBounds.push(tractPolygon);
					};
					setTracts(getTracts);
					setNeighbourhoodBounds(newBounds);
				}
			}
		}

		const runValuesHierarchy = async(userId, userSettings, propertyObject) => {
			if ( userId !== "" && partner === false ) {
				// User logged in
				fetchSavedProperty(userId,  userSettings, propertyObject);
			}
			else {
				// User logged out
				saveMonthlyCosts(propertyObject, setRecurringCosts, strategyPanel, defaultUserData.settings);
				saveInitialCosts(userSettings, propertyObject, setDownPayment, setInterestRate, setDefaultDownPayment, setDefaultInterestRate, strategyPanel);
				saveOtherCosts(userSettings, propertyObject, cityId, setDefaultCostPerSqFoot);
				setEditedProperty(null);
			}
		};

		const fetchSavedProperty = async(userId, userSettings, propertyObject) => {
			const colRef = "Users";
			const docRef = userId;
			const subColRef = "Strategy Properties";
			const subDocRef = propertyObject.zpid;
			const queryDocument = await getSubDocument(colRef, docRef, subColRef, subDocRef);
			if ( queryDocument.status === 200 ) {
				// Get saved property values
				const data = queryDocument.data.data;
				const savedMonthlyCosts = data.monthlyCosts;
				const customPrice = data.price;
				const customArv = data.arv;

				if ( data.salesCompData !== undefined ) {
					setSavedSalesCompData(data.salesCompData);
				}

				if ( data.rentCompData !== undefined ) {
					setSavedRentCompData(data.rentCompData);
				}

				// Check which rent estimate we are using
				const relevantRent = propertyObject.uniqueUnitPropertyDetails !== undefined && propertyObject.uniqueUnitPropertyDetails !== null && propertyObject.uniqueUnitPropertyDetails.length > 0 ? data.multiUnitRentTotal : data.rent;

				// Collect appropriate monthly costs
				for (let index = 0; index < savedMonthlyCosts.length; index++) {
					const element = savedMonthlyCosts[index];
					for (let ii = 0; ii < element.inputs.length; ii++) {
						const input = element.inputs[ii];
						// Cross check with user settings
						const userSettingOriginal = await checkUserSettingsOnCosts(input, userSettings, cityId, index, ii, customPrice, relevantRent, customArv);
						if ( userSettingOriginal.change === true ) {
							// Values based on user settings
							savedMonthlyCosts[index].inputs[ii].original = Number(userSettingOriginal.original.toFixed(2));

							if ( index === 0 && ii === 3 ) {
								savedMonthlyCosts[index].inputs[ii].brrrrOriginal = Number(userSettingOriginal.brrrrOriginal.toFixed(2));
							}
						}
						else {
							// If there is no user setting value, get the original value from AWS
							let relevantValue;
							if ( index === 0 && ii === 1 ) {
								relevantValue = propertyObject.financials.hoaFees === null ? 0 : checkFinancialsInteger(propertyObject.financials.hoaFees);
							}
							else if ( index === 0 && ii === 2 ) {
								relevantValue = checkFinancialsInteger(propertyObject.financials.insurance);
							}
							else if ( index === 0 && ii === 3 ) {
								relevantValue = checkFinancialsInteger(propertyObject.financials.propertyTaxes);
							}
							else if ( index === 1 && ii === 0 ) {
								relevantValue = checkFinancialsInteger(propertyObject.financials.vacancyRateAllocation);
							}
							else if ( index === 1 && ii === 1 ) {
								relevantValue = checkFinancialsInteger(propertyObject.financials.managementFee);
							}
							else if ( index === 2 && ii === 0 ) {
								relevantValue = checkFinancialsInteger(propertyObject.financials.maintenanceFee);
							}
							else if ( index === 2 && ii === 1 ) {
								relevantValue = 0;
							}
							else {
								relevantValue = input.value;
							}
							savedMonthlyCosts[index].inputs[ii].original = relevantValue;
							if ( index === 0 && ii === 3 ) {
								const brrrrTaxRate = propertyObject.apiFinancialParams.propertyTaxRate;
								const brrrrPropertyTax = (((brrrrTaxRate / 100) * customArv) / 12).toFixed(2);
								savedMonthlyCosts[index].inputs[ii].brrrrOriginal = brrrrPropertyTax;
							}
						}
					}
				};
				data.monthlyCosts = savedMonthlyCosts;
				const checkDp = userSettings.initialCosts[0].value;
				if ( checkDp !== "" ) {
					setDefaultDownPayment(Number(checkDp));
				}
				const checkInterest = userSettings.initialCosts[1].value;
				const intDefault = userSettings.initialCosts[1].default;
				if ( checkInterest !== "" && intDefault === false ) {
					setDefaultInterestRate(Number(checkInterest));
				}
				else {
					// If there is no financial setting default interest rate, use
					// the one in the property object

					const propertyInterest = propertyObject.mortgage30us;
					setDefaultInterestRate(propertyInterest);
				}
				const loanType = Number(userSettings.loanType);
				setLoanYears(loanType);

				// Calculate new mortgage payment
				let newMortgage;
				if ( data.strategy === 2 ) {
					const amountBorrowedInverse = 100 - Number(data.arvFinancingPercentage);
					newMortgage = await calculateMortgagePayment(Number(data.arv), amountBorrowedInverse, Number(data.interestRate), loanType);
				}
				else {
					newMortgage = await calculateMortgagePayment(data.price, data.downPayment, data.interestRate, loanType);
				}

				if ( data.monthlyCosts.length > 0 ) {
					data.monthlyCosts[0].inputs[0].value = Number(newMortgage.toFixed(2));
				}
				
				// Save default rehab costs
				saveOtherCosts(userSettings, propertyObject, cityId, setDefaultCostPerSqFoot);

				setEditedProperty(data);
			}
			else {
				// If there is no saved property, we can assign our default values here
				saveMonthlyCosts(propertyObject, setRecurringCosts, strategyPanel, userSettings);
				saveInitialCosts(userSettings, propertyObject, setDownPayment, setInterestRate, setDefaultDownPayment, setDefaultInterestRate, strategyPanel);
				saveOtherCosts(userSettings, propertyObject, cityId, setDefaultCostPerSqFoot);
				setEditedProperty(null);
			}
		};

		const checkPropertyTaxSettings = (userSettings) => {
			let found = false;
			const customCities = userSettings.customCities;
			const customCityIndex = customCities.findIndex(e => (e.cityId !== undefined && e.cityId === cityId) || (e.metroArea === true && e.msaCode === cityId));
			if ( customCityIndex !== -1 ) {
				const customCity = customCities[customCityIndex];
				const customCitySettings = customCity.settings;
				const propertyTaxSetting = customCitySettings.findIndex(e => e.type === "property-taxes");
				if ( propertyTaxSetting !== -1 ) {
					const propertyTaxValue = customCitySettings[propertyTaxSetting].value;
					found = true;
					setPropertyTaxSetting(Number(propertyTaxValue));
				}
			}

			if ( found === false ) {
				const recurringCosts = userSettings.recurringCosts;
				const propertyTaxSetting = recurringCosts[1];
				if ( propertyTaxSetting.default === false && propertyTaxSetting.value !== "" ) {
					setPropertyTaxSetting(Number(propertyTaxSetting.value));
				}
			}
		};

		const getOutOfHere = () => {
			recordEvent("No Credits Left", {

			});
			navigate("/no-credits", {
				state: state
			});
		};

		const writeZPID = async(colRef, userId, credits, propertyObject, searches) => {
			if ( credits === 0 ) {
				getOutOfHere();
			}
			else {
				const searchIndex = searches.findIndex(e => e.address === id);
				const zpidSearchIndex = searches.findIndex(e => e.zpid === propertyObject.zpid);
				if ( searchIndex === -1 && zpidSearchIndex === -1 ) {
					const fullAddress = `${propertyObject.address.streetAddress}, ${propertyObject.address.city}, ${propertyObject.address.state} ${propertyObject.address.zipcode}, USA`;
					const encodedAddress = encodeURIComponent(fullAddress);
					const newSearchObject = {
						address: fullAddress,
						brokenDownAddress: {
							streetAddress: propertyObject.address.streetAddress,
							city: propertyObject.address.city,
							state: propertyObject.address.state
						},
						encodedAddress: encodedAddress,
						date: new Date(),
						zpid: propertyObject.zpid
					};
					searches.push(newSearchObject);
					const newCredits = credits - 1;
					const data = {
						searches: searches,
						credits: newCredits
					};
					await setMergeData(colRef, userId, data);
				}
				else {
					const relevantIndex = searchIndex === -1 ? zpidSearchIndex : searchIndex;
					const searchObject = searches[relevantIndex];
					if ( searchObject.zpid !== propertyObject.zpid ) {
						searchObject.zpid = propertyObject.zpid;
						const data = {
							searches: searches,
							credits: credits
						}
						await setMergeData(colRef, userId, data);
					}
				}
			}
		};

		if ( queryMade === false ) {
			changeLoadingStep(1);
			queryUser();
		}
		
	}, [
		cityId,
		devMode,
		id,
		navigate,
		queryMade,
		setAddBedroom,
		setBlurred,
		setFavourites,
		setFreeTrial,
		setLoading,
		setMobileBar,
		setProperty,
		setQueryMade,
		setUserDetails,
		setUserSettings,
		state,
		setNoProperty,
		setRecurringCosts,
		setEditedProperty,
		setDownPayment,
		setInterestRate,
		setSpreadsheetProperty,
		setLoanYears,
		setDefaultCostPerSqFoot,
		setDefaultDownPayment,
		setDefaultInterestRate,
		setRecommendedStrategy,
		setPropertyTaxSetting,
		setNeighbourhoodBounds,
		setTracts,
		setNeighbourhoodData,
		coreCity,
		strategyPanel,
		propertySearch,
		setCityId,
		setCommercialProperty,
		setLoadingStep,
		setArvComps,
		partner,
		partnerId,
		setStrategyPanel,
		setCostPerSqFoot,
		setTotalRehabCost,
		setUserId,
		setSavedSalesCompData,
		setInnerWidth,
		setPartnerAmendments,
		setSavedRentCompData
	]);
}

export default PropertyPageEffect;