import React, { useEffect, useState } from 'react';
import '../styles/BuyBoxMetroCity.css';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CustomSelect, colour } from '../styles/GlobalStyles';

function BuyBoxMetroCity(props) {
    const buyBoxCity = props.buyBoxCity;
    const metroCities = buyBoxCity.metroCities;
    const buyBoxMetroCity = props.buyBoxMetroCity;
    const onChangeMetroCity = props.onChangeMetroCity;
    const [options, setOptions] = useState([]);

    useEffect(() => {

        if ( options.length === 0 ) {
            const newArray = [];
            for (let index = 0; index < metroCities.length; index++) {
                const element = metroCities[index];
                const newObject = {
                    text: `${element.city}, ${element.state}`,
                    selected: false,
                    cityId: element.cityId
                };
                newArray.push(newObject);
            };

            const sortedItems = [...newArray].sort((a, b) => {
                return a.text.localeCompare(b.text);
            });

            const allObject = {
                text: "All cities",
                selected: true,
                cityId: "all"
            };
            sortedItems.unshift(allObject);
            setOptions(sortedItems);
        }
    }, [metroCities, options.length]);

    const changeMetroCity = (val) => {
        onChangeMetroCity(val);
    };

    const styles = {
		menuItem: {
			backgroundColor: colour.grayScaleWhite,
			fontFamily: 'Rubik'
		}
    };

    return (
        <div className="buy-box-metro-city-outer-container relative-container">
            <span className="body-semibold colour-primary block-text margin-x-small">
                City
            </span>
            <Box>
                <FormControl fullWidth>
                    <Select
                        labelId="min-select-label"
                        id="buy-box-metro-select"
                        value={buyBoxMetroCity}
                        label=""
                        onChange={(event) => changeMetroCity(event.target.value)}
                        input={<CustomSelect />}
                    >
                        {
                            options.map((item, index) =>
                                <MenuItem 
                                    key={index}
                                    value={item.cityId}
                                    style={styles.menuItem}
                                >
                                    {item.text}
                                </MenuItem>	
                            )
                        }
                    </Select>
                </FormControl>
            </Box>
        </div>
    )
};

export default BuyBoxMetroCity;