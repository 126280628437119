import React, { forwardRef, useState } from 'react';
import '../styles/MobilePropertyCardOverlay.css';
import Heart from '../assets/heart.svg';
import { toggleFavourite, markPropertyViewed } from '../functions/index';
import { useNavigate } from 'react-router-dom';
import { NewPropertyCharacteristics, OffMarketBadge } from './';
import ThickExit from '../assets/thick-exit.svg';
import { HeartActiveBlue } from '../assets';
import { recordEvent } from '../functions/index';

const MobileOffMarketCardOverlay = forwardRef((props, ref) => {
	const item = props.item;
	const userDetails = props.userDetails;
	const formatter = props.formatter;
	const abstract = props.abstract;
	const viewedProperties = props.viewedProperties;
	const setViewedProperties = props.setViewedProperties;
	const setMobileAbtract = props.setMobileAbtract;
	const cityId = props.cityId;
	const cityObject = {
		cityId: cityId,
		city: item.address.city,
		state: item.address.state
	}
	const propertyViewed = viewedProperties.findIndex(e => e.zpid === item.zpid) === -1 ? false : true;
	const [favourites, setFavourites] = useState(userDetails === null ? [] : props.userDetails.favourites !== undefined ? props.userDetails.favourites : []);
	const [favouriteDisable, setFavouriteDisable] = useState(false);
	const navigate = useNavigate();

    const motivatedSeller = [
        {
            flag: item.motivatedSeller.absentee_flag === 0 ? false : true,
            icon: "🫥",
            label: "Absentee owner"
        },
        {
            flag: item.motivatedSeller.auction_flag === 0 ? false : true,
            icon: "🪧",
            label: "Auction"
        },
        {
            flag: item.motivatedSeller.death_flag === 0 ? false : true,
            icon: "🕊️",
            label: "Recent death"
        },
        {
            flag: item.motivatedSeller.free_clear_flag === 0 ? false : true,
            icon: "🧾",
            label: "Free & Clear"
        },
        {
            flag: item.motivatedSeller.high_equity_flag === 0 ? false : true,
            icon: "🐷🏦",
            label: "High Equity"
        },
        {
            flag: item.motivatedSeller.in_state_absentee_owner_flag === 0 ? false : true,
            icon: "😶‍🌫️",
            label: "In-State Absentee owner"
        },
        {
            flag: item.motivatedSeller.inherited_flag === 0 ? false : true,
            icon: "🫴",
            label: "Inherited"
        },
        {
            flag: item.motivatedSeller.lien_flag === 0 ? false : true,
            icon: "🤝",
            label: "Lien"
        },
        {
            flag: item.motivatedSeller.multiple_mortgages_flag === 0 ? true : false,
            icon: "💰",
            label: "Multiple Mortgages"
        },
        {
            flag: item.motivatedSeller.owner_occupied_flag === 0 ? true : false,
            icon: "🧓🏠",
            label: "Owner Occupied"
        },
        {
            flag: item.motivatedSeller.pre_foreclosure_flag === 0 ? false : true,
            icon: "📉",
            label: "Pre-Foreclosure"
        },
        {
            flag: item.motivatedSeller.spousal_death_flag === 0 ? false : true,
            icon: "💍🕊️",
            label: "Spousal Death"
        },
        {
            flag: item.motivatedSeller.tax_lien_flag === 0 ? false : true,
            icon: "🗂️🤝",
            label: "Tax Lien"
        },
        {
            flag: item.motivatedSeller.vacant_flag === 0 ? false : true,
            icon: "🏚️",
            label: "Vacant"
        }
    ];

	const viewProperty = async(zpid) => {
		const formattedAddress = `${item.address.streetAddress.replaceAll(" ", "-")},${item.address.city},${item.address.state}-${item.address.zipcode}`;
		window.open(`/properties/${formattedAddress}/${zpid}`, '_blank');

		const newObject = {
			zpid: zpid,
			date: new Date()
		}
		const newArray = viewedProperties.slice(0);
		newArray.push(newObject);
		setViewedProperties(newArray);
		await markPropertyViewed(zpid);

		const path = window.location.pathname;

		recordEvent("Navigation", {
			oldRoute: path,
			newRoute: `/properties/${formattedAddress}/${zpid}`
		});

		recordEvent("Property Click", {
			zpid: zpid,
			cityId: cityId
		});
	};

	return (
		<div className={"mobile-property-card-overlay-outer-container " + (abstract === false && propertyViewed === true ? "mobile-property-card-overlay-viewed-property" : "")}>
			<div className="mobile-property-card-overlay-inner-container">
				<div className="mobile-property-card-overlay-off-market-left-container">
					<div 
						className="mobile-property-card-overlay-off-market-tags-container"
						onClick={() => viewProperty(item.zpid)}
					>
                        {
                            motivatedSeller.map((subItem, i) =>
                                subItem.flag === true && i <= item.motivatedSellerFourthIndex ?
                                <OffMarketBadge
                                    emoji={subItem.icon}
                                    text={subItem.label}
                                    key={i}
                                />
                            :
                            null
                            )
                        }
                        {
                            item.motivatedSellerCount > 4 ?
                            <div className="off-market-extra-badges-container">
                                <span className="body-semibold text-link underline block-text">
                                    +{item.motivatedSellerCount - 2}
                                </span>
                            </div>
                            :
                            null
                        }
					</div>
					<div 
						className="mobile-property-card-overlay-exit-outer-container"
						onClick={() => setMobileAbtract("")}
					>
						<div className="mobile-property-card-overlay-exit-container">
							<img
								src={ThickExit}
								className="mobile-property-card-overlay-exit-icon"
								alt="Close"
							/>
						</div>
					</div>
				</div>
				<div className="mobile-property-card-overlay-right-container">
					<div 
						className="favourite-icon-container"
						onClick={() => toggleFavourite(favourites, item.zpid, setFavourites, setFavouriteDisable, navigate, cityObject, null)}
					>
						<img
							src={
									favourites.findIndex(e => e.zpid === item.zpid) === -1 ?
									Heart
									:
									HeartActiveBlue
								}
							className={`favourite-icon ${favouriteDisable === true ? "favourite-icon-disable" : ""}`}
							alt="Add To Favourites"
						/>
					</div>
					<div 
						onClick={() => viewProperty(item.zpid)}
						className="mobile-property-card-overlay-info-container"
					>
						<div className="mobile-property-card-overlay-price-container">
							<h3 className="mobile-property-card-overlay-price-text">
								{formatter.format(item.price).replace(".00", "")}
							</h3>
						</div>
						<NewPropertyCharacteristics
							item={item}
						/>
					</div>
					<div 
						className="mobile-property-card-overlay-badges-container"
						onClick={() => viewProperty(item.zpid)}
					>
						<div className={"mobile-property-card-overlay-cash-on-cash-badge-container " + (item.financials.cashOnCash > 0 ? "mobile-property-card-overlay-cash-on-cash-positive" : "mobile-property-card-overlay-cash-on-cash-negative")}>
							<span className="mobile-property-card-overlay-cash-on-cash-badge-label">
								Cash on Cash{' '}
								<span className="mobile-property-card-overlay-cash-on-cash-badge-value">
									{item.financials.cashOnCash.toFixed(2)}%
								</span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
});

export default MobileOffMarketCardOverlay;