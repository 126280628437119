import React, { useState, useCallback, useEffect, useRef } from 'react';
import { ActionButton, FilterCityInput } from '../styles/GlobalStyles';
import { Exit, Search } from '../assets';
import { Accordion, AccordionDetails, AccordionSummary, numberFormatter, stateMetros } from '../styles/GlobalStyles';
import { sendCityIDErrorEmail, cloudFunctionV2, getCitySearch, getMetroSearch } from '../functions';
import { useLocation, useSearchParams } from 'react-router-dom';
import { LoadingStatic, UpgradeCityMap } from './';

function PickPlanMetro(props) {
    const disabled = props.disabled;
    const setDisabled = props.setDisabled;
    const goToCheckout = props.goToCheckout;
    const option = props.option;
    const setSelectedCity = props.setSelectedCity;
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [city, setCity] = useState("");
    const [metroList, setMetroList] = useState(stateMetros);
    const [chosenCity, setChosenCity] = useState("");
    const [loading, setLoading] = useState(false);
    const [cityDetails, setCityDetails] = useState(null);
    const [cityBoundaries, setCityBoundaries] = useState([]);
    const [centre, setCentre] = useState({ lat: 39.841502, lng: -101.309177 });
    const [zoom, setZoom] = useState(4);
    const [cityError, setCityError] = useState(false);
    const [firstRender, setFirstRender] = useState(false);
    const [mapLoading, setMapLoading] = useState(false);
    const [metroAreas, setMetroAreas] = useState([]);
    const [msaListings, setMsaListings] = useState([]);
    const [showList, setShowList] = useState(false);
    const mapRef = useRef(null);
    const mapRefContainer = useRef(null);
    const [zipAccordion, setZipAccordion] = useState(null);
    const location = useLocation();
    const [searchParams] = useSearchParams({});
    const getState = searchParams.get("state");
    const state = getState !== null ? JSON.parse(getState) : location.state;

    const escFunction = useCallback((event) => {
		if ( event.key === "Escape" ) {
			setShowList(false);
		}
	}, []);

    useEffect(() => {
        const scrollToMapEffect = () => {
            if ( mapRefContainer !== null && innerWidth < 1000 && mapRefContainer.current !== null ) {
                mapRefContainer.current.scrollIntoView({ 
                    behavior: 'smooth',
                });
            }
        };

        const saveMSAListingsEffect = async(msaCityList) => {
            const sfhTotals = [];
            const mfhTotals = [];
            for (let index = 0; index < msaCityList.length; index++) {
                const element = msaCityList[index];
                if ( 
                        element.totalPropertyCountMFH !== undefined && element.totalPropertyCountSFH !== undefined &&
                        element.totalPropertyCountMFH !== null && element.totalPropertyCountSFH !== null
                    ) {
                        mfhTotals.push(element.totalPropertyCountMFH);
                        sfhTotals.push(element.totalPropertyCountSFH);
                }
            }
            if ( sfhTotals.length === 0 || mfhTotals.length === 0 ) {

            }
            else {
                const sfhTotal = await roundDownToNearestTen(sfhTotals.reduce((a, b) => a + b, 0));
                const mfhTotal = await roundDownToNearestTen(mfhTotals.reduce((a, b) => a + b, 0));
                const finalTotal = await roundDownToNearestTen(sfhTotal + mfhTotal);
                const newArray = [
                    {
                        label: "TOTAL LISTINGS",
                        total: numberFormatter.format(finalTotal)
                    },
                    {
                        label: "SINGLE-FAMILY",
                        total: numberFormatter.format(sfhTotal)
                    },
                    {
                        label: "MULTI-FAMILY",
                        total: numberFormatter.format(mfhTotal)
                    }
                ];

                setMsaListings(newArray);
            }
        };

        const getMSABoundariesEffect = async(item, msaCityList) => {
            setMapLoading(true);
            const newBoundaries = [];
            const boundariesURL = process.env.REACT_APP_BOUNDARIES_URL;
            for (let index = 0; index < msaCityList.length; index++) {
                const element = msaCityList[index];
                const params = {
                    city: element.city,
                    state: element.state,
                    county: false
                };
                const query = await cloudFunctionV2(boundariesURL, params);
                if ( query.status === 200 ) {
                    const newObject = {
                        city: element.city,
                        state: element.state,
                        boundaries: query.data
                    };
                    newBoundaries.push(newObject);
                    if ( index === 0 ) {
                        setCentre(query.centre);
                    }
                }
            };
            setMetroAreas(newBoundaries);

            const clevelandIndex = msaCityList.findIndex(e => e.city === "Cleveland");
            if ( clevelandIndex !== -1) {
                setZoom(8);
            }
            else {
                setZoom(10);
            }
            setMapLoading(false);

            scrollToMapEffect();
        };

        const upgradeMSAEffect = (item, newMsaCityList) => {
            getMSABoundariesEffect(item, newMsaCityList);
        };

        const selectCityEffect = async() => {
            setLoading(true);
            setMetroAreas([]);
            setMsaListings([]);
            setMapLoading(true);
            const boundariesURL = process.env.REACT_APP_BOUNDARIES_URL;
            const item = state.clickedCity;
            const params = {
                city: item.city,
                state: item.state,
                county: false,
                returnCityId: "True"
            };
            setChosenCity(item.msaTitle);
            const query = await cloudFunctionV2(boundariesURL, params);
            if ( query.status === 200 ) {
                setCentre(query.centre);
                setCityBoundaries(query.data);
                if ( query.data.length > 0 ) {
                    setZoom(11);
                }
            }

            const getCityParams = await getCitySearch(params, false);
            const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
            const gatewayData = {
                type: "gateway",
                resourceId: "citySearchLive",
                queryString: getCityParams
            };
            const getCityDetails = await cloudFunctionV2(gatewayURL, gatewayData);
            if ( getCityDetails.status === 200 ) {
                const body = getCityDetails.body;
                setChosenCity(body.msaTitle);
                setCityDetails(body);
                setCityError(false);

                if ( body.msaList !== null ) {
                    saveMSAListingsEffect(body.msaCityList);
                    upgradeMSAEffect(item, body.msaCityList);

                    setSelectedCity({
                        metroCities: body.msaCityList,
                        msaCode: body.msaCode,
                        msaTitle: body.msaTitle,
                        metroArea: true
                    });
                }

                setDisabled(false);
            }
            else {
                setDisabled(true);
                setCityError(true);

                const cityIdErrorPage = "Upgrade City Page";
                const apiResponse = `Error with citySearchLive query`;
                await sendCityIDErrorEmail(params.city, "", cityIdErrorPage, apiResponse);
            }
            setLoading(false);
            scrollToMapEffect();
        };

        if ( firstRender === false && state !== null && state.clickedCity !== undefined ) {
            setFirstRender(true);
            selectCityEffect();
        }

        const handleWindowResize = () => {
			setInnerWidth(window.innerWidth);
		};

        document.addEventListener("keydown", escFunction, false);
		return () => {
		  document.removeEventListener("keydown", escFunction, false);
		  window.addEventListener('resize', handleWindowResize);
		};
    }, [firstRender, state, innerWidth, setDisabled, escFunction, setSelectedCity]);

    const roundDownToNearestTen = (number) => {
        return Math.floor(number / 10) * 10;
    };

    const focusInput = () => {
        setShowList(true);
    };

    const blurInput = () => {
        setShowList(false);
    };

    const onZoomChanged = () => {
        if ( mapRef.current !== null ) {
            setZoom(mapRef.current.getZoom());
        }
    };

    const scrollToMap = () => {
        if ( mapRefContainer !== null && innerWidth < 1000 && mapRefContainer.current !== null) {
            mapRefContainer.current.scrollIntoView({ 
                behavior: 'smooth',
            });
        }
    };

    const changeCity = async(val) => {
        setCity(val);
        setShowList(true);
        const valLowerCase = val.toLowerCase();

        if ( val === "" ) {
            setMetroList(stateMetros);
        }
        else {
            const newArray = [];
            const cloneArray = [...stateMetros];
            for (let index = 0; index < cloneArray.length; index++) {
                const element = cloneArray[index];
                const stateFull = element.stateFull.toLowerCase();
                if ( stateFull.includes(valLowerCase) ) {
                    newArray.push(stateMetros[index]);
                }

                const metrosInState = element.metros;
                for (let ii = 0; ii < metrosInState.length; ii++) {
                    const msaTitle = cloneArray[index].metros[ii].msaTitle.toLowerCase();
                    if ( msaTitle.includes(valLowerCase) ) {
                        const stateIndex = newArray.findIndex(e => e.stateFull === element.stateFull);
                        if ( stateIndex === -1 ) {
                            const newObject = {
                                stateFull: element.stateFull,
                                state: element.state,
                                metros: [cloneArray[index].metros[ii]]
                            }
                            newArray.push(newObject);
                        }
                        else {
                            const msaIndex = newArray[stateIndex].metros.findIndex(e => e.msaTitle === cloneArray[index].metros[ii].msaTitle);
                            if ( msaIndex === -1 ) {
                                newArray[stateIndex].metros.push(cloneArray[index].metros[ii]);
                            }
                        }
                    }
                }

            }
            setMetroList(newArray);
        }
    };

    const resetCity = () => {
        changeCity("");
    };

    const selectCity = async(item) => {
        setLoading(true);
        setMetroAreas([]);
        setMsaListings([]);
        setShowList(false);
        setCity("");

        const params = {
            msaTitle: `${item.msaTitle}, ${item.state}`,
            county: false,
            returnCityId: "True"
        };
        setChosenCity(`${item.msaTitle}, ${item.state}`);
		const getCityParams = await getMetroSearch(params);
		const gatewayURL = process.env.REACT_APP_AWS_QUERY_URL;
		const gatewayData = {
			type: "gateway",
			resourceId: "msa",
			queryString: getCityParams
		};
		const getCityDetails = await cloudFunctionV2(gatewayURL, gatewayData);
		if ( getCityDetails.status === 200 ) {
            const body = getCityDetails.body;
            setCityDetails(body);
            setCityError(false);
            
            if ( body.msaCityList !== null ) {
                const msaCityList = [];
                for (let index = 0; index < body.msaCityList.length; index++) {
                    const element = body.msaCityList[index];
                    const newMSAObject = {
                        city: element.city,
                        state: element.state,
                        cityId: element.cityId
                    };
                    msaCityList.push(newMSAObject);
                }

                saveMSAListings(msaCityList);
                await getMSABoundaries(msaCityList);

                setSelectedCity({
                    metroCities: msaCityList,
                    msaCode: body.msaCode,
                    msaTitle: body.msaTitle,
                    metroArea: true
                });
            }
            setDisabled(false);
        }
        else {
            setDisabled(true);
            setCityError(true);

            console.log("Error: ", getCityDetails);
            const cityIdErrorPage = "Upgrade City Page";
            const apiResponse = `Error with citySearchLive query`;
            await sendCityIDErrorEmail(params.msaTitle, "", cityIdErrorPage, apiResponse);
        }

        setLoading(false);
    };

    const getMSABoundaries = async(msaCityList) => {
        setMapLoading(true);
        const newBoundaries = [];
        const boundariesURL = process.env.REACT_APP_BOUNDARIES_URL;
        for (let index = 0; index < msaCityList.length; index++) {
            const element = msaCityList[index];
            const params = {
                city: element.city,
                state: element.state,
                county: false
            };
            const query = await cloudFunctionV2(boundariesURL, params);
            if ( query.status === 200 ) {
                const newObject = {
                    city: element.city,
                    state: element.state,
                    boundaries: query.data
                };
                newBoundaries.push(newObject);
                if ( index === 0 ) {
                    setCentre(query.centre);
                }
            }
        };
        setMetroAreas(newBoundaries);

        const clevelandIndex = msaCityList.findIndex(e => e.city === "Cleveland");
        if ( clevelandIndex !== -1) {
            setZoom(8);
        }
        else {
            setZoom(10);
        }
        setMapLoading(false);

        scrollToMap();
    };

    const saveMSAListings = async(msaCityList) => {
        const sfhTotals = [];
        const mfhTotals = [];
        for (let index = 0; index < msaCityList.length; index++) {
            const element = msaCityList[index];
            if ( 
                    element.totalPropertyCountMFH !== undefined && element.totalPropertyCountSFH !== undefined &&
                    element.totalPropertyCountMFH !== null && element.totalPropertyCountSFH !== null
                ) {
                    mfhTotals.push(Number(element.totalPropertyCountMFH));
                    sfhTotals.push(Number(element.totalPropertyCountSFH));
            }
        }
        if ( sfhTotals.length === 0 || mfhTotals.length === 0 ) {

        }
        else {
            const sfhTotal = await roundDownToNearestTen(sfhTotals.reduce((a, b) => a + b, 0));
            const mfhTotal = await roundDownToNearestTen(mfhTotals.reduce((a, b) => a + b, 0));
            const finalTotal = await roundDownToNearestTen(sfhTotal + mfhTotal);
            const newArray = [
                {
                    label: "TOTAL LISTINGS",
                    total: numberFormatter.format(finalTotal)
                },
                {
                    label: "SINGLE-FAMILY",
                    total: numberFormatter.format(sfhTotal)
                },
                {
                    label: "MULTI-FAMILY",
                    total: numberFormatter.format(mfhTotal)
                }
            ];

            setMsaListings(newArray);
        }
    };

    const openWiki = () => {
        window.open("https://en.wikipedia.org/wiki/Principal_city", "_blank");
    };

    return (
        <div className="upgrade-city-body-container relative-container">
            {
                showList === true ?
                <div 
                    className="upgrade-city-click-out-container"
                    onClick={() => blurInput()}
                >
                </div>
                :
                null
            }
            <div className="pick-plan-input-container">
                <div className="upgrade-city-inner-input-container relative-container margin-x-large">
                    <FilterCityInput
                        value={city}
                        type="text"
                        id="upgrade-city-input"
                        placeholder="Search for any major metro..."
                        onChange={(text) => changeCity(text.target.value)}
                        onFocus={() => focusInput()}
                        disabled={false}
                    />
                    <img
                        src={city === "" ? Search : Exit}
                        className={city === "" ? "upgrade-city-search-icon" : "upgrade-city-exit-icon"}
                        alt="Search"
                        onClick={() => city === "" ? null : resetCity()}
                    />
                    {
                        showList === false ?
                        null
                        :
                        <div className="city-filtering-outer-container">
                            {
                                metroList.map((item, index) =>
                                    <div
                                        className="city-filtering-state-container"
                                        key={index}
                                    >
                                        <div className="city-filtering-state-text-container">
                                            <span className="label-semibold-caps colour-primary">
                                                {item.stateFull}
                                            </span>
                                        </div>
                                        {
                                            item.metros.map((subItem, subIndex) =>
                                                <div 
                                                    className="metro-filtering-element"
                                                    key={subIndex}
                                                    onClick={() => selectCity(subItem)}
                                                >
                                                    <span className="body-regular colour-primary">
                                                        {subItem.msaTitle}
                                                    </span>
                                                </div>
                                            )
                                        }
                                        <div className="metro-filtering-divider-padding">
                                            <div className="metro-filtering-divider margin-tops">
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    }
                </div>
                <div className="upgrade-city-input-text-container">
                    {
                        chosenCity === "" ?
                        null
                        :
                        <div className="upgrade-city-details-container margin-medium">
                            <h3 className="heading-small-semibold colour-primary margin-large">
                                {chosenCity}
                            </h3>
                            {
                                loading === true ?
                                <div className="upgrade-city-loading-container">
                                    <LoadingStatic/>
                                    <span className="body-semibold colour-primary block-text margin-top-small margin-x-small">
                                        Loading...
                                    </span>
                                    <span className="body-regular colour-secondary">
                                        Getting geography information
                                    </span>
                                </div>
                                :
                                cityError === true ?
                                <div className="upgrade-city-response-container">
                                    <span className="body-regular colour-error">
                                        We're afraid there was an error getting data for this metro,
                                        please try another metro or contact us for more details
                                    </span>
                                </div>
                                :
                                <div className="upgrade-city-response-container">
                                    <div className="upgrade-city-listings-container">
                                        {
                                            msaListings.map((item, index) =>
                                                <div 
                                                    className="upgrade-city-listings-element"
                                                    key={index}
                                                >
                                                    <span className="label-regular-caps colour-secondary block-text margin-x-small">
                                                        {item.label}
                                                    </span>
                                                    <h3 className="heading-large-semibold colour-primary">
                                                        {item.total}
                                                    </h3>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="">
                                        <span className="label-regular colour-secondary text-italic">
                                            Property counts could be up to 1 week old
                                        </span>
                                    </div>
                                    <div className="upgrade-city-divider margin-tops">
                                    </div>
                                    <div className="upgrade-city-zips-container margin-medium">
                                        <span className="label-regular-caps colour-secondary block-text margin-x-small">
                                            CITIES & ZIP CODES
                                        </span>
                                        {
                                            cityDetails.msaCityList.map((item, index) =>
                                                <div 
                                                    className="upgrade-city-category-zips-element margin-medium"
                                                    key={index}
                                                >
                                                    <span className="body-semibold colour-primary block-text margin-x-small">
                                                        {item.city}, {item.state}
                                                    </span>
                                                    <div className="upgrade-city-zips-grid">
                                                        {
                                                            item.activeZipCodes !== undefined &&
                                                            item.activeZipCodes !== null &&
                                                            item.activeZipCodes.map((subItem, subIndex) =>
                                                                subIndex < (innerWidth < 1000 ? 4 : 5) ?
                                                                <div 
                                                                    className="upgrade-city-zips-element"
                                                                    key={subIndex}
                                                                >
                                                                    <span className="body-regular colour-primary">
                                                                        {subItem}
                                                                    </span>
                                                                </div>
                                                                :
                                                                null 
                                                            )
                                                        }
                                                    </div>
                                                    {
                                                        item.activeZipCodes !== undefined &&
                                                        item.activeZipCodes !== null &&
                                                        item.activeZipCodes.length > (innerWidth < 1000 ? 4 : 5) ?
                                                        <Accordion 
                                                            expanded={index === zipAccordion ? true : false} 
                                                            onChange={() => zipAccordion === index ? setZipAccordion(null) : setZipAccordion(index)}
                                                            sx={{ boxShadow: 'none' }}
                                                        >
                                                            <AccordionSummary
                                                                aria-controls={`panel-${index}-bh-content`}
                                                                id={`panel-${index}-bh-header`}
                                                            >
                                                                <span className={"body-regular colour-link underline block-text " + (zipAccordion === index ? "view-none" : "")}>
                                                                    Show all
                                                                </span>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <div className="upgrade-city-zips-grid">
                                                                    {
                                                                        item.activeZipCodes.map((subItem, subIndex) =>
                                                                            subIndex > (innerWidth < 1000 ? 3 : 4) ?
                                                                            <div 
                                                                                className="upgrade-city-zips-element"
                                                                                key={subIndex}
                                                                            >
                                                                                <span className="body-regular colour-primary">
                                                                                    {subItem}
                                                                                </span>
                                                                            </div>
                                                                            :
                                                                            null 
                                                                        )
                                                                    }
                                                                </div>
                                                                {
                                                                    zipAccordion === index ?
                                                                    <div
                                                                        onClick={() => setZipAccordion(null)}
                                                                    >
                                                                        <span className="body-regular colour-link underline block-text">
                                                                            Hide
                                                                        </span>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                            </AccordionDetails>
                                                        </Accordion>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            )
                                        }
                                        <div className="margin-top-medium">
                                            <span className="body-regular colour-secondary text-italic">
                                                We only include <span className="underline cursor-pointer" onClick={() => openWiki()}>Principal Cities</span> within
                                                the Metropolitan area, with a population of at least 10,000
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
            <div className="pick-plan-map-container">
                <UpgradeCityMap
                    cityBoundaries={cityBoundaries}
                    centre={centre}
                    zoom={zoom}
                    mapRef={mapRef}
                    onZoomChanged={onZoomChanged}
                    metroAreas={metroAreas}
                    upgradedMSA={true}
                    mapLoading={mapLoading}
                    mapRefContainer={mapRefContainer}
                />
                <div className="pick-plan-checkout-button-container margin-top-x-large">
                    <span className="body-regular colour-primary block-text margin-medium">
                        Subtotal <span className="body-semibold">{option.price}</span> <span className="colour-secondary">/month</span>
                    </span>
                    <div className="pick-plan-checkout-button-element">
                        <ActionButton
                            disabled={disabled}
                            onClick={() => goToCheckout()}
                        >
                            Continue to checkout
                        </ActionButton>
                    </div>
                </div>
                <div className="margin-top-medium">
                    <span className="label-regular colour-secondary block-text text-align-right mobile-none">
                        Upon upgrade, it usually takes about 1 hour 
                        to upload the data for your metro area.
                    </span>
                </div>
            </div>
        </div>
    )
};

export default PickPlanMetro;