import React from 'react';
import '../styles/PricingCard.css';
import { ActionButton } from '../styles/GlobalStyles';
import { useNavigate } from 'react-router-dom';
// import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { BestValue } from './';
import { recordEvent } from "../functions";

function PricingCard(props) {
    const item = props.item;
    const index = props.index;
    const pickCity = props.pickCity;
    const disabled = props.disabled;
    const devMode = props.devMode;
    const firstPricingOption = props.firstPricingOption;
    const changePricingOption = props.changePricingOption;
    const navigate = useNavigate();

    const complete = () => {
        recordEvent("Plan Selected", {
            months: firstPricingOption === true ? 1 : 12,
            price: item.total / 100,
            counties: item.counties
        });

        const newState = item.route === "/pay" ? {
            allCities: false,
            price: item.amount,
            subscriptionId: devMode === true ? item.testSubscriptionId : item.subscriptionId,
            autoBilling: firstPricingOption === true ? true : false,
            plan: {
                total: item.total / 100,
                originalPrice: item.amount / 100,
                months: firstPricingOption === true ? 1 : 12,
                price: firstPricingOption === true ? item.total / 100 : item.total / 1200,
                title: item.title,
                label: item.label,
                counties: item.counties
            }
        } : null;
        navigate(item.route, {
            state: newState
        });
    };

    return (
        <div className="pricing-card-outer-container">
            {
                index === 2 && firstPricingOption === false ?
                <BestValue
                    text="✨Recommended✨"
                />
                :
                null
            }
            <div className="pricing-card-top-inner-container">
                <span className="heading-large-semibold text-link text-align-center margin-medium block-text">
                    {item.title}
                </span>
                <div className="pricing-card-image-container margin-medium">
                    <img
                        src={item.image}
                        className="pricing-card-image"
                        alt={item.label}
                    />
                </div>
                <div className="pricing-card-subscription-type-container margin-medium">
                    <span className="body-semibold colour-quaternary text-align-center block-text">
                        {item.label}
                    </span>
                </div>
                {
                    item.price !== undefined ?
                    <div className="pricing-card-row-container margin-medium">
                        <span className="heading-large-light colour-secondary strike-through subtext-margin-right block-text">
                            {item.oldPrice}
                        </span>
                        <h2 className="heading-large-semibold text-link">
                            {item.price}
                        </h2>
                        <span className="body-regular colour-secondary">
                            {item.duration}
                        </span>
                    </div>
                    :
                    null
                }
                <div className="pricing-card-best-for-container">
                    <span className="body-regular colour-primary block-text text-align-center text-italic">
                        {item.bestFor}
                    </span>
                </div>
            </div>
            <div className="pricing-card-features-list">
                {
                    item.features.length === 0 ?
                    null
                    :
                    // <Accordion
                    //     expanded={benefitsExpanded} 
                    //     onChange={() => setBenefitsExpanded(!benefitsExpanded)}
                    //     sx={{ boxShadow: 'none' }}
                    // >
                    //     <AccordionSummary
                    //         aria-controls={`benefits-accordion-${index}-content`}
                    //         id={`benefits-accordion-${index}`}
                    //         // sx={styles.accordionSummary}
                    //     >
                            
                    //         <span className="label-regular colour-link">
                    //             {benefitsExpanded === true ? "Hide benefits" : "See benefits"}
                    //         </span>
                    //     </AccordionSummary>
                    //     <AccordionDetails>
                            <ul className="pricing-card-features-list-ul">
                                {
                                    item.features.map((item, index) => (
                                        item.text === null ?
                                        null
                                        :
                                        <li
                                            key={index}
                                            className="pricing-card-features-list-element margin-medium"
                                        >
                                            <div className="">
                                                <img
                                                    src={item.icon}
                                                    className="pricing-card-features-list-element-icon"
                                                    alt="Tick"
                                                />
                                            </div>
                                            <div className="">
                                                {
                                                    item.text.includes("No 1-on-1") ?
                                                    <span className="body-regular colour-error">
                                                        {item.text}
                                                    </span>
                                                    :
                                                    item.singleLine === true ?
                                                    <span className="body-regular colour-secondary">
                                                        {item.text}
                                                    </span>
                                                    :
                                                    item.singleLine === false && item.boldText === "BONUS:" ?
                                                    <span className="body-regular colour-regular">
                                                        {item.firstText} <span className={"text-bold colour-green " + (item.link !== "" ? "underline cursor-pointer" : "")}>{item.boldText}</span> {item.secondText}
                                                    </span>
                                                    :
                                                    <span className="body-regular colour-secondary">
                                                        {item.firstText} <span className={"text-bold colour-primary " + (item.link !== "" ? "underline cursor-pointer" : "")}>{item.boldText}</span> {item.secondText}
                                                    </span>
                                                }
                                                {
                                                    item.upgrade === true && firstPricingOption === true ?
                                                    <div 
                                                        className="button-row cursor-pointer"
                                                        onClick={() => changePricingOption()}
                                                    >
                                                        <span className="body-semibold colour-quaternary block-text subtext-margin-right">
                                                            {item.upgradeText}
                                                        </span>
                                                        <svg 
                                                            xmlns="http://www.w3.org/2000/svg" 
                                                            width="9" 
                                                            height="8" 
                                                            viewBox="0 0 9 8" 
                                                            fill="none"
                                                        >
                                                            <path 
                                                                d="M8.07215 4.38239L4.63217 7.82237C4.42108 8.03347 4.07882 8.03347 3.86773 7.82237C3.65664 7.61128 3.65664 7.26903 3.86773 7.05793L6.38495 4.54071L0.771009 4.54071C0.472477 4.54071 0.230469 4.29871 0.230469 4.00017C0.230469 3.70164 0.472477 3.45963 0.771009 3.45963L6.38495 3.45963L3.86773 0.942414C3.65664 0.73132 3.65664 0.389068 3.86773 0.177974C4.07882 -0.0331203 4.42108 -0.0331202 4.63217 0.177974L8.07215 3.61795C8.28324 3.82905 8.28324 4.1713 8.07215 4.38239Z" 
                                                                fill="#2F4858"
                                                            />
                                                        </svg>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </li>
                                ))}
                            </ul>
                        // </AccordionDetails>
                    // </Accordion>
                }
            </div>
            {
                pickCity === false ?
                <div className={"pricing-card-button-container " + (item.label === "Custom plan" ? "pricing-card-custom-margin-top" : "")}>
                    <ActionButton
                        onClick={() => complete()}
                        disabled={disabled}
                    >
                        {item.ctaText}
                    </ActionButton>
                    {
                        item.ctaExtraText !== null ?
                        <span className="body-regular colour-secondary text-italic text-align-center block-text margin-top-medium">
                            {item.ctaExtraText}
                        </span>
                        :
                        null
                    }
                </div>
                :
                null
            }
        </div>
    )
}

export default PricingCard;